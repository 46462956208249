import React, { InputHTMLAttributes, SyntheticEvent, useEffect, useState } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Field, InjectedFormProps, reduxForm, SubmissionError, WrappedFieldMetaProps } from 'redux-form'
import { SignUpFormData } from '../../../../store/account/profile/types'
import { loginByEmail, loginByGoogle } from '../../../../store/account/profile/action-creators'
import whiteBottleIcon from '../../../../assets/images/icons/white-cinch-bottle.svg'
import googleIcon from '../../../../assets/images/icons/google.svg'
import appleIcon from '../../../../assets/images/icons/apple.svg'
// import checkMark from '../../../../assets/images/check-mark.svg'
import Loader from '../../../loader/Loader'
import { AccountFormType } from '../../Header'

interface SignInFormProps {
  signIn: (data: SignUpFormData) => any,
  toggle: () => any,
  close: (e: SyntheticEvent) => void,
  resetPassword: () => any,
  loginByGoogle: (token: string) => Promise<void>,
  setAccountTypeForm: (type: AccountFormType) => void
}

const CustomInput = ({
  input,
  meta: { touched, error },
  placeholder,
  type
}: {
  input: InputHTMLAttributes<HTMLInputElement>,
  meta: WrappedFieldMetaProps,
  placeholder: string,
  type: string
}) => {
  return (<>
    <input {...input} placeholder={placeholder} type={type}/>
    {touched && error && <span className="error">{error} </span>}
  </>)
}

type FormProps = SignInFormProps & InjectedFormProps<SignUpFormData, SignInFormProps>

const Form: React.FunctionComponent<FormProps> = ({
  signIn,
  handleSubmit,
  submitting,
  error,
  toggle,
  resetPassword,
  loginByGoogle,
  setAccountTypeForm
}) => {

  const googleTokenClient = google.accounts.oauth2.initTokenClient({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
    scope: 'profile email',
    callback: async (response: any) => {
      await loginByGoogle(response.access_token)
      setAccountTypeForm(AccountFormType.VerifySmsCode)
    },
  });

  useEffect(() => {
    function onSuccess (data: any) {
      console.log(data)
    }
    function onFail (error: any) {
      console.log(error)
    }
    document.addEventListener('AppleIDSignInOnSuccess', onSuccess)
    document.addEventListener('AppleIDSignInOnFailure', onFail)
    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', onSuccess)
      document.removeEventListener('AppleIDSignInOnFailure', onFail)
    }
  }, [])

  const submit = async (formData: SignUpFormData) => {
    try {
      await signIn({ ...formData, '2fa_mode': 'sms' })
      setAccountTypeForm(AccountFormType.VerifySmsCode)
    } catch (e: any) {
      const error = e.errors
        ? e.errors.map(({ message }: { message: string }) => message).join('\n')
        : 'Something went wrong, please try again'
      throw new SubmissionError({ _error: error })
    }
  }

  const loginGoogle = async () => {
    googleTokenClient.requestAccessToken();
  }

  const loginApple = () => {
    const redirectUrl = process.env.REACT_APP_APPLE_SIGNIN_REDIRECT_URL as string
    const parts = {
      response_type: 'code%20id_token',
      response_mode: 'form_post',
      scope: 'name%20email',
      client_id: process.env.REACT_APP_APPLE_SIGNIN_CLIENT_ID,
      redirect_uri: encodeURIComponent(redirectUrl),
      state: 'authorized'
    }

    const str = Object.entries(parts).map(([key, val]) => `${key}=${val}`).join('&')
    window.location.href = `https://appleid.apple.com/auth/authorize?${str}`
  }

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className={classnames('form-type-2 account-form', {
        hidden: false
      })}>
        <h3 className="subsection__header">Sign in</h3>
        <Field name="email" component={CustomInput} type="text" placeholder="Enter your email" />
        <Field name="password" component={CustomInput} type="password" placeholder="Enter your password" />
        <div className="subsection__forgot" onClick={resetPassword}>Forgot password?</div>
        {!submitting && error && <span className="error">{error} </span>}
        <button type="submit" className={classnames('button_blue', { submitting })}>
          <img src={whiteBottleIcon} />
          Sign In with Cinch
          {submitting && <Loader />}
        </button>
        <button type="button" onClick={loginGoogle} className="button_light-blue">
          <img src={googleIcon} />
          Sign In with Google
        </button>
        <button type="button" onClick={loginApple} className="button_black apple">
          <img src={appleIcon} />
          Sign In with Apple
        </button>
        <p>Don&apos;t have an account? <span onClick={toggle}>Sign up!</span></p>
      </form>
    </>
  )
}

const WrappedForm = reduxForm<SignUpFormData, SignInFormProps>({
  form: 'SignInForm',
  validate: (data: any, { clearSubmitErrors }: any) => {
    clearSubmitErrors()
    return {}
  }
})(Form)

const ConnectedWrappedForm = connect(
  null,
  { signIn: loginByEmail, loginByGoogle }
)(WrappedForm)

export default ConnectedWrappedForm
