import {
  Catalog,
  CATALOG_PAGE_FETCHED,
  CATALOG_ITEMS_FETCHED,
  CatalogItem,
  CATALOG_ITEM_FETCHED,
  ICatalogFetchProps,
  CATALOG_CLEAR_CURRENT_PRODUCT,
  TOGGLE_CATALOG_FILTERS_VISIBILITY,
  CATALOG_STORE_FETCHED,
  CATALOG_STORES_FETCHED
} from './types'
import { CatalogService } from '../../services/CatalogService'
import { ThunkDispatch } from 'redux-thunk'
import { CustomersService } from '../../services/CustomersService'
import { ICartStore } from '../cart/types'

export function fetchCatalogPage ({
  page,
  items,
  category,
  department,
  store
}: ICatalogFetchProps) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const catalog = await CatalogService.getItems({
      page, items, category, department, store
    })
    const preparedCatalog = {
      catalogItems: catalog.list,
      currentPage: catalog.paging_header.current_page,
      totalItems: catalog.paging_header.total_items,
      totalPages: catalog.paging_header.total_pages
    }

    dispatch({
      type: CATALOG_PAGE_FETCHED,
      payload: preparedCatalog as Catalog
    })
  }
}

export function fetchPreviouslyOrderedPage ({
  page,
  items
}: ICatalogFetchProps) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const catalog = await CustomersService.getOrderAgain({ page, items })
    const preparedCatalog = {
      catalogItems: catalog.list,
      currentPage: catalog.paging_header.current_page,
      totalItems: catalog.paging_header.total_items,
      totalPages: catalog.paging_header.total_pages
    }

    dispatch({
      type: CATALOG_PAGE_FETCHED,
      payload: preparedCatalog as Catalog
    })
  }
}

export function fetchMoreItems ({
  page,
  items,
  category,
  department,
  store
}: ICatalogFetchProps) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const catalog = await CatalogService.getItems({
      page, items, category, department, store
    })
    const preparedCatalog = {
      catalogItems: catalog.list,
      currentPage: catalog.paging_header.current_page,
      totalItems: catalog.paging_header.total_items,
      totalPages: catalog.paging_header.total_pages
    }

    dispatch({
      type: CATALOG_ITEMS_FETCHED,
      payload: preparedCatalog as Catalog
    })
  }
}

export function fetchMorePreviouslyOrderedItems ({
  page,
  items
}: ICatalogFetchProps) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const catalog = await CustomersService.getOrderAgain({ page, items })
    const preparedCatalog = {
      catalogItems: catalog.list,
      currentPage: catalog.paging_header.current_page,
      totalItems: catalog.paging_header.total_items,
      totalPages: catalog.paging_header.total_pages
    }

    dispatch({
      type: CATALOG_ITEMS_FETCHED,
      payload: preparedCatalog as Catalog
    })
  }
}

export function fetchProductData (id: string) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const product = await CatalogService.getProduct(id)
    dispatch({
      type: CATALOG_ITEM_FETCHED,
      payload: product as CatalogItem
    })
  }
}

export function fetchStoreData (id: string) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const store = await CatalogService.getStore(id)
    dispatch({
      type: CATALOG_STORE_FETCHED,
      payload: store as ICartStore
    })
  }
}

export function fetchStores (id?: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const stores = await CatalogService.getStores(id)
    dispatch({
      type: CATALOG_STORES_FETCHED,
      payload: stores.list as ICartStore[]
    })
  }
}

export function clearCurrentProductData () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: CATALOG_CLEAR_CURRENT_PRODUCT
    })
  }
}

export function toggleCatalogFiltersVisibility () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: TOGGLE_CATALOG_FILTERS_VISIBILITY
    })
  }
}
