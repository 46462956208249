import React, { useRef, useEffect } from 'react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import classnames from 'classnames'

export enum CustomInputTypes {
  Input = 'input',
  Password = 'password',
  Textarea = 'textarea'
}

interface CustomInputProps {
  labelText: string,
  placeholder: string,
  pushFocusCallback: (data: { [x: string]: () => void }) => void,
  input: WrappedFieldInputProps,
  meta: WrappedFieldMetaProps,
  type: CustomInputTypes
}

export const CustomInput: React.FunctionComponent<CustomInputProps> = ({
  labelText,
  placeholder,
  input,
  pushFocusCallback,
  type = CustomInputTypes.Input,
  meta: {
    touched,
    error
  }
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    pushFocusCallback && pushFocusCallback({
      [input.name]: () => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }
    })
  }, [])
  return (
    <label className={classnames('field', { 'field-error': touched && error })}>
      {labelText}
      {type === 'input' && <input ref={inputRef} {...input} placeholder={placeholder} />}
      {type === 'password' && <input ref={inputRef} {...input} placeholder={placeholder} type='password' />}
      {type === 'textarea' && <textarea ref={inputRef} {...input} placeholder={placeholder} />}
    </label>
  )
}
