import {
  ACCOUNT_FAVORITES_ALSO_LIKE_FETCHED,
  ACCOUNT_FAVORITES_ITEMS_FETCHED,
  ACCOUNT_FAVORITES_RECENTLY_ORDERED_FETCHED,
  AccountFavoritesActions,
  AccountFavoritesPage
} from './types'

const initialState = {
  favorites: [],
  recentlyOrdered: [],
  alsoLike: []
}

export default function reduce (state: AccountFavoritesPage = initialState, action: AccountFavoritesActions) {
  switch (action.type) {
    case ACCOUNT_FAVORITES_ALSO_LIKE_FETCHED:
      return {
        ...state,
        alsoLike: action.payload
      }
    case ACCOUNT_FAVORITES_ITEMS_FETCHED:
      return {
        ...state,
        favorites: action.payload
      }
    case ACCOUNT_FAVORITES_RECENTLY_ORDERED_FETCHED:
      return {
        ...state,
        recentlyOrdered: action.payload
      }
    default:
      return state
  }
}
