import { NullLiteral } from '@babel/types'
import { IFavorite } from '../favorites/types'
import { ICategory } from '../departments/types'
import { ICartStore } from '../cart/types'

export interface CatalogItemImage {
  id?: string
  encoded_filename: string
  created_at: string
  url: string
}

export interface ICatalogItemPricesNetwork {
}

export interface ICatalogItemPricesStorePrice {
  sale_price: number
  store: ICartStore
}

export interface ICatalogItemPrices {
  real_sale_price: number
  sale_price: number
  store_network_prices: ICatalogItemPricesNetwork[]
  store_prices: ICatalogItemPricesStorePrice[]
}

export interface CatalogItem {
  id: string
  name: string
  description?: string
  sale_price?: number
  real_sale_price: number
  store_price?: number
  tax?: number
  images: CatalogItemImage[]
  isFavorites?: boolean
  favorites: IFavorite[]
  subPage?: boolean
  cardCount?: number
  out_of_stock: boolean
  isCustomer: boolean
  category_ids: number[]
  category: ICategory[]
  prices: ICatalogItemPrices
}

export interface IProductByStore {
  storeId: number
  products: CatalogItem[]
}

export interface ICatalogFetchProps {
  page: number
  items: number
  category?: number
  department?: number
  store?: number
  previouslyOrdered?: boolean
}

export interface Catalog {
  currentPage: number
  catalogItems: CatalogItem[]
  totalPages: number
  totalItems: number
  itemsPerPage: number
  currentProduct?: CatalogItem
  isFiltersVisible: Boolean
  catalogStore: ICartStore | null
  stores: ICartStore[]
}

export const CATALOG_ITEMS_FETCHED = 'CATALOG_ITEMS_FETCHED'
interface CatalogItemsFetched {
  type: typeof CATALOG_ITEMS_FETCHED
  payload: Catalog
}

export const CATALOG_PAGE_FETCHED = 'CATALOG_PAGE_FETCHED'
interface CatalogPageFetched {
  type: typeof CATALOG_PAGE_FETCHED
  payload: Catalog
}

export const CATALOG_ITEM_FETCHED = 'CATALOG_ITEM_FETCHED'
interface CatalogItemFetched {
  type: typeof CATALOG_ITEM_FETCHED
  payload: CatalogItem
}

export const CATALOG_STORE_FETCHED = 'CATALOG_STORE_FETCHED'
interface CatalogStoreFetched {
  type: typeof CATALOG_STORE_FETCHED
  payload: ICartStore
}

export const CATALOG_STORES_FETCHED = 'CATALOG_STORES_FETCHED'
interface CatalogStoresFetched {
  type: typeof CATALOG_STORES_FETCHED
  payload: ICartStore[]
}

export const TOGGLE_CATALOG_FILTERS_VISIBILITY = 'TOGGLE_CATALOG_FILTERS_VISIBILITY'

interface ToggleCCatalogFiltersVisibility {
  type: typeof TOGGLE_CATALOG_FILTERS_VISIBILITY
  payload?: NullLiteral
}

export const CATALOG_PROGRESS = 'CATALOG_PROGRESS'
interface CatalogProgress {
  type: typeof CATALOG_PROGRESS
  payload: boolean
}

export const CATALOG_CLEAR_CURRENT_PRODUCT = 'CATALOG_CLEAR_CURRENT_PRODUCT'
interface CatalogClearCurrentProduct {
  type: typeof CATALOG_CLEAR_CURRENT_PRODUCT
}

export type CatalogActions = CatalogItemsFetched
  | CatalogPageFetched
  | CatalogItemFetched
  | ToggleCCatalogFiltersVisibility
  | CatalogProgress
  | CatalogClearCurrentProduct
  | CatalogStoreFetched
  | CatalogStoresFetched
