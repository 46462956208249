import React, { useState, useRef, useEffect } from 'react'
import Swiper, { SwiperInstance } from 'react-id-swiper'
import cn from 'classnames'

import 'swiper/swiper.scss'
import './carousel.scss'

import CatalogItem from '../catalog-item/CatalogItem'

import { makeLoadersArray } from '../../utils/LoadersArray'
import { CatalogItem as ICatalogItem } from '../../store/catalog/types'

interface ICatalogCarouselProps {
  id?: string | undefined
  title: String
  items: ICatalogItem[]
  load?: boolean
  routeState?: object
}

const CatalogCarousel: React.FC<ICatalogCarouselProps> = ({ id = undefined, title = '', items = [], load = false, routeState = undefined }) => {
  const swiper = useRef<SwiperInstance>(null)
  const [prevDisable, setPrevDisable] = useState(true)
  const [nextDisable, setNextDisable] = useState(true)

  const loaderArray = makeLoadersArray(10, 'temp')

  const goNext = () => {
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slidePrev()
    }
  }

  const swiperOptions = {
    spaceBetween: 20,
    slidesPerView: 6,
    grabCursor: true,
    breakpoints: {
      300: {
        slidesPerView: 1,
        spaceBetween: 15
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      800: {
        slidesPerView: 3,
        spaceBetween: 15
      },
      900: {
        slidesPerView: 4,
        spaceBetween: 15
      },
      1150: {
        slidesPerView: 6,
        spaceBetween: 20
      }
    },
    on: {
      slideChange: () => {
        setPrevDisable(swiper.current.swiper.isBeginning)
        setNextDisable(swiper.current.swiper.isEnd)
      },
      init: () => {
        setPrevDisable(swiper.current.swiper.isBeginning)
        setNextDisable(swiper.current.swiper.isEnd)
      }
    }
  }

  useEffect(() => {
    if (swiper.current && swiper.current.swiper) {
      swiper.current.swiper.update()
    }
  }, [swiper.current])

  if (!items.length) {
    return null
  }

  return (
    <section className="carousel">
      <div className="carousel__header">
        <h3>{title}</h3>
        <div className="carousel__nav">
          <button className={cn('carousel__nav-btn', 'left', { disabled: prevDisable })} onClick={goPrev}></button>
          <button className={cn('carousel__nav-btn', { disabled: nextDisable })} onClick={goNext}></button>
        </div>
      </div>
      <div className="carousel__body" id={id}>
        {load
          ? <div className="carousel__body-load">
              {loaderArray.map(item => <div key={item.id}><CatalogItem {...item} /></div>)}
            </div>
          : <Swiper ref={swiper} { ...swiperOptions }>
              {items.map(item => <div key={item.id}><CatalogItem {...item} routeState={routeState} /></div>)}
            </Swiper>
        }
      </div>
    </section>
  )
}

export default CatalogCarousel
