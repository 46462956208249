import { ApiService } from './ApiService'
import { CustomersService, withToken } from './CustomersService'

class OrdersServiceClass {
  constructor (
    private apiService: typeof ApiService,
    private customersService: typeof CustomersService
  ) { }

  @withToken()
  async getOrders () {
    return this.apiService.post(`/customers/${this.customersService.customerId}/orders/list/1/100`, {
      sorting: [{
        name: 'date_created',
        ascending: false
      }]
    })
  }
}

export const OrdersService = new OrdersServiceClass(ApiService, CustomersService)
