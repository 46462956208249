import React, { ChangeEvent, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Field, InjectedFormProps, reduxForm, SubmissionError, change } from 'redux-form'
import { SignUpFormData } from '../../../../store/account/profile/types'
import { createCustomer, getUnfinishedRegister } from '../../../../store/account/profile/action-creators'
import whiteBottleIcon from '../../../../assets/images/icons/white-cinch-bottle.svg'
import Loader from '../../../loader/Loader'
import { AccountFormType } from '../../Header'

interface SignUpFormProps {
  createCustomer: (data: SignUpFormData) => any
  toggle: () => any
  setAccountTypeForm: (type: AccountFormType) => any
  getUnfinishedRegister: () => any
}

const Form: React.FunctionComponent<
  SignUpFormProps &
  InjectedFormProps<SignUpFormData, SignUpFormProps>
> = ({
  createCustomer,
  handleSubmit,
  submitting,
  setAccountTypeForm,
  toggle,
  change,
  getUnfinishedRegister,
  error
}) => {
  const [isBusiness, setIsBusiness] = useState<boolean>(false)

  const submit = async (data: SignUpFormData) => {
    if (submitting) {
      return
    }

    if (data.repeatPassword !== data.password) {
      throw new SubmissionError({ _error: 'Passwords do not match.' })
    }

    if (!data.phone) {
      throw new SubmissionError({ _error: 'Phone number is required.' })
    }
    if (data.company_name) {
      data.business_account = true
    }
    try {
      await createCustomer({ ...data, '2fa_mode': 'sms' })
      setAccountTypeForm(AccountFormType.VerifySmsCode)
    } catch (e: any) {
      const error = e.errors
        ? e.errors.map(({ message }: { message: string }) => message).join('\n')
        : 'Something went wrong, please try again'
      throw new SubmissionError({ _error: error })
    }
  }

  const getUnfinishedRegisterHandler = async () => {
    const data = await getUnfinishedRegister()
    change('email', data.email)
    change('phone', data.phone)
    change('password', data.password || '')
    change('repeatPassword', data.repeatPassword || '')
    change('referral_code', data.referral_code || '')
    change('isBusinessCheckbox', !!data.business_account)
    change('company_name', data.company_name)
    setIsBusiness(!!data.business_account)
  }

  useEffect(() => {
    getUnfinishedRegisterHandler()
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className={classnames('form-type-2 account-form sign-up')}>
        <div className="subsection__header">Sign up</div>
        <label>E-mail<Field name="email" component="input" type="text" /></label>
        <label>Phone number<Field name="phone" component="input" type="text" placeholder="+1 ___ ___ ____" /></label>
        <label>Password<Field name="password" component="input" type="password" /></label>
        <label>Repeat password<Field name="repeatPassword" component="input" type="password" /></label>
        <label>Referral code (optional)<Field name="referral_code" component="input" type="text" /></label>
        <div id="business-account"><Field id="isBusinessCheckbox" name="isBusinessCheckbox" type="checkbox" component="input" onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setIsBusiness(e.target.checked)
        }}/><label htmlFor="isBusinessCheckbox" className="account-business">I would like to register a business account</label></div>
        {
          isBusiness
            ? <label>Company name (optional)<Field name="company_name" component="input" type="text" /></label>
            : null
        }
        {!submitting && error && <span className="error">{error}</span>}
        <button className="button_blue">
          <img src={whiteBottleIcon} />
          Create New Account
          {submitting && <><div className="dimmer"></div><Loader /></>}
        </button>
        <p>Have already registered? <span onClick={toggle}>Sign In!</span></p>
      </form>
    </>
  )
}

const WrappedForm = reduxForm<SignUpFormData, SignUpFormProps>({
  form: 'SignUpForm'
})(Form)

const ConnectedWrappedForm = connect(
  null,
  { createCustomer, change, getUnfinishedRegister }
)(WrappedForm)

export default ConnectedWrappedForm
