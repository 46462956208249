import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../../store/reducers'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { SocialLinksAttributes } from './types'

import facebook from '../../assets/images/social/facebook.svg'
import twitter from '../../assets/images/social/twitter.svg'
import gmail from '../../assets/images/social/gmail.svg'
import instagram from '../../assets/images/social/instagram.svg'
import youtube from '../../assets/images/social/youtube.svg'
import { getSocialNetworksLinks } from '../../store/information/action-creators'

const icons = {
  facebook: facebook,
  instagram: instagram,
  gmail: gmail,
  twitter: twitter,
  youtube: youtube
}

function getSocialNetworkIcon (network: keyof typeof icons): any {
  return icons[network]
}

const SocialLinks: React.FC<SocialLinksAttributes> = ({ className, getSocialNetworksLinks, socialLinks }) => {
  if (process.env.REACT_APP_TARGET_FOLDER === 'nrs') {
    return null
  }

  useEffect(() => {
    getSocialNetworksLinks()
  }, [])

  if (!socialLinks || socialLinks.length === 0) {
    return null
  }

  return (
    <nav className={`menu menu--social-links ${className}`}>
      <ul className="menu__container">
        {
          socialLinks.map((socialLink, i) => {
            const icon = getSocialNetworkIcon(socialLink.social_network)

            if (socialLink.link.includes('@')) {
              socialLink.link = 'mailto:' + socialLink.link
            }

            return (
              <li key={i} className="menu__item">
                <a className="menu__link" title={socialLink.social_network} href={socialLink.link}>
                  <LazyLoadImage className="menu__image" src={icon} alt={socialLink.social_network} /></a>
              </li>
            )
          })
        }
      </ul>
    </nav>
  )
}

export default connect((state: ReduxState) => {
  return {
    socialLinks: state.information.socialLinks
  }
}, {
  getSocialNetworksLinks
})(SocialLinks)
