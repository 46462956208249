import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import './departments.scss'

import { IDepartment } from '../../store/departments/types'
import noPhoto from '../../assets/images/no-photo.svg'
import MobileDepartments from './MobileDepartments'
import Loader from '../loader/Loader'
import { decodeHtmlSymbols } from '../../utils/DecodeHtmlSymbols'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// import { getIsAsapDelivery } from '../../utils/GetIsAsapDelivery'

interface IDepartmentProps extends IDepartment {
  loading?: boolean
}

interface IDepartments {
  items?: IDepartmentProps[]
  storeId?: string | undefined
}

const Departments: React.FC<IDepartments> = ({ items = [], storeId }) => {
  // const isAsapDelivery = getIsAsapDelivery()

  return (
    <section className={classNames('departments', storeId ? 'store' : '')}>
      <h3>Departments</h3>
      <div className="departments-wrap">
          {
            items.filter(x => x.id !== 1).map((department, i) => {
              const title = decodeHtmlSymbols(department.title)
              return (
                department.loading
                  ? <div key={i} className='department loading'>
                    <div className='department__cover'>
                      <div className='department__cover-loader-wrapper'>
                        <Loader color='blue' className='department-loader'/>
                      </div>
                    </div>
                    <div className='department__placeholder'>
                      <div></div>
                    </div>
                  </div>
                  : <NavLink to={ storeId
                    ? {
                        pathname: `/store/${storeId}/${department.id}`
                      }
                    : {
                        pathname: '/catalog',
                        search: `department=${department.id}`
                      }} key={department.id} className='department'>
                    <div className="department__cover">
                      <LazyLoadImage alt={title} src={ department.image ? department.image.url : noPhoto } />
                    </div>
                    <div className="department__name"><span>{title}</span></div>
                    <div className="department__link"></div>
                  </NavLink>
              )
            })
          }
      </div>
      <MobileDepartments items={items} storeId={storeId}/>
    </section>
  )
}

export default Departments
