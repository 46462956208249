import {
  Catalog,
  CATALOG_CLEAR_CURRENT_PRODUCT,
  CATALOG_ITEM_FETCHED,
  CATALOG_ITEMS_FETCHED,
  CATALOG_PAGE_FETCHED,
  CATALOG_STORE_FETCHED,
  CATALOG_STORES_FETCHED,
  CatalogActions,
  TOGGLE_CATALOG_FILTERS_VISIBILITY
} from './types'

const initialState = {
  catalogItems: [],
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  itemsPerPage: 20,
  isFiltersVisible: false,
  catalogStore: null,
  stores: []
}

export default function reduce (state: Catalog = initialState, action: CatalogActions) {
  switch (action.type) {
    case CATALOG_PAGE_FETCHED:
      return {
        ...state,
        ...action.payload
      }
    case CATALOG_ITEMS_FETCHED:
      return {
        ...state,
        ...action.payload,
        catalogItems: state.catalogItems.concat(action.payload.catalogItems)
      }
    case CATALOG_ITEM_FETCHED: {
      return {
        ...state,
        currentProduct: action.payload
      }
    }
    case TOGGLE_CATALOG_FILTERS_VISIBILITY: {
      return {
        ...state,
        isFiltersVisible: !state.isFiltersVisible
      }
    }
    case CATALOG_CLEAR_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: undefined
      }
    case CATALOG_STORE_FETCHED:
      return {
        ...state,
        catalogStore: action.payload
      }
    case CATALOG_STORES_FETCHED:
      return {
        ...state,
        stores: action.payload.sort((a, b) => {
          return a.id - b.id
        })
      }
    default:
      return state
  }
}
