import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import './footer.scss'

import { ExtensibleAttributes } from '../../types/extensible-attributes'
import SocialLinks from '../social-links/SocialLinks'
import appStore from '../../assets/images/AppStore.svg'
import googlePlay from '../../assets/images/GooglePlay.svg'
import { ReduxState } from '../../store/reducers'
import { getAppLinks } from '../../store/information/action-creators'
import { ISocialLink } from '../../store/information/types'

interface IFooterProps extends ExtensibleAttributes{
  getAppLinks: () => void
  androidLink: string
  iosLink: string
  socialLinks: ISocialLink[]
}

function inIframe () {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const Footer: React.FC<IFooterProps> = ({ className = '', getAppLinks, androidLink, iosLink, socialLinks }) => {
  const [logo, setLogo] = useState('')

  useEffect(() => {
    getAppLinks()
    import('../../assets/images/logo.svg').then(img => {
      setLogo(img.default)
    })
  }, [])

  return (
    <>
      <footer className={`${className} footer`}>
        <div className="footer__block footer__block--container">
          <img src={logo} alt="Cinch Delivery" />
          {
            !inIframe()
              ? <SocialLinks className="menu--horizontal"/>
              : null
          }
        </div>
        <div className="footer__block footer__block--info">
          <h3>Information</h3>
          <nav className="menu menu--footer menu--vertical">
            <ul className="menu__container">
              <li className="menu__item"><NavLink to="/about-us" className="menu__link menu__link">About Us</NavLink></li>
              <li className="menu__item"><NavLink to="/privacy-policy" className="menu__link">Privacy Policy</NavLink></li>
              <li className="menu__item"><NavLink to="/terms-and-conditions" className="menu__link">Terms & Conditions</NavLink></li>
              <li className="menu__item"><a href="https://www.idt.net/vcdpa" className="menu__link">Virginia Privacy Notice</a></li>
            </ul>
          </nav>
        </div>
        <div className="footer__block footer__block--info">
          <h3>Purchase</h3>
          <nav className="menu menu--footer menu--vertical">
            <ul className="menu__container">
              <li className="menu__item"><NavLink to="/delivery-information" className="menu__link">Delivery and Payment</NavLink></li>
              <li className="menu__item"><NavLink to="/terms-and-conditions" className="menu__link">Warranty and Returns</NavLink></li>
            </ul>
          </nav>
        </div>
        {
          !inIframe()
            ? <div className="footer__block footer__block--links">{ (iosLink || androidLink) && <><h3>Try our apps</h3>
              <nav className="menu menu--vertical menu--appstore">
                <ul className="menu__container">
                  <li className="menu__item">
                    {iosLink && <a className="menu__link" title="Download on the App Store" href={iosLink}>
                      <LazyLoadImage className="footer__application-store-link" src={appStore} alt="Download on the App Store" />
                    </a>}
                    { androidLink && <a className="menu__link google" title="Get it on Google Play" href={androidLink}>
                      <LazyLoadImage className="footer__application-store-link" src={googlePlay} alt="Get it on Google Play" />
                    </a> }
                  </li>
                </ul>
              </nav></> }</div>
            : null
        }
        <div className="footer__block footer__block--contacts">
          <h3>Contacts</h3>
          <address>
            <div><a className="footer__block footer__block--contacts-telephone" href={
              'tel:(347) 422-7812'
            }>{
              '(347) 422-7812'
            }</a></div>
            <div><a href={
              'mailto:deliveryicart@gmail.com'
            }>{
              'deliveryicart@gmail.com'
            }</a></div>
          </address>
        </div>
      </footer>
      <footer className={`${className} footer mobile`}>
        <div className="footer__block footer__block--info">
          <h3>Information</h3>
          <nav className="menu menu--footer menu--vertical">
            <ul className="menu__container">
              <li className="menu__item"><NavLink to="/about-us" className="menu__link menu__link">About Us</NavLink></li>
              <li className="menu__item"><NavLink to="/privacy-policy" className="menu__link">Privacy Policy</NavLink></li>
              <li className="menu__item"><NavLink to="/terms-and-conditions" className="menu__link">Terms & Conditions</NavLink></li>
              <li className="menu__item"><NavLink to="https://www.idt.net/ccpa-do-not-sell/" className="menu__link">Your California Privacy Choices</NavLink></li>
              <li className="menu__item"><a href="https://www.idt.net/ccpa" className="menu__link">California Consumer Privacy Notice</a></li>
              <li className="menu__item"><a href="https://www.idt.net/vcdpa" className="menu__link">Virginia Privacy Notice</a></li>
            </ul>
          </nav>
        </div>
        <div className="footer__block footer__block--info">
          <h3>Purchase</h3>
          <nav className="menu menu--footer menu--vertical">
            <ul className="menu__container">
              <li className="menu__item"><NavLink to="/delivery-information" className="menu__link">Delivery and Payment</NavLink></li>
              <li className="menu__item"><NavLink to="/terms-and-conditions" className="menu__link">Warranty and Returns</NavLink></li>
            </ul>
          </nav>
        </div>
        <div className="footer__block footer__block--contacts">
          <h3>Contacts</h3>
          <address>
            <h4>Main office</h4>
            <div><a href={
              'tel:(347) 422-7812'
            }>{
              '(347) 422-7812'
            }</a></div>
            <div><a href={
              'mailto:deliveryicart@gmail.com'
            }>{
              'deliveryicart@gmail.com'
            }</a></div>
          </address>
        </div>
        { (iosLink || androidLink) && <div className="footer__block footer__block--links">
          <h3>Try our apps</h3>
          <nav className="menu menu--vertical menu--appstore">
            <ul className="menu__container">
              <li className="menu__item">
                {iosLink && <a className="menu__link" title="Download on the App Store" href={iosLink}>
                  <LazyLoadImage className="footer__application-store-link" src={appStore} alt="Download on the App Store" />
                </a>}
                {androidLink && <a className="menu__link google" title="Get it on Google Play" href={androidLink}>
                  <LazyLoadImage className="footer__application-store-link" src={googlePlay} alt="Get it on Google Play" />
                </a>}
              </li>
            </ul>
            </nav>
        </div> }
        { (socialLinks && socialLinks.length > 0) && <div className="footer__block footer__block--links">
          <h3>Stay Connected</h3>
          <SocialLinks className="menu--horizontal" />
        </div> }
        <div className="footer__block footer__block--container image">
          <img src={logo} alt="Cinch Delivery" />
        </div>
      </footer>
    </>
  )
}

export default connect(
  (state: ReduxState) => {
    const android = state.information.appLinks.find(item => item.app_platform === 'android')
    const ios = state.information.appLinks.find(item => item.app_platform === 'ios')
    return {
      androidLink: android ? android.link : '',
      iosLink: ios ? ios.link : '',
      socialLinks: state.information.socialLinks
    }
  },
  {
    getAppLinks
  })(Footer)
