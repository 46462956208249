import { SocialLink } from '../../components/social-links/types'

export const PRIVACY_POLICY_FETCHED = 'PRIVACY_POLICY_FETCHED'
interface PrivacyPolicyFetched {
  type: typeof PRIVACY_POLICY_FETCHED,
  payload: string
}

export const DELIVERY_INFORMATION_FETCHED = 'DELIVERY_INFORMATION_FETCHED'
interface DeliveryInformationFetched {
  type: typeof DELIVERY_INFORMATION_FETCHED,
  payload: string
}

export const ABOUT_US_FETCHED = 'ABOUT_US_FETCHED'
interface AboutUsFetched {
  type: typeof ABOUT_US_FETCHED,
  payload: string
}

export const TERMS_AND_CONDITIONS_FETCHED = 'TERMS_AND_CONDITIONS_FETCHED'
interface TermsAndConditionsFetched {
  type: typeof TERMS_AND_CONDITIONS_FETCHED,
  payload: string
}

export const SOCIAL_NETWORK_LINKS_FETCHED = 'SOCIAL_NETWORK_LINKS_FETCHED'
interface SocialNetworkLinksFetched {
  type: typeof SOCIAL_NETWORK_LINKS_FETCHED
  payload: ISocialLink[]
}

export const APP_LINKS_FETCHED = 'APP_LINKS_FETCHED'
interface AppLinksFetched {
  type: typeof APP_LINKS_FETCHED
  payload: IPlatformLink[]
}

export const ANNOUNCEMENT_LIST_FETCHED = 'ANNOUNCEMENT_LIST_FETCHED'
interface AnnouncementListFetched {
  type: typeof ANNOUNCEMENT_LIST_FETCHED
  payload: IAnnoucementsList[]
}

export type InformationActions = PrivacyPolicyFetched
  | DeliveryInformationFetched
  | AboutUsFetched
  | TermsAndConditionsFetched
  | SocialNetworkLinksFetched
  | AppLinksFetched
  | AnnouncementListFetched

export interface IInformation {
  privacyPolicy: string
  deliveryInformation: string
  aboutUs: string
  listLoad: boolean;
  termsAndConditions: string
  socialLinks: ISocialLink[]
  appLinks: IPlatformLink[]
  announcements: IAnnoucementsList
}

export interface ISocialLink {
  social_network: SocialLink
  link: string
}

export interface IAnnoucementsList {
  currentPage: number;
  list: IAnnouncementItem[];
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
}

export interface IAnnouncementItem {
  id: string;
  title: string;
  layout: number;
  images?: AnnouncementItemImage[];
  header: AnnouncementItemBody;
  description: AnnouncementItemBody;
  additional_text: AnnouncementItemBody;
  redirect_url: string;
  background_color: number;
  loading?: boolean;
}

export interface AnnouncementItemImage {
  id?: string;
  encoded_filename: string;
  created_at: string;
  url: string;
}

export interface AnnouncementItemBody {
  text: string,
  color: number,
  align: string,
  justify: string
}

export interface IPlatformLink {
  app_platform: string
  link: string
}
