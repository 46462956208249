import {
  GET_ORDERS,
  CLEAR_ORDERS,
  OrdersActions,
  IOrders
} from './types'

const initialState: IOrders = {
  loading: true,
  list: [],
  paging_header: {
    current_page: 1,
    total_items: 2,
    total_pages: 3
  }
}

export default function reducer (state = initialState, action: OrdersActions) {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...action.payload,
        loading: false
      }
    case CLEAR_ORDERS:
      return initialState
    default:
      return state
  }
}
