import {
  IDashboard,
  DashboardActions,
  GET_DASHBOARD_DATA,
  CLEAR_DASHBOARD_DATA, GET_BONUSES_DATA
} from './types'

const initialState: IDashboard = {
  loading: true,
  favorites: null,
  orders: null,
  latestOrder: null,
  referrals: [],
  total: 0,
  available: 0
}

export default function reducer (state = initialState, action: DashboardActions) {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return {
        ...action.payload,
        loading: false
      }
    case CLEAR_DASHBOARD_DATA:
      return initialState
    case GET_BONUSES_DATA:
      return {
        ...state,
        referrals: action.payload.referrals,
        total: action.payload.total,
        available: action.payload.available
      }
    default:
      return state
  }
}
