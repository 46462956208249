import { IAddress } from '../store/customers/types'

export default function getAddressString (address?: IAddress) {
  if (!address) {
    return 'Unknown address'
  }

  const addressArr = []
  addressArr.push(address.zip)
  addressArr.push(address.line1)
  addressArr.push(address.city)

  return addressArr.join(', ')
}
