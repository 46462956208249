import { ThunkDispatch } from 'redux-thunk'
import { DepartmentsService } from '../../services/DepartmentsService'
import { IDepartments, DEPARTMENTS_FETCHED, IDepartment, ICategory } from './types'

export function fetchDepartments (page: number, items: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const res = await DepartmentsService.getItems({ page })

    const categoriesList: ICategory[] = res.list.reduce((acc: ICategory[], department: IDepartment) => {
      const categories: ICategory[] = department.categories.map(category => ({
        id: category.id,
        title: category.title,
        image: category.image,
        departmentId: department.id
      }))
      acc.push(...categories)
      return acc
    }, [])

    const departments: IDepartments = {
      departmentItems: res.list as IDepartment[],
      categories: categoriesList,
      totalItems: res.paging_header.total_items,
      totalPages: res.paging_header.total_pages,
      currentPage: res.paging_header.current_page,
      itemsPerPage: items
    }

    dispatch({
      type: DEPARTMENTS_FETCHED,
      payload: departments as IDepartments
    })
  }
}
