import { INewsState, NEWS_FETCHED, NEWS_ITEM_FETCHED, NEWS_REMOVE_SELECTED, NewsActions } from './types'
import { makeLoadersArray } from '../../utils/LoadersArray'

const initialState: INewsState = {
  list: makeLoadersArray(3, 'news'),
  pagingHeader: {
    total_items: 0,
    total_pages: 0,
    items_per_page: 20,
    current_page: 1
  },
  selectedNewsItem: undefined
}

export default function reduce (state = initialState, action: NewsActions) {
  switch (action.type) {
    case NEWS_FETCHED:
      return { ...state, ...action.payload }
    case NEWS_ITEM_FETCHED:
      return {
        ...state,
        selectedNewsItem: action.payload
      }
    case NEWS_REMOVE_SELECTED:
      return {
        ...state,
        selectedNewsItem: undefined
      }
    default:
      return state
  }
}
