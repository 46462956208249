import { ThunkDispatch } from 'redux-thunk'

import { SearchService } from '../../services/SearchService'
import { CLEAR_SEARCH_DATA, PRE_SEARCH_CLEAR, PRE_SEARCH_FETCHED, PRE_SEARCH_LOADING_STATE, PRE_SEARCH_VISIBLE, SEARCH_FETCHED, SEARCH_LOADING_STATE, SEARCH_MORE_ITEMS, SEARCH_PAGE } from './types'
import { convertPreSearch } from '../../utils/convertPreSearch'

export function loadMore (searchString: string, page: number, category: string, clearData: boolean) {
  const itemsPerPage = 20

  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    try {
      const newItems = await SearchService.loadMore(searchString, page, category, itemsPerPage)
      const data = {
        list: newItems.list,
        currentPage: newItems.paging_header.current_page,
        totalItems: newItems.paging_header.total_items,
        totalPages: newItems.paging_header.total_pages,
        more_available: newItems.list.length >= itemsPerPage,
        itemsPerPage: 20,
      }
      dispatch({
        type: clearData ? SEARCH_PAGE : SEARCH_MORE_ITEMS,
        payload: data,
        category: category
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export function search (searchString: string) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SEARCH_LOADING_STATE,
      payload: true
    })
    try {
      const data = await SearchService.search(searchString)
      dispatch({
        type: SEARCH_FETCHED,
        payload: data
      })
    } catch (e) {
      console.error(e)
    } finally {
      dispatch({
        type: SEARCH_LOADING_STATE,
        payload: false
      })
    }
  }
}

export function preSearch (searchString: string) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: PRE_SEARCH_LOADING_STATE,
      payload: true
    })
    try {
      const result = await SearchService.search(searchString)
      const data = convertPreSearch(result)
      dispatch({
        type: PRE_SEARCH_FETCHED,
        payload: data
      })
    } catch (e) {
      console.error(e)
    } finally {
      dispatch({
        type: PRE_SEARCH_LOADING_STATE,
        payload: false
      })
    }
  }
}

export function preSearchVisible (isVisible: boolean) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: PRE_SEARCH_VISIBLE,
      payload: isVisible
    })
  }
}

export function preSearchClear () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: PRE_SEARCH_CLEAR
    })
  }
}

export function clearSearchData () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: CLEAR_SEARCH_DATA
    })
  }
}
