import { IPreSearchItem, ISearchResultCategory } from '../store/search/types'
import getAddressString from '../utils/GetAddressString'
import priceToString from './PriceToString'

export function convertPreSearch (data: ISearchResultCategory): IPreSearchItem[] {
  const result: IPreSearchItem[] = []

  const toPush = {
    stores: 1,
    departments: 1,
    categories: 1,
    products: 4
  }

  if (data.stores.list.length > 0) {
    toPush.stores = 1
  } else {
    toPush.stores = 0
    toPush.products++
  }

  if (data.departments.list.length > 0) {
    toPush.departments = 1
  } else {
    toPush.departments = 0
    toPush.products++
  }

  if (data.categories.list.length > 0) {
    toPush.categories = 1
  } else {
    toPush.categories = 0
    toPush.products++
  }

  if (toPush.stores) {
    const store = data.stores.list[0]
    result.push({
      image: store.network.image,
      name: store.name,
      link: `/store/${store.id}`,
      type: 'store',
      title: getAddressString(store.address)
    })
  }

  if (toPush.departments) {
    const department = data.departments.list[0]
    result.push({
      image: department.image ? department.image.url : null,
      name: department.title,
      link: `/catalog?department=${department.id}`,
      type: 'department',
      title: `Categories: ${department.categories.map((c:any) => c.title).join(', ')}`
    })
  }

  if (toPush.categories) {
    const category = data.categories.list[0]
    const department = data.departments.list.find(d => d.id === category.department_id)
    result.push({
      image: category.image ? category.image.url : null,
      name: category.title,
      link: `/catalog?department=${category.department_id}&category=${category.id}`,
      type: 'category',
      title: department ? `From ${department.title}` : ''
    })
  }

  if (toPush.products) {
    data.products.list.slice(0, toPush.products).forEach(item => {
      result.push({
        image: item.images[0] ? item.images[0].url : null,
        name: item.name,
        link: `/catalog/${item.id}`,
        type: 'product',
        price: priceToString(item.real_sale_price)
      })
    })
  }

  return result
}
