import { ReduxState } from '../reducers'
import { ThunkDispatch } from 'redux-thunk'
import { CartService } from '../../services/CartService'
import { DECREMENT_CART_ITEM, DROP_CART, GET_MULTI_CART, INCREMENT_CART_ITEM, SHOW_CART, HIDE_CART } from './types'
import { UserType } from '../account/profile/types'
import { CatalogService } from '../../services/CatalogService'

export function getCart () {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()

    let data = null
    if (state.account.profile.userType === UserType.CustomerUserType) {
      data = await CartService.get()
    } else {
      data = await CartService.getGuestCart()
    }
    dispatch({
      type: GET_MULTI_CART,
      payload: data
    })
  }
}

export function updateCart (data) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: GET_MULTI_CART,
      payload: [data]
    })
  }
}

export function dropCart () {
  return {
    type: DROP_CART
  }
}

export function showCart () {
  return {
    type: SHOW_CART
  }
}

export function hideCart () {
  return {
    type: HIDE_CART
  }
}

export function incrementCartItem ({ id, count = 1, storeId }: { id: string, count?: number, storeId?: number }) {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()
    let data
    if (state.account.profile.userType === UserType.CustomerUserType) {
      data = await CartService.add({ id, count, storeId })
    } else {
      data = await CartService.addGuestCartItem({ id, count, storeId })
    }
    dispatch({
      type: INCREMENT_CART_ITEM,
      payload: {
        id,
        count
      }
    })
    dispatch({
      type: GET_MULTI_CART,
      payload: data
    })
  }
}

export function decrementCartItem ({ id, count = 1, storeId }: { id: string, count?: number, storeId?: number }) {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()
    let data
    if (state.account.profile.userType === UserType.CustomerUserType) {
      data = await CartService.remove({ id, count, storeId })
    } else {
      data = await CartService.removeGuestCartItem({ id, count, storeId })
    }
    dispatch({
      type: DECREMENT_CART_ITEM,
      payload: {
        id,
        count
      }
    })
    dispatch({
      type: GET_MULTI_CART,
      payload: data
    })
  }
}

export function getDeliveryInfo (address: object[], storeIds: number[]) {
  return async () => {
    const data = await CatalogService.getDeliveryInfo(address, storeIds)
    return data
  }
}
