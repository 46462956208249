import {
  ADD_ADDRESS,
  CLEAR_ORDER_DATA,
  EDIT_ADDRESS,
  GET_ADDRESSES,
  GET_COUPON,
  GET_COUPON_START,
  IOrder,
  ORDER_CREATED,
  OrderActions,
  COUPON_AVAILABILITY,
  CLEAR_COUPON_AVAILABILITY_DATA,
  CLEAR_COUPON_DATA
} from './types'

const initialState = {
  list: [],
  paging_header: null,
  couponLoading: false,
  coupon: null,
  isCouponAvailable: null
}

export default function reducer (state: IOrder = initialState, action: OrderActions) {
  switch (action.type) {
    case GET_ADDRESSES:
      return {
        ...state,
        list: action.payload || []
      }
    case ADD_ADDRESS: {
      const { list } = state
      return {
        ...state,
        list: [...list, action.payload]
      }
    }
    case EDIT_ADDRESS: {
      const { list } = state
      const newList = list.map(address => {
        if (address.id === action.payload.id) {
          return {
            ...address,
            ...action.payload
          }
        } else {
          return address
        }
      })
      return {
        ...state,
        list: newList
      }
    }
    case GET_COUPON_START:
      return {
        ...state,
        couponLoading: true
      }
    case COUPON_AVAILABILITY: {
      const { available } = action.payload
      return {
        ...state,
        isCouponAvailable: available
      }
    }
    case CLEAR_COUPON_DATA:
      return {
        ...state,
        coupon: null
      }
    case CLEAR_COUPON_AVAILABILITY_DATA:
      return {
        ...state,
        isCouponAvailable: null
      }
    case GET_COUPON: {
      const couponData = action.payload
      return {
        ...state,
        couponLoading: false,
        coupon: couponData
      }
    }
    case CLEAR_ORDER_DATA:
      return initialState
    case ORDER_CREATED:
    default:
      return state
  }
}
