import React from 'react'
import Loader from '../loader/Loader'

import checkImg from '../../assets/images/check.svg'
import crossImg from '../../assets/images/cross.svg'

export enum ProgressStatus {
  None,
  Saving,
  Error,
  Saved
}

interface ProgressProps {
  status: ProgressStatus
}

const Progress: React.FunctionComponent<ProgressProps> = ({ status }) => {
  switch (status) {
    case ProgressStatus.Saving:
      return <Loader color='orange' />
    case ProgressStatus.Saved:
      return <span className="outer check"><img src={checkImg} alt="check" /></span>
    case ProgressStatus.Error:
      return <span className="outer cross"><img src={crossImg} alt="cross" /></span>
    default:
      return null
  }
}

export default Progress
