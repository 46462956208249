import React, { ChangeEvent, SyntheticEvent, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../../store/reducers'
import parseToInt from '../../utils/PriceToInt'
import { CartService } from '../../services/CartService'
import classnames from 'classnames'
import debounce from 'lodash.debounce'
import Loader from '../loader/Loader'
import { IServerErrors } from '../../types/ServerErrors'
import { updateCart } from '../../store/cart/actions'

import checkImg from '../../assets/images/check.svg'

export interface ITipsMessage {
  message: string,
  type?: string
}

interface ITipsProps {
  updateCart: (data: any) => void,
  driver_tips?: number
}

const DriverTips: React.FC<ITipsProps> = props => {
  const [driverTips, setDriverTips] = useState(0)
  const [tipsApplied, setTipsApplied] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tipsMessage, setTipsMessage] = useState<ITipsMessage | null>(null)

  function addTipsToCart (bonuses: number) {
    setLoading(true)
    setDriverTips(bonuses)
    CartService.useTips(bonuses)
      .then((res) => {
        setTipsMessage(null)
        setLoading(false)
        setTipsApplied(true)
        props.updateCart(res)
      })
      .catch((error: IServerErrors) => {
        if (error.errors && error.errors.length > 0) {
          setTipsMessage({
            type: 'error',
            message: error.errors[0].message
          })
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    if (props.driver_tips) {
      setDriverTips(props.driver_tips)
    }
  }, [props.driver_tips])

  const onInputChange = debounce((e) => {
    if (e.target.value !== '') {
      addTipsToCart(parseToInt(e.target.value))
    } else {
      addTipsToCart(0)
    }
  }, 2000)

  const onFieldChange = useCallback(debounce(onInputChange), [])

  function prepareMoney (money: number) {
    return money / 100
  }

  return (
      <>
        <label className="small-text" onClick={(e: SyntheticEvent) => {
          e.preventDefault()
        }}>
          You can tip the delivery person
        </label>
        <div className="money-buttons" >
          <button className={classnames('secondary__cancel', { clear: driverTips === 0 })} onClick={(e: SyntheticEvent): void => {
            e.preventDefault()
            setTipsApplied(false)
            addTipsToCart(0)
          }} disabled={driverTips === 0} />
          <button className={classnames('secondary', { active: driverTips === 2000 })} onClick={(e: SyntheticEvent): void => {
            e.preventDefault()
            setTipsApplied(false)
            addTipsToCart(2000)
          }} disabled={loading}>
            $20
              </button>
          <button className={classnames('secondary', { active: driverTips === 1500 })} onClick={(e: SyntheticEvent): void => {
            e.preventDefault()
            setTipsApplied(false)
            addTipsToCart(1500)
          }} disabled={loading}>
            $15
              </button>
          <button className={classnames('secondary', { active: driverTips === 1000 })} onClick={(e: SyntheticEvent): void => {
            e.preventDefault()
            setTipsApplied(false)
            addTipsToCart(1000)
          }} disabled={loading}>
            $10
              </button>
          <button className={classnames('secondary', { active: driverTips === 500 })} onClick={(e: SyntheticEvent): void => {
            e.preventDefault()
            setTipsApplied(false)
            addTipsToCart(500)
          }} disabled={loading}>
            $5
              </button>
        </div>
        <label className="tiny-text">
          Other amount
          <div className="money-container">
            {loading ? <Loader color="orange" className="loader" /> : tipsApplied ? <div className="outer check"><img src={checkImg} alt="check" /></div> : null}
            {/* <div className="image-container">
              <img src={cinchBonuses} alt="cinch bottle"/>
            </div> */}
            {driverTips ? <div className="input-prepend driver">$</div> : null}
            <input
              className="tips"
              type="number"
              value={driverTips ? prepareMoney(driverTips) : ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.persist()
                setTipsApplied(false)
                if (event.target.value !== '') {
                  setDriverTips(parseToInt(event.target.value))
                } else {
                  setDriverTips(0)
                }
                onFieldChange(event)
              }}
              min={0.00}
              step={0.01}
              placeholder="No tips"
            />
          </div>
        </label>

        {tipsMessage
          ? <p className="error">{tipsMessage.message}</p>
          : null}
      </>
  )
}

function stateToProps (state: ReduxState) {
  return {
    ...state.cart
  }
}

export default connect(stateToProps, { updateCart })(DriverTips)
