import {
  ADD_TO_FAVORITES,
  FavoritesActions,
  FETCH_FAVORITES,
  IFavoritesState,
  REMOVE_FROM_FAVORITES
} from './types'

const initialState: IFavoritesState = {
  favorites: []
}

export default function reducer (state = initialState, action: FavoritesActions) {
  switch (action.type) {
    case FETCH_FAVORITES:
      return {
        ...state,
        favorites: action.payload || []
      }
    case ADD_TO_FAVORITES:
      return {
        ...state,
        favorites: state.favorites.concat(action.payload)
      }
    case REMOVE_FROM_FAVORITES:
      return {
        ...state,
        favorites: state.favorites.filter(item => {
          return String(item.id) !== action.payload
        })
      }
    default:
      return state
  }
}
