import { IPaginator } from '../../types/paginator'
import { CatalogItemImage } from '../catalog/types'

export interface INewsItem {
  id: number
  title: string
  body: string
  images: CatalogItemImage[]
  date_created: string
  date_updated: string
  loading?: boolean
}

export interface INews {
  list: INewsItem[]
  pagingHeader: IPaginator
}

export const NEWS_FETCHED = 'NEWS_FETCHED'
interface NewsFetched {
  type: typeof NEWS_FETCHED
  payload: INews[]
}

export const NEWS_ITEM_FETCHED = 'NEWS_ITEM_FETCHED'
interface NewsItemFetched {
  type: typeof NEWS_ITEM_FETCHED
  payload: INewsItem[]
}

export const NEWS_REMOVE_SELECTED = 'NEWS_REMOVE_SELECTED'
interface NewsRemoveSelected {
  type: typeof NEWS_REMOVE_SELECTED
}

export type NewsActions = NewsFetched | NewsItemFetched | NewsRemoveSelected;

export interface INewsState {
  list: INewsItem[]
  pagingHeader: IPaginator,
  selectedNewsItem?: INewsItem
}
