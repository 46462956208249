import { ApiService, ApiServiceClass } from './ApiService'

export class DepartmentsServiceClass {
  apiService: ApiServiceClass;

  constructor (apiService: ApiServiceClass) {
    this.apiService = apiService
  }

  async getItems ({ page = 1 } = {}) {
    // without API-endpoint to categories we must download all of departments
    const res = await this.apiService.post(`/catalog/departments/list/${page}/50`)
    return res
  }

  async getItem (id: string) {
    const res = await this.apiService.get(`/catalog/departments/${id}`)
    return res
  }

  async getItemsByStore ({ page = 1, storeId = '' } = {}) {
    const res = await this.apiService.post(`/catalog/departments/list/${page}/50`, storeId
      ? {
          filtering: {
            store_ids: [storeId]
          }
        }
      : null)
    return res
  }
}

export const DepartmentsService = new DepartmentsServiceClass(ApiService)
