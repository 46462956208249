import { CatalogItem } from '../catalog/types'

export const FEATURED_ITEMS_FETCHED = 'FEATURED_ITEMS_FETCHED'
interface FeaturedItemsFetched {
  type: typeof FEATURED_ITEMS_FETCHED
  payload: CatalogItem[]
}

export const NEW_ARRIVALS_ITEMS_FETCHED = 'NEW_ARRIVALS_ITEMS_FETCHED'
interface NewArrivalsFetched {
  type: typeof NEW_ARRIVALS_ITEMS_FETCHED
  payload: CatalogItem[]
}

export const SUGGESTS_ITEMS_FETCHED = 'SUGGESTS_ITEMS_FETCHED'
interface SuggestsFetched {
  type: typeof SUGGESTS_ITEMS_FETCHED
  payload: CatalogItem[]
}
export const ORDER_AGAIN_FETCHED = 'ORDER_AGAIN_FETCHED'
interface OrderAgainFetched {
  type: typeof ORDER_AGAIN_FETCHED
  payload: CatalogItem[]
}
export const SET_IS_ASAP_DELIVERY = 'SET_IS_ASAP_DELIVERY'
interface SetIsAsapDelivery {
  type: typeof SET_IS_ASAP_DELIVERY
  payload: boolean
}

export const CLEAR_MAIN_STATE = 'CLEAR_MAIN_STATE'
interface ClearMainState {
  type: typeof CLEAR_MAIN_STATE
}

export type MainPageActions = SuggestsFetched | NewArrivalsFetched | FeaturedItemsFetched | OrderAgainFetched | SetIsAsapDelivery | ClearMainState

export interface MainPage {
  featured: CatalogItem[]
  featuredLoad: boolean
  suggests: CatalogItem[]
  suggestsLoad: boolean
  newArrivals: CatalogItem[]
  newArrivalsLoad: boolean
  orderAgain: CatalogItem[]
  orderAgainLoad: boolean
  isAsapDelivery: boolean
}
