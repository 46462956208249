import React, { ChangeEvent, SyntheticEvent, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../../store/reducers'
import priceToString from '../../utils/PriceToString'
import parseToInt from '../../utils/PriceToInt'
import { BonusService } from '../../services/BonusService'
import Loader from '../loader/Loader'
import { IServerErrors } from '../../types/ServerErrors'
import classnames from 'classnames'
import debounce from 'lodash.debounce'
import { updateCart } from '../../store/cart/actions'

import checkImg from '../../assets/images/check.svg'

export interface IBonusMessage {
  message: string,
  type?: string
}

interface IBonusProps {
  allBonuses: number,
  updateCart: (data: any) => void,
  bonuses_to_use?: number
}

const Bonus: React.FC<IBonusProps> = props => {
  const [preparedBonuses, setPreparedBonuses] = useState(0)
  const [bonusesApplied, setBonusesApplied] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bonusMessage, setBonusMessage] = useState<IBonusMessage | null>(null)

  useEffect(() => {
    if (props.bonuses_to_use) {
      setPreparedBonuses(props.bonuses_to_use)
    }
  }, [props.bonuses_to_use])

  function addBonusesToCart (bonuses: number) {
    setLoading(true)
    BonusService.useBonuses(bonuses)
      .then((res) => {
        setBonusMessage(null)
        setLoading(false)
        if (preparedBonuses || preparedBonuses === 0) {
          setBonusesApplied(true)
          props.updateCart(res)
        }
      })
      .catch((error: IServerErrors) => {
        if (error.errors && error.errors.length > 0) {
          setBonusMessage({
            type: 'error',
            message: error.errors[0].message
          })
          setLoading(false)
        }
      })
  }

  const onInputChange = debounce((e) => {
    if (e.target.value !== '') {
      addBonusesToCart(parseToInt(e.target.value))
    } else {
      addBonusesToCart(0)
    }
  }, 2000)

  const onFieldChange = useCallback(debounce(onInputChange), [])

  function prepareMoney (money: number) {
    return money / 100
  }

  return (props.allBonuses > 0
    ? <>
      <label>
        {`You have ${priceToString(props.allBonuses)} Cinch bonuses`}
      </label>
      <div className="money-buttons" >
        <button className={classnames('secondary__cancel', { clear: preparedBonuses === 0 })} onClick={(e: SyntheticEvent): void => {
          e.preventDefault()
          setPreparedBonuses(0)
          addBonusesToCart(0)
        }} disabled = {preparedBonuses === 0} />
        <button className={classnames('secondary', { active: preparedBonuses === props.allBonuses })} onClick={(e: SyntheticEvent): void => {
          e.preventDefault()
          setPreparedBonuses(props.allBonuses)
          setBonusesApplied(false)
          addBonusesToCart(props.allBonuses)
        }} disabled = {loading}>
          All
        </button>
        {
          props.allBonuses >= 1500
            ? <button className={classnames('secondary', { active: preparedBonuses === 1500 })} onClick={(e: SyntheticEvent): void => {
              e.preventDefault()
              setPreparedBonuses(1500)
              setBonusesApplied(false)
              addBonusesToCart(1500)
            }} disabled = {loading}>
              $15
          </button>
            : null
        }
        {
          props.allBonuses >= 1000
            ? <button className={classnames('secondary', { active: preparedBonuses === 1000 })} onClick={(e: SyntheticEvent): void => {
              e.preventDefault()
              setPreparedBonuses(1000)
              setBonusesApplied(false)
              addBonusesToCart(1000)
            }} disabled = {loading}>
              $10
          </button>
            : null
        }
        {
          props.allBonuses >= 500
            ? <button className={classnames('secondary', { active: preparedBonuses === 500 })} onClick={(e: SyntheticEvent): void => {
              e.preventDefault()
              setPreparedBonuses(500)
              setBonusesApplied(false)
              addBonusesToCart(500)
            }} disabled = {loading}>
              $5
          </button>
            : null
        }
      </div>
      <label>
        Other amount
          <div className="money-container">
          {loading ? <Loader color="orange" className="loader" /> : bonusesApplied ? <div className="outer check"><img src={checkImg} alt="check" /></div> : null}
          {preparedBonuses ? <div className="input-prepend driver">$</div> : null}
          <input
            className="tips"
            type="number"
            value={preparedBonuses ? prepareMoney(preparedBonuses) : ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              event.persist()
              setBonusesApplied(false)
              if (event.target.value !== '') {
                setPreparedBonuses(parseToInt(event.target.value))
              } else {
                setPreparedBonuses(0)
              }
              onFieldChange(event)
            }}
            min={0.00}
            step={0.01}
            placeholder='$10'
          />
        </div>
      </label>
      { bonusMessage
        ? <p className="error">{bonusMessage.message}</p>
        : null}
    </>
    : null
  )
}

function stateToProps (state: ReduxState) {
  return {
    ...state.cart
  }
}

export default connect(stateToProps, { updateCart })(Bonus)
