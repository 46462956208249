import React, { useState } from 'react'
import './account.scss'

import Subsection, { SubsectionProps } from '../Subsection'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'
import ResetPasswordForm from './ResetPasswordForm'
import { AccountFormType } from '../../Header'
import VerifySmsCodeForm from './VerifySmsCodeForm'

interface AccountProps {
  showSignIn: AccountFormType,
  onResetPassword: () => void
}

const Account: React.FunctionComponent<AccountProps & SubsectionProps> = props => {
  const [showLogin, toggleShowLogin] = useState(AccountFormType.SignIn)
  const innerProps = {
    close: props.close,
    toggle: () => toggleShowLogin(showLogin === AccountFormType.SignUp ? AccountFormType.SignIn : AccountFormType.SignUp),
    setAccountTypeForm: (type: AccountFormType) => { toggleShowLogin(type) }
  }
  const resetPassword = () => {
    toggleShowLogin(AccountFormType.ResetPassword)
  }

  return (
    <Subsection {...props}>
      <div className="subsection__columns-container account-container">
        {
          showLogin === AccountFormType.SignIn && <SignInForm {...innerProps} resetPassword={resetPassword} />
        }
        {
          showLogin === AccountFormType.SignUp && <SignUpForm {...innerProps}/>
        }
        {
          showLogin === AccountFormType.ResetPassword && <ResetPasswordForm {...innerProps} />
        }
        {
          showLogin === AccountFormType.VerifySmsCode && <VerifySmsCodeForm {...innerProps} />
        }
      </div>
    </Subsection>
  )
}

export default Account
