import {
  ACCOUNT_PROFILE_BRAINTREE_TOKEN_FETCHED,
  ACCOUNT_PROFILE_LOGGED_IN,
  ACCOUNT_PROFILE_SET_USER_TYPE,
  ACCOUNT_PROFILE_SIGN_OUT,
  ACCOUNT_PROFILE_TOGGLE_LOGIN_FORM,
  ACCOUNT_PROFILE_USER_DATA_CLEAR,
  ACCOUNT_PROFILE_USER_DATA_FETCHED,
  ACCOUNT_PROFILE_USER_DATA_UPDATE,
  ACCOUNT_PROFILE_BRAINTREE_TOKEN_CLEAR,
  ACCOUNT_PROFILE_TOGGLE_STANDALONE_LOGIN,
  AccountProfileActions,
  ProfileInfo,
  UserType,
  ACCOUNT_PROFILE_USER_NOTIFICATIONS_DATA_FETCHED,
  ACCOUNT_PROFILE_USER_CREATED,
  CustomerInfo, ACCOUNT_PROFILE_USER_ADDRESS_SAVED, ACCOUNT_PROFILE_USER_PHONE_UPDATE
} from './types'
import { CustomersService } from '../../../services/CustomersService'

export interface IProfileAccountInfo extends ProfileInfo {
  showStandaloneLoginForm: boolean
  verifySmsCodeInformation?: CustomerInfo & { typeSign: string, '2fa_mode'?: string } | null
  userLocation?: string | null
  phoneConfirm: boolean
}

const initialState = {
  loggedIn: false,
  profile: null,
  signUpFormData: {
    email: '',
    password: ''
  },
  phoneConfirm: false,
  showLoginForm: true,
  userType: CustomersService.getUserType(),
  braintree_token: null,
  showStandaloneLoginForm: false,
  verifySmsCodeInformation: null,
  userLocation: localStorage.getItem('cinch:userLocation')
}

export default function reduce (state: IProfileAccountInfo = initialState, action: AccountProfileActions) {
  switch (action.type) {
    case 'ACCOUNT_PROFILE_SIGNED_UP':
    case 'ACCOUNT_PROFILE_SAVE_PROFILE_DATA':
      break
    case ACCOUNT_PROFILE_TOGGLE_LOGIN_FORM:
      return {
        ...state,
        showLoginForm: !state.showLoginForm
      }
    case ACCOUNT_PROFILE_SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload
      }
    case ACCOUNT_PROFILE_USER_DATA_FETCHED:
      return {
        ...state,
        profile: action.payload
      }
    case ACCOUNT_PROFILE_USER_DATA_CLEAR:
      return {
        ...state,
        ...initialState
      }
    case ACCOUNT_PROFILE_USER_DATA_UPDATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload
        }
      }
    case ACCOUNT_PROFILE_USER_PHONE_UPDATE:
      return {
        ...state,
        phoneConfirm: action.payload
      }
    case ACCOUNT_PROFILE_SIGN_OUT:
      return {
        ...state,
        userType: UserType.GuestUserType,
        profile: null,
        loggedIn: false
      }
    case ACCOUNT_PROFILE_BRAINTREE_TOKEN_FETCHED: {
      return {
        ...state,
        braintree_token: action.payload
      }
    }
    case ACCOUNT_PROFILE_BRAINTREE_TOKEN_CLEAR: {
      return {
        ...state,
        braintree_token: action.payload
      }
    }
    case ACCOUNT_PROFILE_TOGGLE_STANDALONE_LOGIN:
      return {
        ...state,
        showStandaloneLoginForm: action.payload
      }
    case ACCOUNT_PROFILE_USER_NOTIFICATIONS_DATA_FETCHED:
      return {
        ...state,
        profile: {
          ...state.profile,
          preferences: {
            ...state.profile && { ...state.profile.preferences },
            notifications: {
              ...action.payload
            }
          }
        }
      }
    case ACCOUNT_PROFILE_USER_CREATED: {
      return {
        ...state,
        verifySmsCodeInformation: {
          ...action.payload
        }
      }
    }
    case ACCOUNT_PROFILE_USER_ADDRESS_SAVED: {
      const userLocation = JSON.stringify(action.payload)
      localStorage.setItem('cinch:userLocation', userLocation)

      return {
        ...state,
        userLocation
      }
    }
    case ACCOUNT_PROFILE_LOGGED_IN:
    default:
      return state
  }
}
