import { ThunkDispatch } from 'redux-thunk'
import { formValueSelector } from 'redux-form'
import Cookies from 'js-cookie'
import { ReduxState } from '../../reducers'
import { CustomersService } from '../../../services/CustomersService'
import { CustomerLoginInfo, CustomerSignUpInfo } from '../../customers/types'
import {
  ACCOUNT_PROFILE_LOGGED_IN,
  ACCOUNT_PROFILE_SET_USER_TYPE,
  ACCOUNT_PROFILE_SIGN_OUT,
  ACCOUNT_PROFILE_USER_DATA_FETCHED,
  ACCOUNT_PROFILE_USER_DATA_CLEAR,
  ACCOUNT_PROFILE_USER_DATA_UPDATE,
  CustomerDataChanges,
  UserType,
  ACCOUNT_PROFILE_BRAINTREE_TOKEN_FETCHED,
  ACCOUNT_PROFILE_BRAINTREE_TOKEN_CLEAR,
  ACCOUNT_PROFILE_TOGGLE_STANDALONE_LOGIN,
  ACCOUNT_PROFILE_USER_NOTIFICATIONS_DATA_FETCHED,
  ACCOUNT_PROFILE_USER_CREATED,
  ACCOUNT_PROFILE_USER_ADDRESS_SAVED,
  ACCOUNT_PROFILE_USER_PHONE_UPDATE,
  ACCOUNT_PROFILE_DELETE
} from './types'
import { AddressData, CLEAR_ORDER_DATA } from '../../order/types'
import { INotificationsSettings } from '../../../components/notifications/Notifications'
import { OrderService } from '../../../services/OrderService'
import { GuestService } from '../../../services/GuestsService'

export function loginByEmail (logInInfo: CustomerLoginInfo) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    let customer = null
    try {
      customer = await CustomersService.login(logInInfo)
    } catch (e) {
      if (e && e.errors && e.errors[0].code === 'login_phone_value_empty') {
        customer = {
          error: 'login_phone_value_empty'
        }
      } else {
        throw e
      }
    }

    dispatch({
      type: ACCOUNT_PROFILE_USER_CREATED,
      payload: {
        ...logInInfo,
        ...customer
      }
    })
  }
}

export function loginByGoogle (token: string, mode: string = 'sms') {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    if (!token) {
      const googleAuth = gapi.auth2.getAuthInstance()
      const user = await googleAuth.signIn()
      const authResponse = user.getAuthResponse(true)
      token = authResponse.access_token
    }
    let customer = null
    const signInInfo = {
      token: token,
      type: 'Google',
      '2fa_mode': mode
    }
    try {
      customer = await CustomersService.loginByToken(signInInfo)
    } catch (e) {
      if (e && e.errors && e.errors[0].code === 'login_phone_value_empty') {
        customer = {
          error: 'login_phone_value_empty'
        }
      } else {
        throw e
      }
    }

    dispatch({
      type: ACCOUNT_PROFILE_USER_CREATED,
      payload: {
        ...customer,
        ...signInInfo,
        typeSign: 'login'
      }
    })
  }
}

export function loginByApple (token: string, mode: string = 'sms') {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    let customer = null
    const signInInfo = {
      token: token,
      type: 'Apple',
      '2fa_mode': mode
    }
    try {
      customer = await CustomersService.loginByToken(signInInfo)
    } catch (e) {
      if (e && e.errors && e.errors[0].code === 'login_phone_value_empty') {
        customer = {
          error: 'login_phone_value_empty'
        }
      } else {
        throw e
      }
    }

    dispatch({
      type: ACCOUNT_PROFILE_USER_CREATED,
      payload: {
        ...customer,
        ...signInInfo,
        typeSign: 'login'
      }
    })
  }
}

export function signUpAndLoginByEmail (signUpInfo: CustomerSignUpInfo) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    if (signUpInfo.referral_code) {
      signUpInfo.referral_code = String(signUpInfo.referral_code)
      signUpInfo.referral_code = signUpInfo.referral_code.trim().replace(/[^A-Za-z0-9]/g, '')
    }

    await CustomersService.createCustomer(signUpInfo)
    return loginByEmail(signUpInfo)(dispatch)
  }
}

export function getUnfinishedRegister () {
  return async function () {
    const customer = await CustomersService.getUnfinishedRegister()

    return customer
  }
}

export function createCustomer (signUpInfo: CustomerSignUpInfo) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    if (signUpInfo.referral_code) {
      signUpInfo.referral_code = String(signUpInfo.referral_code)
      signUpInfo.referral_code = signUpInfo.referral_code.trim().replace(/[^A-Za-z0-9]/g, '')
    }

    const customer = await CustomersService.startRegister(signUpInfo)

    dispatch({
      type: ACCOUNT_PROFILE_USER_CREATED,
      payload: {
        ...signUpInfo,
        typeSign: 'register'
        // email: signUpInfo.email,
        // password: signUpInfo.password
      }
    })

    return customer
  }
}

export function verifySmsCode (code: string) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) {
    const state = getState()
    const customer = state.account.profile.verifySmsCodeInformation
    const customerId = String(customer && customer.id)
    let newCustomer
    if (customer && customer.typeSign === 'register') {
      newCustomer = await CustomersService.verifySmsCodeRegister(code)
    } else {
      newCustomer = await CustomersService.verifySmsCode(customerId, code)
    }
    dispatch({
      type: ACCOUNT_PROFILE_LOGGED_IN,
      payload: newCustomer
    })
    dispatch({
      type: ACCOUNT_PROFILE_SET_USER_TYPE,
      payload: UserType.CustomerUserType
    })
  }
}

export function resendCode (mode: string) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) {
    const state = getState()
    const customer = state.account.profile.verifySmsCodeInformation || {
      email: '',
      password: '',
      type: '',
      token: '',
      '2fa_mode': 'sms',
      typeSign: 'login'
    }

    customer['2fa_mode'] = mode

    if (customer.type === 'Google') {
      await loginByGoogle(customer.token, mode)(dispatch)
    } else if (customer.type === 'Apple') {
      await loginByApple(customer.token, mode)(dispatch)
    } else {
      if (customer.typeSign === 'register') {
        await createCustomer({
          ...customer
        })(dispatch)
      } else {
        await loginByEmail({
          ...customer
        })(dispatch)
      }
    }
  }
}

export function updateUserPhone (phone: string) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) {
    const state = getState()
    const customer = state.account.profile.verifySmsCodeInformation as any

    if (customer.email) {
      await loginByEmail({
        email: customer.email,
        password: customer.password,
        phone,
        '2fa_mode': 'sms'
      })(dispatch)
    } else if (customer.type) {
      const { id } = await CustomersService.loginByToken({
        token: customer.token,
        type: customer.type,
        '2fa_mode': 'sms',
        phone
      }) || { id: null }
      dispatch({
        type: ACCOUNT_PROFILE_USER_CREATED,
        payload: {
          ...customer,
          id,
          typeSign: 'login'
        }
      })
    }
  }
}

export function initResetPassword (signUpInfo: CustomerSignUpInfo) {
  const expireDate = new Date()
  expireDate.setMinutes(expireDate.getMinutes() + 30)
  Cookies.set('customer:email', signUpInfo.email, {
    expires: expireDate,
    secure: true,
    sameSite: 'none'
  })
  return async function () {
    return CustomersService.initResetPassword(signUpInfo)
  }
}

export function finishResetPassword (resetPasswordData: any) {
  return async function () {
    return CustomersService.finishResetPassword(resetPasswordData)
  }
}

export function fetchCustomerData () {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()
    let customerData = state.account.profile.profile
    if (!customerData) {
      customerData = await CustomersService.getUserData(null)
    }

    dispatch({
      type: ACCOUNT_PROFILE_USER_DATA_FETCHED,
      payload: customerData
    })
  }
}

export function setCustomerNotifications (notifications: INotificationsSettings) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const data = {
      on_order_completed: notifications.onOrderDelivered,
      on_order_delivery: notifications.onOrderDelivery,
      on_order_collecting: notifications.onOrderPicking,
      on_order_created: notifications.onOrderReceive
    }
    await CustomersService.setCustomerNotifications(data)
    dispatch({
      type: ACCOUNT_PROFILE_USER_NOTIFICATIONS_DATA_FETCHED,
      payload: data
    })
  }
}

export function clearCustomerData () {
  return {
    type: ACCOUNT_PROFILE_USER_DATA_CLEAR
  }
}

export function signOut () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await CustomersService.signOut()
    dispatch({
      type: ACCOUNT_PROFILE_SIGN_OUT
    })
    dispatch({
      type: CLEAR_ORDER_DATA
    })
  }
}

export function deleteAccount() {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await CustomersService.deleteAccount()
    dispatch({
       type: ACCOUNT_PROFILE_DELETE
    })
  }
}

export function saveProfileData () {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()
    const selector = formValueSelector('ProfileForm')

    const userData: CustomerDataChanges = {
      ...selector(state, 'first_name') && { first_name: selector(state, 'first_name') },
      ...selector(state, 'last_name') && { last_name: selector(state, 'last_name') },
      ...selector(state, 'phone') && { phone: selector(state, 'phone') },
      ...selector(state, 'email') && { email: selector(state, 'email') },
      ...selector(state, 'default_address_id') && { default_address_id: selector(state, 'default_address_id') },
      ...selector(state, 'image_id') && { image_id: selector(state, 'image_id') },
      ...selector(state, 'company_name') && { company_name: selector(state, 'company_name') }
    }

    dispatch({
      type: ACCOUNT_PROFILE_USER_DATA_UPDATE,
      payload: userData
    })

    await CustomersService.updateUserData(userData)
  }
}

export function saveProfilePhone () {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()
    const selector = formValueSelector('ProfileForm')

    const userData: CustomerDataChanges = {
      ...selector(state, 'phone') && { phone: selector(state, 'phone') }
    }

    try {
      await CustomersService.updateUserPhone(userData)
      dispatch({
        type: ACCOUNT_PROFILE_USER_PHONE_UPDATE,
        payload: true
      })
    } catch (e) {
      dispatch({
        type: ACCOUNT_PROFILE_USER_PHONE_UPDATE,
        payload: false
      })
      throw new Error(e.message)
    }
  }
}

export function verifyPhoneSmsCode (code: string) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    let isVerify = false
    try {
      await CustomersService.verifyPhoneSmsCode(code)
      dispatch({
        type: ACCOUNT_PROFILE_USER_PHONE_UPDATE,
        payload: false
      })
      isVerify = true
    } catch {
      isVerify = false
    }
    return isVerify
  }
}

export function saveOrderProfileData () {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()
    const selector = formValueSelector('orderForm')
    const userData: CustomerDataChanges = {
      ...selector(state, 'firstName') && { first_name: selector(state, 'firstName') },
      ...selector(state, 'lastName') && { last_name: selector(state, 'lastName') },
      ...selector(state, 'phone') && { phone: selector(state, 'phone') },
      ...selector(state, 'email') && { email: selector(state, 'email') }
    }

    dispatch({
      type: ACCOUNT_PROFILE_USER_DATA_UPDATE,
      payload: userData
    })

    await CustomersService.updateUserData(userData)
  }
}

export function updateProfileData (customerData: CustomerDataChanges) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    if (Object.keys(customerData).length === 0) {
      return
    }

    await CustomersService.updateUserData(customerData)
    dispatch({
      type: ACCOUNT_PROFILE_USER_DATA_UPDATE,
      payload: customerData
    })
  }
}

export function getBraintreeToken () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const tokenRes = await CustomersService.getBraintreeToken()

    dispatch({
      type: ACCOUNT_PROFILE_BRAINTREE_TOKEN_FETCHED,
      payload: tokenRes.braintree_token
    })
  }
}

export function clearBraintreeToken () {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: ACCOUNT_PROFILE_BRAINTREE_TOKEN_CLEAR,
      payload: null
    })
  }
}

export function showStandaloneLoginForm () {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: ACCOUNT_PROFILE_TOGGLE_STANDALONE_LOGIN,
      payload: true
    })
  }
}

export function hideStandaloneLoginForm () {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: ACCOUNT_PROFILE_TOGGLE_STANDALONE_LOGIN,
      payload: false
    })
  }
}

export function saveLocation (address: AddressData) {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()
    const isCustomer = state.account.profile.userType === UserType.CustomerUserType

    if (isCustomer) {
      await OrderService.addAddress({
        title: 'Default address',
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        state: address.state,
        zip: address.zip,
        notes: ''
      })
    } else {
      await GuestService.addAddress({
        title: 'Default address',
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        state: address.state,
        zip: address.zip,
        notes: ''
      })
    }

    dispatch({
      type: ACCOUNT_PROFILE_USER_ADDRESS_SAVED,
      payload: address
    })
  }
}
