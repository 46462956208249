import { ApiService } from './ApiService'
import { CustomersService } from './CustomersService'
import { AddressData, OrderData, ICoupon } from '../store/order/types'
import formatPhpDateString from '../utils/formatPhpDateString'

class OrderServiceClass {
  constructor (
    private apiService: typeof ApiService,
    private customersService: typeof CustomersService
  ) { }

  async getAddresses () {
    if (this.customersService.customerId) {
      return this.apiService.get(`/customers/${this.customersService.customerId}/addresses`)
    }
    return null
  }

  async addAddress ({
    title,
    line1,
    line2,
    city,
    state,
    zip,
    notes
  }: AddressData) {
    return this.apiService.post(`/customers/${this.customersService.customerId}/addresses`, {
      title,
      label: title,
      line1: line1,
      line2: line2,
      zip: zip,
      city: city,
      state: state,
      country: 'N/A',
      notes
    })
  }

  async editAddress ({
    title,
    line1,
    line2,
    city,
    state,
    zip,
    notes,
    id
  }: AddressData) {
    return this.apiService.put(`/customers/${this.customersService.customerId}/addresses/${id}`, {
      title,
      label: title,
      line1: line1,
      line2: line2,
      zip: zip,
      city: city,
      state: state,
      country: 'N/A',
      notes
    })
  }

  async getCoupon (code: string): Promise<ICoupon | null> {
    return this.apiService.get(`/sales/coupons/couponWithCode/${code}`)
  }

  async getCouponById (id: string): Promise<ICoupon | null> {
    return this.apiService.get(`/sales/coupons/${id}`)
  }

  async checkCouponAvailability () {
    return this.apiService.post('/customers/checkCouponAvailability')
  }

  applyCoupon ({ id, storeId }: ICoupon & { storeId: number }) {
    return this.apiService.post(`/v2/customers/${this.customersService.customerId}/cart/coupons`, {
      coupon_id: id,
      store_id: storeId
    })
  }

  deleteCoupon ({ id, storeId }: ICoupon & { storeId: number }) {
    return this.apiService.delete(`/v2/customers/${this.customersService.customerId}/cart/coupons`, {
      coupon_id: id,
      store_id: storeId
    })
  }

  async reorder(orderId: string) {
    return this.apiService.post(`/orders/${orderId}/reorder`)
  }

  async makeOrder (data: OrderData) {
    function getNextWorkDay () {
      const d = new Date()
      const day = d.getDay()
      let add = 1
      if (day === 5) add = 3
      else if (day === 6) add = 2
      d.setDate(d.getDate() + add)
      return formatPhpDateString(d)
    }

    return this.apiService.post('/orders/public', {
      nonce: data.nonce,
      driver_instructions: data.driverInstructions,
      delivery_schedule: {
        // TODO temporary fix for https://trello.com/c/kjxVvhRv/77-not-able-to-create-an-order-as-customer-422-error-code
        base_date: getNextWorkDay(), // data.deliverySchedule,
        recurring_type: 0, // data.recurringType,
        delivery_type: data.storeId === 1 ? 'internal' : 'asap'
      },
      address_id: data.addressId,
      ...data.storeId && { store_id: data.storeId }
    })
  }
}

export const OrderService = new OrderServiceClass(ApiService, CustomersService)
