import { IStoreState, STORE_FETCHED, STORE_DEPARTMENTS_FETCHED, StoreActions, STORE_DEPARTMENT_FETCHED, STORE_FEATURED_FETCHED, STORE_NEW_ARRIVALS_FETCHED, STORE_DEPARTMENT_PRODUCTS_FETCHED, STORE_CLEAR_STATE, STORE_DEPARTMENT_CLEAR_STATE, STORE_DEPARTMENT_PRODUCTS_FETCHED_MORE } from './types'

const initialState: IStoreState = {
  store: null,
  products: [],
  arrivals: [],
  departments: [],
  department: null,
  departmentProducts: [],
  pagingHeader: null
}

export default function reduce (state = initialState, action: StoreActions) {
  switch (action.type) {
    case STORE_FETCHED:
      return { ...state, store: action.payload }
    case STORE_DEPARTMENTS_FETCHED:
      return { ...state, departments: action.payload }
    case STORE_DEPARTMENT_FETCHED:
      return { ...state, department: action.payload }
    case STORE_DEPARTMENT_PRODUCTS_FETCHED:
      return { ...state, departmentProducts: action.payload.list, pagingHeader: action.payload.paging_header }
    case STORE_DEPARTMENT_PRODUCTS_FETCHED_MORE:
      return { ...state, departmentProducts: [...state.departmentProducts, ...action.payload.list], pagingHeader: action.payload.paging_header }
    case STORE_FEATURED_FETCHED:
      return { ...state, products: action.payload }
    case STORE_NEW_ARRIVALS_FETCHED:
      return { ...state, arrivals: action.payload }
    case STORE_CLEAR_STATE:
      return { ...state, store: null, products: [], arrivals: [], departments: [] }
    case STORE_DEPARTMENT_CLEAR_STATE:
      return { ...state, store: null, department: null, departmentProducts: [] }
    default:
      return state
  }
}
