import React, { useState } from 'react'

export interface WithCallbacksProps {
  callbacks: {
    [key: string]: () => void
  },
  pushCallback: () => void
}

export function withCallbacks<T = {}> (Component: React.ComponentType<T>) {
  return (props: any) => {
    const [callbacks, setCallbacks] = useState({})
    const pushCallback = (data: {
      [key: string]: () => never
    }) => {
      const currentCallbacks = callbacks
      Object.assign(currentCallbacks, data)
      setCallbacks(currentCallbacks)
    }

    return <Component
      {...props}
      callbacks={callbacks}
      pushCallback={pushCallback} />
  }
}
