import debounce from 'lodash.debounce'
import React, { KeyboardEvent, useEffect } from 'react'
import { connect } from 'react-redux'

import { preSearch, preSearchVisible, preSearchClear } from '../../../../store/search/action-creators'
import PreSearch from './PreSearch'

import './search.scss'

interface IMobileSearch {
  showSearch: (searchString: string) => void
  searchString: string
  changeSearchString: (value: string) => void
  preSearch: (searchString: string) => void
  preSearchVisible: (isVisible: boolean) => void
  preSearchClear: () => void
}

const MobileSearchInput: React.FunctionComponent<IMobileSearch> = (props) => {
  const onSearchKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      const el = e.target as HTMLInputElement
      if (el.value && el.value.length > 2) {
        props.showSearch(el.value)
        props.preSearchVisible(false)
      }
    }
  }

  const onSearch = () => {
    if (props.searchString.length > 2) {
      props.showSearch(props.searchString)
      props.preSearchVisible(false)
    }
  }

  const debouncedSearch = React.useRef(debounce(async (searchString) => {
    if (searchString.trim().length > 2) {
      await props.preSearch(searchString)
    }
  }, 500)).current

  useEffect(() => {
    if (!props.searchString.trim().length) {
      props.preSearchClear()
    }
  }, [props.searchString])

  return (
    <div className="header__search--input-wrapper">
      <input className="mobile-search form__input form__input--search"
        type="text"
        name="tags"
        onChange={(e) => {
          props.changeSearchString(e.target.value)
          debouncedSearch(e.target.value)
        }}
        onFocus={() => props.searchString ? props.preSearchVisible(true) : null}
        onBlur={() => {
          setTimeout(() => props.preSearchVisible(false), 200)
        }}
        placeholder="What would you like to find?"
        onKeyPress={onSearchKeyPress}
        value={props.searchString}
      />
      <div className="icon" onClick={onSearch}></div>
      <PreSearch onSearch={onSearch}/>
    </div>
  )
}

export default connect(null, {
  preSearch,
  preSearchVisible,
  preSearchClear
})(MobileSearchInput)
