import React, { SyntheticEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { NavLink } from 'react-router-dom'

import '../catalog/catalog.scss'
import '../header/header.scss'
import { ReduxState } from '../../store/reducers'
import { Catalog, ICatalogFetchProps } from '../../store/catalog/types'
import Pagination from '../pagination/Pagination'
import { fetchPreviouslyOrderedPage, fetchMorePreviouslyOrderedItems } from '../../store/catalog/action-creators'
import { UserType } from '../../store/account/profile/types'
import CatalogItem from '../../components/catalog-item/CatalogItem'

interface CatalogContainerProps {
  catalog: Catalog;
  fetchPreviouslyOrderedPage: (params: ICatalogFetchProps) => void;
  fetchMorePreviouslyOrderedItems: (params: ICatalogFetchProps) => void;
}

const CatalogContainer: React.FC<CatalogContainerProps & RouteComponentProps> = (props) => {
  const [currentPage, setPage] = useState(1)
  const [itemsPerPage] = useState(20)

  useEffect(() => {
    document.documentElement.scrollTop = 0
    props.fetchPreviouslyOrderedPage({
      page: currentPage,
      items: itemsPerPage
    })
  }, [])

  function loadMore (e: SyntheticEvent): void {
    e.preventDefault()
    const page = currentPage + 1
    setPage(page)
    props.fetchMorePreviouslyOrderedItems({
      page,
      items: itemsPerPage
    })
  }

  function loadPage (e: SyntheticEvent, page: number) {
    document.documentElement.scrollTop = 0
    e.preventDefault()
    setPage(page)
    props.fetchPreviouslyOrderedPage({
      page,
      items: itemsPerPage
    })
  }

  return (
    <>
      <section className="content__catalog catalog__view">
        <div className="breadcrumbs">
          <NavLink to="/" className="breadcrumbs__item breadcrumbs__item--link">Main</NavLink>
          <div className="breadcrumbs__divider"></div>
          <div className="breadcrumbs__item">Previously ordered</div>
        </div>
        <h2>Previously ordered</h2>
        <div className="catalog">
          { props.catalog.catalogItems.map(CatalogItem) }
          { props.catalog.totalItems > props.catalog.currentPage * itemsPerPage
            ? <a className="content__load-more" href="#" onClick={loadMore}><p>Load More</p></a>
            : null
          }
        </div>
        <Pagination totalPages={props.catalog.totalPages} currentPage={props.catalog.currentPage} loadPage={loadPage} />
      </section>
    </>
  )
}

const mapStateToProps = function (state: ReduxState) {
  const items = [...state.catalog.catalogItems]
  const favorites = state.favorites.favorites
  const countInCard = state.cart.items
  for (let i = 0; i < items.length; i++) {
    items[i].isFavorites = false
    items[i].cardCount = 0
    items[i].isCustomer = state.account.profile.userType === UserType.CustomerUserType

    for (let c = 0; c < countInCard.length; c++) {
      if (items[i].id === countInCard[c].product.id) {
        items[i].cardCount = countInCard[c].count
      }
    }

    for (let j = 0; j < favorites.length; j++) {
      if (items[i].id === favorites[j].id) {
        items[i].isFavorites = true
      }
    }
  }
  return {
    catalog: state.catalog,
    departments: state.departments.departmentItems,
    favorites: state.favorites.favorites,
    cart: state.cart.items
  }
}
export default connect(
  mapStateToProps,
  { fetchPreviouslyOrderedPage, fetchMorePreviouslyOrderedItems }
)(CatalogContainer)
