import React, { useCallback, useEffect } from 'react'
import { change, Field, FormErrors, InjectedFormProps, reduxForm } from 'redux-form'
import debounce from 'lodash.debounce'
import { CustomInput } from '../order/CustomInput'
import { SAVE_DEBOUNCE_TIMEOUT } from '../../constants'
import { connect } from 'react-redux'
import { ReduxState } from '../../store/reducers'
import { fetchCustomerData, saveOrderProfileData } from '../../store/account/profile/action-creators'
import { CustomerInfo, UserType } from '../../store/account/profile/types'
import { withCallbacks, WithCallbacksProps } from '../../utils/withCallbacks'
import { ICart } from '../../store/cart/types'

interface ICartCustomerInfoProps {
  fetchCustomerData: () => void
  saveOrderProfileData: () => void
  profile: CustomerInfo | null
  isCurrentUserCustomer: boolean
  carts: ICart[]
}

interface CartCustomerFormData {
  firstName: string
  companyName?: string
  lastName: string
  phone: string
  email: string
  address: number
  driverInstructions: string
  date: string
}

type CustomerCartFormWithCallbacksProps = ICartCustomerInfoProps & WithCallbacksProps
type CustomerCartFormComponentProps = CustomerCartFormWithCallbacksProps & InjectedFormProps<CartCustomerFormData, CustomerCartFormWithCallbacksProps>

const CartCustomerInfo: React.FunctionComponent<CustomerCartFormComponentProps & WithCallbacksProps> = (props) => {
  const onFieldChanged = useCallback(debounce(props.saveOrderProfileData, SAVE_DEBOUNCE_TIMEOUT), [])

  useEffect(() => {
    if (props.isCurrentUserCustomer) {
      props.fetchCustomerData()
    }
  }, [])

  useEffect(() => {
    if (props.initialValues) {
      props.change('firstName', props.initialValues.firstName)
      props.change('lastName', props.initialValues.lastName)
      props.change('phone', props.initialValues.phone)
      props.change('email', props.initialValues.email)
      props.change('companyName', props.initialValues.companyName)
    }
  }, [props.carts])

  return <form className="form-type-2 relative">
    {!props.isCurrentUserCustomer && <>
      <div className="dimmer" title="Please login to proceed to checkout"></div>
      <div className="dimmer">Please sign in to proceed to checkout</div>
    </>}
    <h3 className="multi-cart-title">Customer info</h3>
    <div className="row">
      <Field
        name="firstName"
        labelText="First Name"
        component={CustomInput}
        onChange={onFieldChanged}
        placeholder="Please enter your first name"
        required
      />
      <Field
        name="lastName"
        labelText="Last Name"
        component={CustomInput}
        onChange={onFieldChanged}
        placeholder="Please enter your last name"
        required
      />
    </div>
    <div className="row">
      <Field
        name="phone"
        labelText="Phone number"
        component={CustomInput}
        onChange={onFieldChanged}
        placeholder="Please enter your phone number" />
      <Field
        name="email"
        labelText="Email"
        component={CustomInput}
        onChange={onFieldChanged}
        placeholder="Please enter your email" />
    </div>
    {
      props.profile && props.profile.business_account &&
      <div className="row">
        <Field name="companyName"
          labelText="Company name (optional)"
          component={CustomInput}
          placeholder="Company name"
        />
      </div>
    }
  </form>
}

const WrappedCartCustomerForm = reduxForm<CartCustomerFormData, CustomerCartFormWithCallbacksProps>({
  form: 'orderForm',
  validate: ({
    firstName,
    lastName,
    phone,
    email,
    address
  }: CartCustomerFormData) => {
    const errors: FormErrors<CartCustomerFormData> = {}
    if (!firstName) errors.firstName = 'First Name is required'
    if (!lastName) errors.lastName = 'Last Name is required'
    if (!/^(\d|\+|\(|\)|-| )+$/.test(phone)) errors.phone = 'Phone Number is required'
    if (!/@/.test(email)) errors.email = 'Email is required'
    if (!address) errors.address = 'Address is required'

    return errors
  },
  onSubmitFail: function (errors, __, ___, props) {
    if (!errors) return
    const [firstErrorKey] = Object.keys(errors)
    const firstErrorFieldCallback: () => void = props.callbacks[firstErrorKey]
    firstErrorFieldCallback && firstErrorFieldCallback()
  }
})(CartCustomerInfo)

const WithCallbacks = withCallbacks(WrappedCartCustomerForm)

const ConnectedWithProps = connect(
  (state: ReduxState) => {
    const { profile = null } = state.account.profile || {}

    return {
      ...profile && {
        initialValues: {
          firstName: profile.first_name,
          lastName: profile.last_name,
          email: profile.email,
          phone: profile.phone,
          address: profile.default_address_id,
          companyName: profile.company_name
        }
      },
      profile: state.account.profile.profile,
      isCurrentUserCustomer: state.account.profile.userType === UserType.CustomerUserType
    }
  },
  { fetchCustomerData, saveOrderProfileData, change }
)(WithCallbacks)

export default ConnectedWithProps
