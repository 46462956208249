export const DEPARTMENTS_FETCHED = 'DEPARTMENTS_FETCHED'
interface DepartmentsFetched {
  type: typeof DEPARTMENTS_FETCHED
  payload: IDepartments[]
}

export type DepartmentsActions = DepartmentsFetched

export interface IDepartmentImage {
  id?: string;
  encoded_filename: string;
  created_at: string;
  url: string;
}

export interface ICategory {
  id: number;
  title: string;
  departmentId: number;
  image?: IDepartmentImage;
  default?: boolean;
}

export interface IDepartment {
  id: number;
  title: string;
  image?: IDepartmentImage;
  categories: ICategory[];
  promoted_products: [];
}

export interface IDepartments {
  departmentItems: IDepartment[];
  categories: ICategory[];
  totalPages: number;
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
}
