import { DashboardService } from '../../services/DashboardService'
import { ThunkDispatch } from 'redux-thunk'
import {
  GET_DASHBOARD_DATA,
  CLEAR_DASHBOARD_DATA,
  GET_BONUSES_DATA,
  IBonuses
} from './types'
import { BonusService } from '../../services/BonusService'

export function getDashboardData () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const data = await DashboardService.getData()
    dispatch({
      type: GET_DASHBOARD_DATA,
      payload: data
    })
  }
}

export function getBonuses () {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    const bonuses = await BonusService.get()
    dispatch({
      type: GET_BONUSES_DATA,
      payload: bonuses as IBonuses
    })
  }
}

export function clearDashboardData () {
  return {
    type: CLEAR_DASHBOARD_DATA
  }
}
