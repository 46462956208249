import axios from 'axios'
import Cookies from 'js-cookie'
import { HttpErrorService, HttpErrorServiceClass } from './HttpErrorService'

const CINCH_API = process.env.REACT_APP_API_URL as string
const API_TYPE = window.location.host.split('.').reverse().join('.')

export class ApiServiceClass {
  tokenChecked: boolean
  apiUrl: string
  _isTokenInProgress: boolean
  _tokenRequestsQueue: []
  authHeader: string | null
  httpErrorService: HttpErrorServiceClass

  constructor (httpService: HttpErrorServiceClass = HttpErrorService) {
    this.apiUrl = CINCH_API
    this._isTokenInProgress = false
    this.tokenChecked = false
    this._tokenRequestsQueue = []
    this.authHeader = null
    this.httpErrorService = httpService
  }

  async checkToken (token: string) {
    if (this.tokenChecked) {
      return token
    }

    try {
      await axios.post(`${this.apiUrl}/catalog/products/public/list/1/1`, null, { headers: { Authorization: token } })
      this.tokenChecked = true
      return token
    } catch (e) {
      Cookies.remove('guest:id')
      Cookies.remove('guest:token')
      const res = await axios.post(`${this.apiUrl}/guests`)
      return `Bearer ${res.data.api_token}`
    }
  }

  async getAuthHeader () {
    let authHeader: string | null = null
    const customerToken = Cookies.get('customer:token')
    if (customerToken) {
      authHeader = `Bearer ${customerToken}`
    } else {
      if (this._isTokenInProgress) {
        const promise = new Promise((resolve) => {
          this._tokenRequestsQueue.push(resolve)
        })
        return promise
      }
      this._isTokenInProgress = true
      const guestToken = Cookies.get('guest:token')
      if (guestToken) {
        authHeader = `Bearer ${guestToken}`
        authHeader = await this.checkToken(authHeader)
      } else {
        const res = await axios.post(`${this.apiUrl}/guests`)
        authHeader = `Bearer ${res.data.api_token}`

        Cookies.set('guest:id', res.data.id, {
          expires: 365,
          secure: true,
          sameSite: 'none'
        })
        Cookies.set('guest:token', res.data.api_token, {
          expires: 365,
          secure: true,
          sameSite: 'none'
        })
      }
      this._isTokenInProgress = false
      setTimeout(() => {
        while (this._tokenRequestsQueue.length > 0) {
          const resolve = this._tokenRequestsQueue.pop()
          resolve(authHeader)
        }
      }, 0)
    }

    return authHeader
  }

  async request ({ method, url, data, opts = {} }: any): Promise<any> {
    const arg = [
      this.apiUrl + url
    ]

    if (method !== 'get') {
      arg.push(data)
    }

    if (!opts.headers) {
      opts.headers = {}
    }
    opts.headers.Authorization = await this.getAuthHeader()
    opts.headers['CINCH-API-VERSION'] = 'v2'
    opts.headers['CINCH-APP-TYPE'] = API_TYPE

    arg.push(opts)

    if (method === 'delete') {
      const options = arg.pop() as any
      if (options) {
        options.data = arg.pop()
        arg.push(options)
      }
    }
    try {
      const response = await axios[method](...arg)
      return response.data
    } catch (e) {
      this.httpErrorService.handleResponse(e)
      if (e && e.response && e.response.data) {
        throw e.response.data
      } else {
        throw e
      }
    }
  }

  async uploadFile<T = any> (url: string, formData: FormData): Promise<T> {
    const fullUrl = this.apiUrl + url
    const authHeader = await this.getAuthHeader()
    try {
      const response = await axios.post(fullUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: authHeader
        }
      })
      return response.data
    } catch (error) {
      this.httpErrorService.handleResponse(error)
      throw error.response
        ? (error.response.status === 413 ? error.response : error.response.data)
        : error
    }
  }

  get (url: string, opts?: any) {
    return this.request({ method: 'get', url, opts })
  }

  post (url: string, data?: any, opts?: any) {
    return this.request({ method: 'post', url, data, opts })
  }

  put (url: string, data?: any, opts?: any) {
    return this.request({ method: 'put', url, data, opts })
  }

  delete (url: string, data?: any, opts?: any) {
    return this.request({ method: 'delete', url, data, opts })
  }
}

export const ApiService = new ApiServiceClass(HttpErrorService)
