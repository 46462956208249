export const GET_ORDERS = 'GET_ORDERS'

interface GetOrdersAction {
  type: typeof GET_ORDERS,
  payload: IOrders
}

export const CLEAR_ORDERS = 'CLEAR_ORDERS'

interface ClearOrdersAction {
  type: typeof CLEAR_ORDERS
}

export type IOrders = {
  loading: boolean,
  list: {}[],
  paging_header: {
    current_page: number
    total_items: number
    total_pages: number
  } | null
}

export type OrdersActions = GetOrdersAction | ClearOrdersAction
