import React, { SyntheticEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { decodeHtmlSymbols } from '../../utils/DecodeHtmlSymbols'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import noPhoto from '../../assets/images/no-photo.svg'
import Loader from '../loader/Loader'
import priceToString from '../../utils/PriceToString'
import { ICoupon } from '../../store/order/types'
import { CartBenefits, ICart } from '../../store/cart/types'
import OrderForm from '../order/OrderForm'
import { connect } from 'react-redux'
import { decrementCartItem, incrementCartItem } from '../../store/cart/actions'
import getAddressString from '../../utils/GetAddressString'
import { UserType } from '../../store/account/profile/types'
import { ReduxState } from '../../store/reducers'
import trimLongText from "../../utils/trimLongText";

function getGiftsFromCoupon (coupons: ICoupon[], close: () => void) {
  if (coupons && coupons.length !== 0 && coupons[0].gift_products) {
    return coupons[0].gift_products.map((item, index) => {
      return item.product
        ? <div key={index} className="cart__item cart__item--shadow">
          <div className="cart__item-images">
            <Link to={`/catalog/${item.product.id}`} className="cart__item-image" title={decodeHtmlSymbols(item.product.name)} onClick={close}>
              <LazyLoadImage src={item.product.images && item.product.images[0] ? item.product.images[0].url : noPhoto} alt={item.product.name} />
            </Link>
          </div>
          <div className="cart__item-properties">
            <h3 className="cart__item-title">{decodeHtmlSymbols(item.product.name)}</h3>
            <p className="cart__item-description">{item.product.description}</p>
            <div className="cart__item-prices">
              <label className="form__label form__label--big form__label--centered area-total">
                Free: <span className="strike">{priceToString(item.product.real_sale_price * item.count)}</span>
              </label>
            </div>
          </div>
          <span className="cart__item-discount">
            <span className="cart__item-discount-text">Free<br />Gift!</span>
          </span>
        </div>
        : null
    }).filter(Boolean)
  } else {
    return null
  }
}

function getGiftProducts (benefits: CartBenefits | undefined, close: () => void) {
  if (benefits && benefits.discounts && benefits.discounts.length !== 0) {
    return <div className="cart__discount-container">
      {
        benefits.discounts.map((item, index) => {
          return item.gift
            ? <div key={index} className="cart__item cart__item--shadow">
              <div className="cart__item-images">
                <Link to={`/catalog/${item.gift.product.id}`} className="cart__item-image" title={decodeHtmlSymbols(item.gift.product.name)} onClick={close}>
                  <LazyLoadImage src={item.gift.product.images && item.gift.product.images[0] ? item.gift.product.images[0].url : noPhoto} alt={item.gift.product.name} />
                </Link>
              </div>
              <div className="cart__item-properties">
                <h3 className="cart__item-title">{decodeHtmlSymbols(item.gift.product.name)}</h3>
                <p className="cart__item-description">{item.gift.product.description}</p>
                <div className="cart__item-price">
                  Gift
                </div>
                <div>x{item.gift.count}</div>
              </div>
            </div>
            : null
        }).filter(Boolean)
      }
    </div>
  } else {
    return null
  }
}

export interface IMultiCartItemProps extends ICart {
  index: number
  loading?: boolean
  close: () => void
  incrementCartItem: ({ id, storeId }: { id: string, count?: number, storeId?: number }) => Promise<void>
  decrementCartItem: ({ id, storeId }: { id: string, count?: number, storeId?: number }) => Promise<void>
  isCurrentUserCustomer: boolean
}

const MultiCartItemComponent = (props: IMultiCartItemProps) => {
  const [cartLoading, setCartLoading] = useState(false)

  return (
    <>
      { cartLoading ? <div className="form__counter-cart-loader"><Loader className="loader product-counter " color='blue' /></div> : null }
      <div className="multi-cart__header">
        <div className="multi-cart__header-number">Order #{props.index + 1}</div>
        <div className="multi-cart__header-store">{props.cartStore ? decodeHtmlSymbols(props.cartStore.name) : 'Cinch'}</div>
        <div className="multi-cart__header-address">{props.cartStore ? getAddressString(props.cartStore.address) : ''}</div>
      </div>
      <div className="multi-cart__body">
        <div className="multi-cart__products">
          {
            props.items.map((item, index) => {
              const increment = async () => {
                setCartLoading(true)
                await props.incrementCartItem({
                  id: item.product.id,
                  ...props.cartStore && { storeId: props.cartStore.id }
                })
                setCartLoading(false)
              }
              const decrement = async () => {
                if (item.count === 1) return
                setCartLoading(true)
                await props.decrementCartItem({
                  id: item.product.id,
                  ...props.cartStore && { storeId: props.cartStore.id }
                })
                setCartLoading(false)
              }
              const drop = async () => {
                setCartLoading(true)
                await props.decrementCartItem({
                  id: item.product.id,
                  count: item.count,
                  ...props.cartStore && { storeId: props.cartStore.id }
                })
                setCartLoading(false)
              }
              return (
                <div key={index} className="cart__item">
                  <div className="cart__item-images">
                    <Link to={`/catalog/${item.product.id}`} className="cart__item-image" title={decodeHtmlSymbols(item.product.name)} onClick={props.close}>
                      <LazyLoadImage src={item.product.images && item.product.images[0] ? item.product.images[0].url : noPhoto} alt={item.product.name} />
                    </Link>
                  </div>
                  <div className="cart__item-properties">
                    <div className="cart__item-title">{decodeHtmlSymbols(item.product.name)}</div>
                    <div className="cart__item-description">{trimLongText(item.product.description, 20)}</div>
                    <div className="cart__item-price">
                      {priceToString(item.product.real_sale_price)}
                    </div>
                    {
                      item.product.real_sale_price !== item.product.sale_price && <div className="cart__item-discount-price">
                        {priceToString(item.product.sale_price)}
                      </div>
                    }
                    <div className="row">
                      <div className="form__counter">
                        <span className="form__counter-decrement" onClick={decrement}></span>
                        <input className="form__input form__input--centered form__input--big" type="text" readOnly={true} disabled={true} value={item.count} />
                        <span className="form__counter-increment" onClick={increment}></span>
                      </div>
                      <label className="cart__item-total">
                        Total: {priceToString(item.product.real_sale_price * item.count)}
                      </label>
                    </div>
                  </div>
                  <div className="cart__item-remove" onClick={drop}></div>
                </div>
              )
            })
          }
          {
            getGiftProducts(props.benefits, props.close)
          }
          {
            getGiftsFromCoupon(props.coupons, props.close)
          }
        </div>
        <div className="multi-cart__order">
          {!props.isCurrentUserCustomer && <div className="dimmer" title="Please login to proceed to checkout"></div>}
          <OrderForm close={props.close} cartStore={props.cartStore} isCartModal={true}/>
        </div>
      </div>
    </>
  )
}

export const MultiCartItem = connect((state: ReduxState) => {
  return {
    isCurrentUserCustomer: state.account.profile.userType === UserType.CustomerUserType
  }
}, {
  incrementCartItem, decrementCartItem
})(MultiCartItemComponent)
