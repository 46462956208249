import React from 'react'

export function withProps<T = {}, K = {
  [prop: string]: (props: T) => any
}> (propsResolvers: K) {
  return (Component: React.ComponentType<T>) => {
    return (props: T) => {
      const resolvedProps = Object.keys(propsResolvers).reduce((resolvedProps, resolverKey) => {
        return {
          ...resolvedProps,
          [resolverKey]: propsResolvers[resolverKey](props)
        }
      }, {})
      return <Component
        {...props}
        {...resolvedProps} />
    }
  }
}
