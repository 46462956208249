import { AnyAction } from 'redux'

export interface GuestState {
  token: string | null
}

const initialState = {
  topicsByUrl: {},
  selectedTopicUrls: []
}

export default function reduce (state = initialState, action: AnyAction) {
  switch (action.type) {
    default:
      return state
  }
}
