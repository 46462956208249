import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './information.scss'
import { RouteComponentProps } from 'react-router'
import deliveryInfoImg from '../../assets/images/delivery-info.png'
import privacyPolicy from '../../assets/images/privacy-policy.png'
import termsAndCons from '../../assets/images/terms-and-conditions.png'
import InformationService from '../../services/InformationService'

interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
}

interface InformationProps {
}

export enum InformationRoutes {
  privacyPolicy = '/privacy-policy',
  deliveryInfo = '/delivery-information',
  termsAndCons = '/terms-and-conditions',
  aboutUs = '/about-us'
}

const InformationComponent: React.FunctionComponent<InformationProps & MatchProps> = props => {
  const [aboutUs, setAboutUs] = useState('')

  function getImage (): string {
    switch (props.match.path) {
      case InformationRoutes.termsAndCons:
        return termsAndCons
      case InformationRoutes.privacyPolicy:
        return privacyPolicy
      case InformationRoutes.deliveryInfo:
        return deliveryInfoImg
      case InformationRoutes.aboutUs:
        return aboutUs
    }
    return ''
  }
  const [image, setImage] = useState('')
  useEffect(() => {
    setImage(getImage())

    import('../../assets/images/cinch-logo.svg').then(module => {
      setAboutUs(module.default)
    })
  })

  function getHeader (): string {
    switch (props.match.path) {
      case InformationRoutes.termsAndCons:
        return 'Terms & Conditions'
      case InformationRoutes.privacyPolicy:
        return 'Privacy Policy'
      case InformationRoutes.deliveryInfo:
        return 'Delivery Information'
      case InformationRoutes.aboutUs:
        return 'About Us'
    }
    return ''
  }
  const [title, setTitle] = useState('')
  useEffect(() => {
    setTitle(getHeader())
  })

  const [informationText, setInformationText] = useState('')
  function getInformationText (): void {
    switch (props.match.path) {
      case InformationRoutes.termsAndCons:
        InformationService.getTermsAndConditions().then(({ text = '' } = {}) => {
          setInformationText(text)
        })
        break
      case InformationRoutes.privacyPolicy:
        InformationService.getPrivacyPolicy().then(({ text = '' } = {}) => {
          setInformationText(text)
        })
        break
      case InformationRoutes.deliveryInfo:
        InformationService.getDeliveryInformation().then(({ text = '' } = {}) => {
          setInformationText(text)
        })
        break
      case InformationRoutes.aboutUs:
        InformationService.getAboutUs().then(({ text = '' } = {}) => {
          setInformationText(text)
        })
        break
    }
  }
  useEffect(() => {
    setInformationText('Loading...')
    getInformationText()
  }, [props.match.path])

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <section className="information">
      <div className="breadcrumbs">
        <NavLink to="/" className="breadcrumbs__item breadcrumbs__item--link">Main</NavLink>
        <div className="breadcrumbs__divider"></div>
        <div className="breadcrumbs__item">{title}</div>
      </div>
      <div className="information-header">
        <img src={image} alt="Information header logo" />
        <div>{title}</div>
      </div>
      <div className="information-content">
        { informationText.split('\n').map((text, i) => (<p key={i}>{text}</p>)) }
      </div>
    </section>
  )
}

export default InformationComponent
