import { IBraintreeResponse } from '../../types/Braintree'

export function initializeBraintree (userToken: string, id: number) {
  return new Promise((resolve, reject) => {
    try {
      // @ts-ignore
      // eslint-disable-next-line
      braintree.dropin.create({
        authorization: userToken,
        container: '#dropin-container' + id,
        paypal: {
          flow: 'vault',
          buttonStyle: {
            color: 'blue',
            shape: 'rect',
            size: 'medium'
          }
        },
        card: {
          cardholderName: {
            required: false
          }
        }
      }, function (error: Error, instance: any) {
        if (error) return reject(error)
        const paymentHandler = () => new Promise((resolve, reject) => {
          instance.requestPaymentMethod((error: Error, payload: IBraintreeResponse) => {
            if (error) return reject(error)
            resolve(payload)
          })
        }) as Promise<IBraintreeResponse>
        resolve(paymentHandler)
      })
    } catch (err) {
      reject(err)
    }
  }) as Promise<() => Promise<IBraintreeResponse>>
}
