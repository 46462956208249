import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form'
import ReactCodeInput from 'react-code-input'
import classnames from 'classnames'
import { Store } from 'react-notifications-component'
import { SignUpFormData } from '../../../../store/account/profile/types'
import { verifySmsCode, resendCode, updateUserPhone } from '../../../../store/account/profile/action-creators'
// import checkMark from '../../../../assets/images/check-mark.svg'
import { ReduxState } from '../../../../store/reducers'

interface ResetPasswordFormProps {
  verifySmsCode: (code: string) => any
  updateUserPhone: (phone: string) => any
  toggle: () => any
  close: (e: SyntheticEvent) => void
  resendCode: (mode: string) => any
  isBusiness?: boolean | null
  backendError?: string
}

const Form: React.FunctionComponent<
  ResetPasswordFormProps &
  InjectedFormProps<SignUpFormData, ResetPasswordFormProps>
  > = ({
    verifySmsCode,
    handleSubmit,
    close,
    submitting,
    error,
    isBusiness,
    resendCode,
    backendError,
    updateUserPhone
  }) => {
    const [code, setCode] = useState('')
    const [loggedIn, setLoggedIn] = useState(false)
    const [apiError, setApiError] = useState('')
    const [noPhoneError, setNoPhoneError] = useState(backendError === 'login_phone_value_empty')
    const [phoneSubmitted, setPhoneSubmitted] = useState(false)
    const [phone, setPhone] = useState('')
    const [resendType, setResendType] = useState(0)

    useEffect(() => {
      if (backendError === 'login_phone_value_empty') {
        setNoPhoneError(true)
      }
    }, [backendError])

    const submit = async () => {
      try {
        if (noPhoneError && !phoneSubmitted) {
          await updateUserPhone(phone)
          setPhoneSubmitted(true)
        } else {
          await verifySmsCode(code)
          setLoggedIn(true)
          close()
          Store.addNotification({
            title: 'Logged in',
            message: isBusiness
              ? <div className="business-text">Thanks for registering your business with Cinch! We offer great benefits to our business customers. If your business has tax exempt status please send your certificate to <a href={
                'mailto:deliveryicart@gmail.com?subject=Business%20resale%20certificate%20or%20tax%20exempt%20form'
              } className="business-text-link" >{
                'deliveryicart@gmail.com'
              }</a> so we remove tax from your orders. <br/><br/> Happy Cinching!</div>
              : 'You have logged in successfully!',
            type: 'success',
            insert: 'top',
            container: 'bottom-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: isBusiness ? 15000 : 5000,
              onScreen: true
            }
          })
        }
      } catch (e: any) {
        const error = e.errors
          ? e.errors.map(({ message }: { message: string }) => message).join('\n')
          : 'Something went wrong, please try again'
        throw new SubmissionError({ _error: error })
      }
    }
    return (
      <>
        <form className={classnames('form-type-2 account-form sign-up', {
          hidden: loggedIn || !noPhoneError
        })}>
          <div className="relative">
            <div className="sms-code__text pad-top">Cinch requires your phone number to ensure the security of your account.</div>
            <Field name="phone"
                   component="input"
                   type="text"
                   placeholder="+1 ___ ___ ____"
                   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                     setPhone(String(e.target.value))
                   }}
                   value={phone}
            />
            <div className={classnames({
              dimmer: noPhoneError && phoneSubmitted
            })}></div>
          </div>
        </form>
        <form onSubmit={handleSubmit(submit)} className={classnames('form-type-2 account-form sign-up', {
          hidden: loggedIn
        })}>
          <div className="relative">
            <div className="sms-code__text">{resendType === 0 ? 'We’ve sent a verification code to your phone.' : 'We’ve sent a verification code to your mailbox.'}<br/>Please enter it to continue.</div>
            <ReactCodeInput
              name="code"
              className="sms-code__code"
              type="text"
              placeholder="Code"
              fields={6}
              value={code}
              inputMode="verbatim"
              onChange={(newCode: string) => {
                setCode(newCode)
              }}
              autoFocus={false}
              disabled={noPhoneError && !phoneSubmitted}
            />
            <div className="sms-code__text">Haven&apos;t received the code? <span className="sms-code__resend" onClick={async () => {
              try {
                setResendType(0)
                await resendCode('sms')
                setApiError('')
              } catch (e: any) {
                if (e.errors && e.errors[0]) {
                  setApiError(e.errors[0].message)
                }
              }
            }}>&nbsp;Resend SMS&nbsp;</span>
          </div>
            {!submitting && (error || apiError) && <span className="error">{error || apiError}</span>}
            <div className={classnames({
              dimmer: noPhoneError && !phoneSubmitted
            })}></div>
          </div>
          <button className="button_blue">
            Continue
          </button>
          <button className="button_grey" onClick={close}>
            Cancel
          </button>
        </form>
      </>
    )
  }

const WrappedForm = reduxForm<SignUpFormData, ResetPasswordFormProps>({
  form: 'ConfirmSmsCodePasswordForm'
})(Form)

const ConnectedWrappedForm = connect(
  (state: ReduxState) => {
    const customer = state.account.profile.verifySmsCodeInformation

    return {
      isBusiness: customer && customer.business_account,
      backendError: customer && customer.error
    }
  },
  { verifySmsCode, resendCode, updateUserPhone }
)(WrappedForm)

export default ConnectedWrappedForm
