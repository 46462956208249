import { IAddress } from '../../customers/types'

export const ACCOUNT_PROFILE_LOGGED_IN = 'ACCOUNT_PROFILE_LOGGED_IN'
export const ACCOUNT_PROFILE_SIGNED_UP = 'ACCOUNT_PROFILE_SIGNED_UP'
export const ACCOUNT_PROFILE_TOGGLE_LOGIN_FORM = 'ACCOUNT_PROFILE_TOGGLE_LOGIN_FORM'
export const ACCOUNT_PROFILE_SET_USER_TYPE = 'ACCOUNT_PROFILE_SET_USER_TYPE'
export const ACCOUNT_PROFILE_USER_DATA_FETCHED = 'ACCOUNT_PROFILE_USER_DATA_FETCHED'
export const ACCOUNT_PROFILE_USER_NOTIFICATIONS_DATA_FETCHED = 'ACCOUNT_PROFILE_USER_NOTIFICATIONS_DATA_FETCHED'
export const ACCOUNT_PROFILE_USER_DATA_CLEAR = 'ACCOUNT_PROFILE_USER_DATA_CLEAR'
export const ACCOUNT_PROFILE_USER_DATA_UPDATE = 'ACCOUNT_PROFILE_USER_DATA_UPDATE'
export const ACCOUNT_PROFILE_USER_PHONE_UPDATE = 'ACCOUNT_PROFILE_USER_PHONE_UPDATE'
export const ACCOUNT_PROFILE_SAVE_PROFILE_DATA = 'ACCOUNT_PROFILE_SAVE_PROFILE_DATA'
export const ACCOUNT_PROFILE_SIGN_OUT = 'ACCOUNT_PROFILE_SIGN_OUT'
export const ACCOUNT_PROFILE_BRAINTREE_TOKEN_FETCHED = 'ACCOUNT_PROFILE_BRAINTREE_TOKEN_FETCHED'
export const ACCOUNT_PROFILE_BRAINTREE_TOKEN_CLEAR = 'ACCOUNT_PROFILE_BRAINTREE_TOKEN_CLEAR'
export const ACCOUNT_PROFILE_TOGGLE_STANDALONE_LOGIN = 'ACCOUNT_PROFILE_TOGGLE_STANDALONE_LOGIN'
export const ACCOUNT_PROFILE_USER_CREATED = 'ACCOUNT_PROFILE_USER_CREATED'
export const ACCOUNT_PROFILE_USER_ADDRESS_SAVED = 'ACCOUNT_PROFILE_USER_ADDRESS_SAVED'
export const ACCOUNT_PROFILE_DELETE = 'ACCOUNT_PROFILE_DELETE'

export interface ICustomerPreferencesNotifications {
  on_order_collecting: boolean
  on_order_completed: boolean
  on_order_created: boolean
  on_order_delivery: boolean
}

export interface ICustomerPreferences {
  notifications: ICustomerPreferencesNotifications
}

export enum UserType {
  UnknownUserType = 'UnknownUserType',
  GuestUserType = 'GuestUserType',
  CustomerUserType = 'CustomerUserType'
}

export interface CustomerInfo {
  company_name?: string
  id: number
  api_token: number
  braintree_token: string
  first_name?: string
  last_name?: string
  image?: string
  email?: string
  business_account: boolean
  phone?: string
  date_created: string
  date_updated: string
  referrer_code: string
  referral_code?: string
  status?: number
  default_address_id?: number
  type: string
  preferences: ICustomerPreferences
}

interface AccountProfileLoggedIn {
  type: typeof ACCOUNT_PROFILE_LOGGED_IN,
  payload: CustomerInfo
}

interface AccountProfileSignedUp {
  type: typeof ACCOUNT_PROFILE_SIGNED_UP
}

interface AccountProfileToggleLoginForm {
  type: typeof ACCOUNT_PROFILE_TOGGLE_LOGIN_FORM
}

interface AccountProfileBraintreeTokenClear {
  type: typeof ACCOUNT_PROFILE_BRAINTREE_TOKEN_CLEAR
  payload: null
}

interface AccountProfileSetUserType {
  type: typeof ACCOUNT_PROFILE_SET_USER_TYPE,
  payload: UserType
}

interface AccountProfileUserDataFetched {
  type: typeof ACCOUNT_PROFILE_USER_DATA_FETCHED,
  payload: CustomerInfo
}

interface AccountProfileUserDataClear {
  type: typeof ACCOUNT_PROFILE_USER_DATA_CLEAR
}
interface AccountProfileUserDataUpdate {
  type: typeof ACCOUNT_PROFILE_USER_DATA_UPDATE,
  payload: CustomerInfo
}

interface AccountProfileUserPhoneUpdate {
  type: typeof ACCOUNT_PROFILE_USER_PHONE_UPDATE,
  payload : { phone : string }
}

interface AccountProfileSaveProfileData {
  type: typeof ACCOUNT_PROFILE_SAVE_PROFILE_DATA,
  payload: CustomerInfo
}

interface AccountProfileSignOut {
  type: typeof ACCOUNT_PROFILE_SIGN_OUT
}

interface AccountProfileBraintreeTokenFetched {
  type: typeof ACCOUNT_PROFILE_BRAINTREE_TOKEN_FETCHED
  payload: string
}

interface AccountProfileToggleStandaloneLoginForm {
  type: typeof ACCOUNT_PROFILE_TOGGLE_STANDALONE_LOGIN
  payload: boolean
}

interface AccountProfileUserNotificationsDataFetched {
  type: typeof ACCOUNT_PROFILE_USER_NOTIFICATIONS_DATA_FETCHED
  payload: ICustomerPreferencesNotifications
}

interface AccountProfileUserCreated {
  type: typeof ACCOUNT_PROFILE_USER_CREATED,
  payload: CustomerInfo
}

interface AccountProfileUserAddressSaved {
  type: typeof ACCOUNT_PROFILE_USER_ADDRESS_SAVED,
  payload: IAddress
}

interface AccountProfileDelete {
  type: typeof ACCOUNT_PROFILE_DELETE
}

export type AccountProfileActions = AccountProfileLoggedIn
  | AccountProfileSignedUp
  | AccountProfileToggleLoginForm
  | AccountProfileSetUserType
  | AccountProfileUserDataFetched
  | AccountProfileUserDataClear
  | AccountProfileUserDataUpdate
  | AccountProfileSaveProfileData
  | AccountProfileUserPhoneUpdate
  | AccountProfileSignOut
  | AccountProfileBraintreeTokenFetched
  | AccountProfileBraintreeTokenClear
  | AccountProfileToggleStandaloneLoginForm
  | AccountProfileUserNotificationsDataFetched
  | AccountProfileUserCreated
  | AccountProfileUserAddressSaved
  | AccountProfileDelete

export interface CustomerDataChanges {
  first_name?: string
  last_name?: string
  phone?: string
  default_address_id?: number
  image_id?: number
  company_name?: string
}

export interface SignUpFormData {
  email: string
  password: string
  repeatPassword?: string
  referral_code?: string
  company_name?: string
  business_account?: boolean
  phone?: string
  '2fa_mode': string
}

export interface ProfileInfo {
  loggedIn: boolean
  profile: CustomerInfo | null
  signUpFormData: SignUpFormData
  showLoginForm: boolean
  userType: UserType
  braintree_token: string | null
}
