import { ThunkDispatch } from 'redux-thunk'
import { FavoritesService } from '../../services/FavoritesService'
import { ADD_TO_FAVORITES, FETCH_FAVORITES, IFavorite, REMOVE_FROM_FAVORITES } from './types'
import { CatalogService } from '../../services/CatalogService'

export function fetchFavorites () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const favorites = await FavoritesService.get()
    dispatch({
      type: FETCH_FAVORITES,
      payload: favorites as IFavorite[]
    })
  }
}

export function addToFavorites (id: string | number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const productId = String(id)
    await FavoritesService.add(productId)
    const product = await CatalogService.getProduct(productId)
    dispatch({
      type: ADD_TO_FAVORITES,
      payload: product
    })
  }
}

export function removeFromFavorites (id: string | number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const productId = String(id)
    await FavoritesService.remove(productId)
    dispatch({
      type: REMOVE_FROM_FAVORITES,
      payload: productId
    })
  }
}
