import { ApiService } from './ApiService'

class SearchServiceClass {
  constructor (
    private apiService: typeof ApiService
  ) { }

  async search (searchString: string) {
    return this.apiService.post('/search/universal', {
      search_text: searchString
    })
  }

  async loadMore (searchString: string, page: number, category: string, items_per_page: number) {
    const categString = category.toLocaleLowerCase()
    const res = await this.apiService.post(`/search/${categString}/${page}/${items_per_page}`, {
      search_text: searchString
    })
    return res
  }
}

export const SearchService = new SearchServiceClass(ApiService)
