import React, { useEffect } from 'react'
import { reduxForm, Field, FormErrors, InjectedFormProps, WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { AddressData } from '../../../store/order/types'
import { addAddress, editAddress } from '../../../store/order/actions'
import Loader from '../../loader/Loader'
import { Store } from 'react-notifications-component'
import { IAddress } from '../../../store/customers/types'

interface AddressFormProps {
  onAdd: (data: any) => void,
  onEdit: (data: any) => void,
  addAddress: (data: AddressData) => any,
  editAddress: (data: AddressData) => any,
  addressData: IAddress | null
}

enum FieldTypes {
  Input,
  Textarea
}

const CustomField = ({
  input,
  meta: {
    touched,
    error
  },
  fieldType = FieldTypes.Input,
  placeholder
}: {
  input: WrappedFieldInputProps,
  meta: WrappedFieldMetaProps,
  fieldType?: FieldTypes,
  placeholder: string
}) => {
  switch (fieldType) {
    case FieldTypes.Textarea:
      return <textarea className={classnames({ error: touched && error })} {...input} placeholder={placeholder} />
    default:
      return <input className={classnames({ error: touched && error })} {...input} placeholder={placeholder} />
  }
}

const NewAddressForm: React.FunctionComponent<
  AddressFormProps &
  InjectedFormProps<AddressData, AddressFormProps>
> = (props) => {
  useEffect(() => {
    if (props.addressData) {
      props.initialize(props.addressData)
    }
  }, [])

  const onSubmit = async (data: AddressData) => {
    try {
      if (props.addressData) {
        await props.editAddress(data)
        props.onEdit(data)
      } else {
        const { id: newAddressId } = await props.addAddress(data)
        data.id = newAddressId
        props.onAdd(data)
      }
    } catch (error) {
      if (!Array.isArray(error.errors))
        return;

      const message = error.errors[0].message || 'Something went wrong, please try again later';

      Store.addNotification({
        title: 'Address saving error',
        message: message,
        type: 'danger',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }
  return (
    <div className="dropdown__overlay">
      <Field component={CustomField} name="title" placeholder="Label (e.g. Home, Office, etc.)" autocomplete="off" />
      <Field component={CustomField} name="line1" placeholder="Street Address" />
      <Field component={CustomField} name="line2" placeholder="Apartment, suite, floor, etc" />
      <Field component={CustomField} name="city" placeholder="City" />
      <Field component={CustomField} name="state" placeholder="State" />
      <Field component={CustomField} name="zip" placeholder="ZIP Code" />
      <Field component={CustomField} fieldType={FieldTypes.Textarea} name="notes" placeholder="Driver instructions" />
      <button
        disabled={props.submitting}
        onClick={props.handleSubmit(onSubmit)}
        type="button"
        className="green">
        {props.submitting ? <Loader className='loader_full-central' /> : 'Save'}
      </button>
    </div>
  )
}

const WrappedNewAddressForm = reduxForm<AddressData, AddressFormProps>({
  form: 'newAddressForm',
  validate: ({
    title,
    line1,
    city,
    state,
    zip
  }: AddressData) => {
    const errors: FormErrors<AddressData> = {}
    if (!title) errors.title = 'First Name is required'
    if (!line1) errors.line1 = 'Street address is required'
    if (!city) errors.city = 'City is required'
    if (!state) errors.state = 'State is required'
    if (!/^\d+$/.test(zip)) errors.zip = 'ZIP Code is required'
    return errors
  }
})(NewAddressForm)

export default connect(
  null,
  { addAddress, editAddress }
)(WrappedNewAddressForm)
