function trimLongText (text: any, maxWords: number) {
  try {
    const words = text.split(/\s+/)
    if (words.length <= maxWords) {
      return text
    }
    return words.slice(0, maxWords).join(' ') + '...'
  } catch (e) {
    return ''
  }
}

export default trimLongText
