import { ThunkDispatch } from 'redux-thunk'

import InformationService from '../../services/InformationService'
import { APP_LINKS_FETCHED, ISocialLink, PRIVACY_POLICY_FETCHED, SOCIAL_NETWORK_LINKS_FETCHED, ANNOUNCEMENT_LIST_FETCHED, IAnnoucementsList } from './types'

export function getPrivacyPolicy () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const { text = '' } = await InformationService.getPrivacyPolicy()
    dispatch({
      type: PRIVACY_POLICY_FETCHED,
      payload: text
    })
  }
}

export function getSocialNetworksLinks () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const links = await InformationService.getSocialNetworkLinks()
    dispatch({
      type: SOCIAL_NETWORK_LINKS_FETCHED,
      payload: links as ISocialLink[]
    })
  }
}

export function fetchAnnouncements () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const data = await InformationService.getAnnouncements()
    dispatch({
      type: ANNOUNCEMENT_LIST_FETCHED,
      payload: data as IAnnoucementsList[]
    })
  }
}

export function getAppLinks () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const links = await InformationService.getAppLinks()
    dispatch({
      type: APP_LINKS_FETCHED,
      payload: links as ISocialLink[]
    })
  }
}
