import Cookies from 'js-cookie'
import { ApiService, ApiServiceClass } from './ApiService'
import { CustomerSignUpInfo } from '../store/customers/types'
import { GuestService, GuestsServiceClass } from './GuestsService'
import {
  CustomerDataChanges,
  CustomerInfo,
  ICustomerPreferencesNotifications,
  UserType
} from '../store/account/profile/types'

export class CustomersServiceClass {
  apiService: ApiServiceClass;
  guestService: GuestsServiceClass;

  constructor (apiService: ApiServiceClass, guestService: GuestsServiceClass) {
    this.apiService = apiService
    this.guestService = guestService
  }

  setCustomerInfo (customer: CustomerInfo) {
    if (!customer) throw new Error('Unauthorized')
    if (customer.api_token) {
      this.token = String(customer.api_token)
    }
    if (customer.id) {
      this.customerId = String(customer.id)
    }
  }

  getUserType (): UserType {
    const customerUserToken = Cookies.get('customer:token')
    if (customerUserToken) {
      return UserType.CustomerUserType
    }

    const guestUserToken = Cookies.get('guest:token')
    if (guestUserToken) {
      return UserType.GuestUserType
    }

    return UserType.UnknownUserType
  }

  get token (): string | undefined {
    return Cookies.get('customer:token')
  }

  set token (value: string | undefined) {
    if (value) {
      this.guestService.token = undefined
      Cookies.set('customer:token', value, {
        expires: 365,
        secure: true,
        sameSite: 'none'
      })
    } else {
      Cookies.remove('customer:token')
    }
  }

  get customerId () {
    return Cookies.get('customer:id')
  }

  set customerId (value: string | undefined) {
    if (value) {
      Cookies.set('customer:id', value, {
        expires: 365,
        secure: true,
        sameSite: 'none'
      })
      Cookies.remove('guest:id')
    } else {
      Cookies.remove('customer:id')
    }
  }

  createCustomer (customerInfo: CustomerSignUpInfo) {
    return this.apiService.post('/v2/customers', customerInfo)
  }

  startRegister (customerInfo: CustomerSignUpInfo) {
    return this.apiService.post('/v2/customers/registration', customerInfo)
  }

  async verifySmsCode (customerId: string, code: string) {
    const customer: CustomerInfo = await this.apiService.post(`/v2/customers/${customerId}/verifySMSCode`, { code })
    this.setCustomerInfo(customer)
    return customer
  }

  async verifySmsCodeRegister (code: string) {
    const customer: CustomerInfo = await this.apiService.post('/v2/customers/verifyRegistrationByCode', { code })
    this.setCustomerInfo(customer)
    return customer
  }

  async initResetPassword (customerInfo: CustomerSignUpInfo) {
    return this.apiService.post('/accounts/initiatePasswordReset', { ...customerInfo, type: 'customer' })
  }

  async finishResetPassword (customerInfo: any) {
    return this.apiService.post('/accounts/resetPassword', customerInfo)
  }

  async login (customerInfo: CustomerSignUpInfo) {
    const customer: CustomerInfo = await this.apiService.post('/v2/customers/login', customerInfo)
    this.setCustomerInfo(customer)
    return customer
  }

  async loginByToken (data: {
    token: string,
    type: 'Google' | 'Apple',
    email?: string,
    phone?: string,
    '2fa_mode'?: string
  }) {
    const customer: CustomerInfo = await this.apiService.post('/v2/customers/tokenLogin', data)
    this.setCustomerInfo(customer)
    return customer
  }

  async getUnfinishedRegister () {
    const customer: CustomerInfo = await this.apiService.get('/v2/customers/unfinishedRegistration')
    return customer
  }

  async getUserData (id: string | number | null) {
    if (!id) {
      id = String(this.customerId)
    }
    const customer: CustomerInfo = await this.apiService.get(`/customers/${id}`)
    return customer
  }

  async setCustomerNotifications (notifications: ICustomerPreferencesNotifications) {
    return this.apiService.post(`/customers/${this.customerId}/setPreferences`, {
      preferences: {
        notifications
      }
    })
  }

  async setCustomerNotificationsByApiToken (notifications: ICustomerPreferencesNotifications, token: string) {
    return this.apiService.post(`/customers/setPreferencesExpiring?api_token=${token}`, {
      preferences: {
        notifications
      }
    })
  }

  async signOut () {
    await this.apiService.post('/customers/logout')
    Cookies.remove('customer:token')
    Cookies.remove('customer:id')
    Cookies.remove('guest:token')
    Cookies.remove('guest:id')
  }

  async deleteAccount () {
      await this.apiService.delete(`/v2/customers/${this.customerId}`)
  }

  async updateUserData (userData: CustomerDataChanges) {
    const id = this.customerId
    await this.apiService.put(`/customers/${id}`, userData)
  }

  async updateUserPhone (userData: { phone : string }) {
    const id = this.customerId
    await this.apiService.put(`/v2/customers/${id}/phone`, userData)
  }

  async verifyPhoneSmsCode (code: string) {
    const id = this.customerId
    const customer: CustomerInfo = await this.apiService.post(`/v2/customers/${id}/phone/verifySMSCode`, { code })
    this.setCustomerInfo(customer)
    return customer
  }

  async getOrderAgain ({ page = 1, items = 20 } = {}) {
    const id = this.customerId
    const res = await this.apiService.post(`/customers/${id}/orderedProducts/list/${page}/${items}`)
    return res
  }

  async changePassword ({ currentPassword, newPassword }: {
    currentPassword: string,
    newPassword: string
  }) {
    return this.apiService.post('/accounts/changePassword', {
      current_password: currentPassword,
      new_password: newPassword
    })
  }

  async getBraintreeToken () {
    return this.apiService.get('/customers/getBraintreeToken')
  }

  async setPreferences ({ preferred_mode, customerId, notifications }: {
    preferred_mode: string
    customerId: string
    notifications: object
  }) {
    return this.apiService.post(`/customers/${customerId}/setPreferences`, {
      preferences: {
        notifications,
        delivery: {
          preferred_mode
        }
      }
    })
  }
}

// TODO: REMOVE ALL USAGES
export function withToken () {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value
    descriptor.value = async function (...args: any[]) {
      return originalMethod.apply(this, args)
    }
  }
}

export const CustomersService = new CustomersServiceClass(ApiService, GuestService)
