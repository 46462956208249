import React, { useEffect, useRef, MutableRefObject } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import './menu.scss'

import { IDepartment } from '../../../../store/departments/types'
import classnames from 'classnames'
import archive from '../../../../assets/images/archive.svg'
import repeat from '../../../../assets/images/repeat.svg'
import favorite from '../../../../assets/images/favorite.svg'
import bonuses from '../../../../assets/images/bonuses.svg'
import chevron from '../../../../assets/images/chevron-right.svg'
import { UserType } from '../../../../store/account/profile/types'
import Accordion from './Accordion'
import { ReduxState } from '../../../../store/reducers'
import { fetchCustomerData, showStandaloneLoginForm } from '../../../../store/account/profile/action-creators'
import { getAddresses } from '../../../../store/order/actions'
import { IAddress } from '../../../../store/customers/types'

export interface IDepartmentProps extends IDepartment {
  loading?: boolean
}

interface IDepartments {
  items?: IDepartmentProps[]
  closeMenu: () => void
  opened: boolean
}

interface IDepartmentsConnectedProps {
  getCustomerData: () => {}
  getAddresses: () => {}
  isCustomer: boolean
  name: string
  address: string
  showStandaloneLoginForm: () => void
}

const Menu: React.FC<IDepartments & IDepartmentsConnectedProps> = ({ items = [], closeMenu, isCustomer, getCustomerData, getAddresses, address, name, opened, showStandaloneLoginForm }) => {
  useEffect(() => {
    if (isCustomer) {
      getCustomerData()
      getAddresses()
    }
  }, [isCustomer])

  function showLoginForm () {
    closeMenu()
    showStandaloneLoginForm()
  }

  return (
    <div className={classnames('burger-menu', { opened: opened })}>
      { isCustomer
        ? <div className="burger-menu__user">
            <div className="user__name"><span>{name}</span>
              <NavLink to="/account/profile">
                <button title="Edit profile" onClick={closeMenu}/>
              </NavLink>
            </div>
            <div className="user__address">{address}</div>
          </div>
        : <div className="burger-menu__user"><div className="header__signup menu" onClick={showLoginForm}>
            <span style={{ textDecoration: 'underline' }}>Sign Up</span>&nbsp;{
              'to achieve the best experience!'
            }
          </div>
        </div>
      }
      <div className="burger-menu__links" onClick={closeMenu}>
        {!isCustomer ? <div className="dimmer"></div> : null}
        <NavLink to='/account/orders' className="links-item">
          <img className="links-item__icon" src={archive} alt="Your orders icon" />
          <div className="links-item__text">Your orders</div>
          <img className="links-item__arrow" src={chevron} alt="Chevron right icon" />
        </NavLink>
        <NavLink to='/previously-ordered' className="links-item">
          <img className="links-item__icon" src={repeat} alt="Order again icon" />
          <div className="links-item__text">Order again</div>
          <img className="links-item__arrow" src={chevron} alt="Chevron right icon" />
        </NavLink>
        <NavLink to="/account/favorites" className="links-item">
          <img className="links-item__icon" src={favorite} alt="Favorite products icon" />
          <div className="links-item__text">Favorite Products</div>
          <img className="links-item__arrow" src={chevron} alt="Chevron right icon" />
        </NavLink>
      </div>
      <div className="burger-menu__shop">
        <div className="burger-menu__shop-header">Shop</div>
        <Accordion items={items} close={closeMenu}/>
      </div>
      <div className="burger-menu__contacts">
        <div className="burger-menu__contacts-header">Contacts</div>
        <div className="burger-menu__contacts-body">
          <span>Main Office</span>
          <a href={
            'tel:(347) 422-7812'
          }>{
            '(347) 422-7812'
          }</a>
          <a href={
            'mailto:deliveryicart@gmail.com'
          }>{
            'deliveryicart@gmail.com'
          }</a>
        </div>
      </div>
    </div>
  )
}

const ConnectedMenu = connect(
  (state: ReduxState) => {
    const isCustomer = state.account.profile.userType === UserType.CustomerUserType
    let address = 'Delivery address is not specified'
    let name = 'Profile is incomplete'
    if (isCustomer) {
      if (state.account.profile.profile && (state.account.profile.profile.first_name || state.account.profile.profile.last_name)) {
        const actualFullName = `${state.account.profile.profile.first_name} ${state.account.profile.profile.last_name}`.trim()
        if (actualFullName) {
          name = actualFullName
        }
      }
      if (state.account.profile.profile && state.account.profile.profile.default_address_id) {
        if (state.order.list && state.order.list.length) {
          const addressInfo = state.order.list.find(item => {
            if (state.account.profile.profile) {
              return item.id === state.account.profile.profile.default_address_id
            }
            return false
          }) as IAddress
          if (addressInfo) {
            address = [
              addressInfo.line1,
              addressInfo.line2,
              addressInfo.city,
              `${addressInfo.state} ${addressInfo.zip}`
            ]
              .filter(Boolean)
              .join(', ')
          }
        }
      }
    }
    return {
      isCustomer,
      name,
      address
    }
  },
  {
    getCustomerData: fetchCustomerData,
    getAddresses,
    showStandaloneLoginForm
  }
)(Menu)

function useOutsideWrapper (ref:MutableRefObject<any>, closeCatalog: ()=>void, ignoreItems?: MutableRefObject<any>[]) {
  useEffect(() => {
    function handleClickOutside (event: MouseEvent) {
      if (ref && (!ignoreItems || !ignoreItems.some(x => x.current.contains(event.target as Node))) && !ref.current.contains(event.target as Node)) {
        closeCatalog()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

interface IWrapper {
  closeMenu: () => void,
  ignoreItems?: MutableRefObject<any>[],
  children?: any
}

function OutsideWrapper (props: IWrapper) {
  const wrapperRef = useRef(null)
  useOutsideWrapper(wrapperRef, props.closeMenu, props.ignoreItems)

  return (
    <div ref={wrapperRef} className="absolute">
      {props.children}
    </div>
  )
}

function Wrapper (props: IDepartments & IWrapper) {
  return <OutsideWrapper closeMenu={props.closeMenu} ignoreItems={props.ignoreItems}>
    <ConnectedMenu {...props} />
  </OutsideWrapper>
}

export default Wrapper
