import guests, { GuestState } from './guests/reducer'
import catalog from './catalog/reducer'
import departments from './departments/reducer'
import cart from './cart/reducer'
import order from './order/reducer'
import orders from './orders/reducer'
import main from './main/reducer'
import account, { AccountPage } from './account/reducers'
import dashboard from './dashboard/reducer'
import favorites from './favorites/reducer'
import { combineReducers } from 'redux'
import { Catalog } from './catalog/types'
import { MainPage } from './main/types'
import { FormReducer, reducer as formReducer } from 'redux-form'
import { IDepartments } from './departments/types'
import { CartState } from './cart/types'
import { IOrder } from './order/types'
import { IOrders } from './orders/types'
import { INewsState } from './news/types'
import { IDashboard } from './dashboard/types'
import news from './news/reducer'
import { IFavoritesState } from './favorites/types'
import { ISearchResult, IPreSearchState } from './search/types'
import search from './search/reducer'
import information from './information/reducer'
import { IInformation } from './information/types'
import store from './store/reducer'
import { IStoreState } from './store/types'

export interface ReduxState {
  guests: GuestState;
  catalog: Catalog;
  main: MainPage;
  account: AccountPage;
  form: FormReducer;
  departments: IDepartments;
  cart: CartState;
  order: IOrder;
  orders: IOrders;
  news: INewsState;
  dashboard: IDashboard;
  favorites: IFavoritesState;
  search: ISearchResult & IPreSearchState;
  information: IInformation;
  store: IStoreState
}

const rootReducer = combineReducers({
  cart,
  order,
  guests,
  catalog,
  main,
  account,
  departments,
  orders,
  dashboard,
  form: formReducer,
  news,
  favorites,
  search,
  information,
  store
})

export default rootReducer
