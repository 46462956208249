import { ApiService, ApiServiceClass } from './ApiService'

class InformationService extends ApiServiceClass {
  getPrivacyPolicy () {
    return ApiService.get('/feed/info/privacyPolicy')
  }

  getTermsAndConditions () {
    return ApiService.get('/feed/info/termsAndConditions')
  }

  getAboutUs () {
    return ApiService.get('/feed/info/aboutUs')
  }

  getDeliveryInformation () {
    return ApiService.get('/feed/info/deliveryInformation')
  }

  getSocialNetworkLinks () {
    return ApiService.get('/feed/info/socialLinks')
  }

  getAppLinks () {
    return ApiService.get('/feed/info/appLinks')
  }

  async getAnnouncements ({ page = 1, items = 20 } = {}) {
    const propertyName = process.env.REACT_APP_TARGET_FOLDER === 'nrs' ? 'is_shown_at_nrs' : 'is_shown_at_cinch'
    const filters = {
      [propertyName]: true
    }
    const res = await ApiService.post(`/feed/announcements/list/${page}/${items}`, {
      filtering: filters
    })
    return res
  }
}

export default new InformationService()
