import React, { useEffect, useState, ChangeEvent, SyntheticEvent } from 'react'
import { Field, InjectedFormProps, reduxForm, FormErrors, SubmissionError, getFormValues } from 'redux-form'
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'
import { Store } from 'react-notifications-component'

import './order.scss'

import { getCart, updateCart, getDeliveryInfo } from '../../store/cart/actions'
import { getCoupon, checkCoupon, checkCouponStart, createOrder, getAddresses, clearOrderData, clearCouponData } from '../../store/order/actions'
import { getBraintreeToken, updateProfileData, saveOrderProfileData } from '../../store/account/profile/action-creators'
import { getBonuses } from '../../store/dashboard/actions'
import { OrderService } from '../../services/OrderService'
import { ICoupon, INewOrderData } from '../../store/order/types'
import { ReduxState } from '../../store/reducers'
import { initializeBraintree } from './helpers'
import { withCallbacks, WithCallbacksProps } from '../../utils/withCallbacks'
import { withProps } from '../../utils/withProps'
import { CustomInput } from './CustomInput'
import DeliveryAddress, { getAddressString } from './DeliveryAddresss/DeliveryAddress'
import Coupon, { ICouponMessage } from './Coupon'
import CartSidebar from '../cart/CartSidebar'
import Loader from '../loader/Loader'
import Bonus from './Bonus'
import { CustomerDataChanges, CustomerInfo, UserType } from '../../store/account/profile/types'
import { IServerErrors } from '../../types/ServerErrors'
import { CartBenefits, ICartStore } from '../../store/cart/types'
import { IAddress } from '../../store/customers/types'
import priceToString from '../../utils/PriceToString'
import DriverTips from './DriverTips'

import checkImg from '../../assets/images/check.svg'

interface OrderFormProps {
  clearOrderData: () => void
  getBraintreeToken: () => void
  checkCouponStart: () => void
  getAddresses: () => void
  checkCoupon: (data: ChangeEvent<HTMLInputElement>) => void
  createOrder: (data: INewOrderData) => void
  updateProfileData: (data: CustomerDataChanges) => void
  updateCart: (data: any) => void
  fetchCustomerData: () => void
  getDeliveryInfo: (address: object[], storeIds: number[]) => { eta: string, details: { message: string }[] }
  getBonuses: () => void
  couponLoading: boolean
  coupon: ICoupon | null
  benefits?: CartBenefits
  braintreeToken?: string
  coupons: ICoupon[]
  couponCode: string
  selectAddress: number,
  addresses: IAddress[] | null
  bonuses: number
  profile: CustomerInfo
  driverTips: number
  saveOrderProfileData: () => void
  isCouponAvailable: boolean | null
  clearCouponData: (coupon: ICoupon) => void
  total: number
  close: () => void
  cartStore?: ICartStore,
  isCurrentUserCustomer: boolean
  isCartModal: boolean
  getCart: () => void
}

interface OrderFormData {
  firstName: string
  companyName?: string
  lastName: string
  phone: string
  email: string
  address: number
  driverInstructions: string
  date: string
}

type OrderFormWithCallbacksProps = OrderFormProps & WithCallbacksProps & RouteComponentProps
type OrderFormComponentProps = OrderFormWithCallbacksProps & InjectedFormProps<OrderFormData, OrderFormWithCallbacksProps>

const OrderForm: React.FunctionComponent<OrderFormComponentProps & WithCallbacksProps> = props => {
  const [couponCode, setCouponCode] = useState(props.couponCode)
  const [deliveryMessage, setDeliveryMessage] = useState({ eta: '', message: '' })
  const [couponMessage, setCouponMessage] = useState<ICouponMessage | null>(null)
  const [{ paymentHandler }, setPaymentHandler] = useState<any>({ paymentHandler: null })
  const couponApplied = Boolean(couponCode && !props.couponLoading && props.coupon)
  const [openCouponModal, setOpenCouponModal] = useState(false)

  useEffect(() => {
    props.getAddresses()
  }, [])

  useEffect(() => {
    if (props.braintreeToken) {
      const storeId = props.cartStore ? props.cartStore.id : 0
      initializeBraintree(props.braintreeToken, storeId).then(handler => {
        setPaymentHandler({ paymentHandler: handler })
      })
    }
  }, [props.braintreeToken])

  useEffect(() => {
    if (couponApplied && props.coupon && !props.coupons.find(({ id }) => {
      return props.coupon && props.coupon.id === id
    })) {
      OrderService.applyCoupon({ ...props.coupon, storeId: props.cartStore ? props.cartStore.id : 0 })
        .then(res => props.updateCart(res)).catch((error: IServerErrors) => {
          if (error.errors && error.errors.length > 0) {
            setCouponMessage({
              type: 'error',
              message: error.errors[0].message
            })
          }
        })
    }
  }, [props.coupon])

  useEffect(() => {
    if (props.coupons && props.coupons.length > 0) {
      if (props.coupons[0] && props.coupons[0].code) {
        setCouponCode(props.coupons[0].code)
      }
    }
  }, [props.coupons])

  useEffect(() => {
    if (props.isCurrentUserCustomer) {
      props.getBonuses()
    }
  }, [props.bonuses])

  useEffect(() => {
    if (props.benefits) {
      let message = null
      if (!isNaN(props.benefits.coupons && props.benefits.coupons[0] && props.benefits.coupons[0].saved_amount) && props.benefits.coupons[0].saved_amount > 0) {
        message = {
          message: `You are saving $${props.benefits.coupons[0].saved_amount / 100}`,
          type: ''
        }
      } else if (props.coupon && props.coupon.gift_products.length > 0) {
        message = null
      } else if (props.coupon && props.coupon.minimal_order_total && props.coupon.minimal_order_total > props.total) {
        message = {
          message: `Minimal order total is not reached for coupon usage! Minimal order total: ${priceToString(props.coupon.minimal_order_total)} Your order total: ${priceToString(props.total)}`,
          type: 'error'
        }
      } else {
        message = {
          message: 'Sorry, your order does not meet coupon requirements',
          type: 'error'
        }
      }
      setCouponMessage(message)
    }
  }, [props.benefits])

  const onSubmit = async (data: OrderFormData) => {
    if (props.isCouponAvailable === false && props.braintreeToken) {
      setCouponCode('')
      if (props.coupon) {
        try {
          await props.clearCouponData(props.coupon)
        } catch (e) {
          throw new SubmissionError({ _error: 'Failed to initialize coupon' })
        }
      }
    }
    if (!props.braintreeToken) {
      try {
        await props.getBraintreeToken()
        const delivery = await props.getDeliveryInfo([
          { address: props.addresses ? getAddressString(props.addresses.find(item => item.id === props.selectAddress) || props.addresses[0]) : '' },
          { address: props.cartStore ? getAddressString(props.cartStore.address) : '' }
        ], [props.cartStore ? props.cartStore.id : 0])

        setDeliveryMessage({ eta: delivery.eta || '', message: delivery.details.length ? delivery.details[0].message : '' })
      } catch (e) {
        throw new SubmissionError({ _error: 'Failed to initialize payment system' })
      }
      return
    }

    if (!paymentHandler) throw new SubmissionError({ _error: 'Failed to initialize payment system' })
    if (props.addresses) {
      props.addresses.forEach((address) => {
        if (address.id === data.address) {
          const errors = []
          if (!address.title) {
            errors.push('Title')
          }
          if (!address.line1) {
            errors.push('Street address')
          }
          if (!address.city) {
            errors.push('City')
          }
          if (!address.state) {
            errors.push('State')
          }
          if (!address.zip) {
            errors.push('ZIP')
          }

          if (errors.length > 0) {
            throw new SubmissionError({ _error: `Fill all required fields for delivery address: ${errors.join(', ')}` })
          }
        }
      })
    }

    try {
      await props.updateProfileData({
        ...data.address !== props.initialValues.address && { default_address_id: data.address },
        ...data.firstName !== props.initialValues.firstName && { first_name: data.firstName },
        ...data.lastName !== props.initialValues.lastName && { last_name: data.lastName },
        ...data.phone !== props.initialValues.phone && { phone: data.phone },
        ...data.companyName !== props.initialValues.companyName && { company_name: data.companyName }
      })
      const brainTreeResponse = await paymentHandler()
      const orderId = await props.createOrder({
        nonce: brainTreeResponse.nonce,
        driverInstructions: data.driverInstructions,
        deliverySchedule: data.date,
        recurringType: 0,
        addressId: data.address,
        storeId: props.cartStore ? props.cartStore.id : undefined
      })

      props.close()

      Store.addNotification({
        title: 'Order',
        message: `Thank you for your order #${orderId}!`,
        type: 'success',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 10000,
          onScreen: true
        }
      })
      await props.getCart()
    } catch (error: any) {
      if (error && error.errors) {
        if (Array.isArray(error.errors) && error.errors.length > 0) {
          throw new SubmissionError({
            _error: error.errors.map((error: any) => {
              return error && error.message ? error.message : ''
            }).join('\n')
          })
        }
      } else {
        throw new SubmissionError({ _error: 'Something went wrong, please try again later' })
      }
    }
    props.clearOrderData()
    props.history.push('/account/orders')
  }

  const toggleOpen = (e: SyntheticEvent) => {
    e.preventDefault()
    props.reset()
    setOpenCouponModal(!openCouponModal)
  }

  const onCouponCodeInput = debounce(async (event: ChangeEvent<HTMLInputElement>) => {
    setCouponCode(event.target.value)

    if (props.coupon) {
      await OrderService.deleteCoupon({ ...props.coupon, storeId: props.cartStore ? props.cartStore.id : 0 }).then(res => props.updateCart(res))
    }

    if (event.target.value) {
      await props.checkCouponStart()
    }
    props.checkCoupon(event)
  }, 500)

  return (
    <section className="order">
      { !props.isCartModal
        ? (
            <div className="breadcrumbs">
              <NavLink to="/" className="breadcrumbs__item breadcrumbs__item--link">Main</NavLink>
              <div className="breadcrumbs__divider"></div>
              <div className="breadcrumbs__item">Order</div>
            </div>
          )
        : null }
      <form className="order-form form-type-2" onSubmit={props.handleSubmit(onSubmit)}>
        <div className="order-form__step address">
          <h3 className="multi-cart-title">Delivery info</h3>
          <Field
            name="address"
            labelText="Delivery Address"
            readOnly
            component={DeliveryAddress}
            changeValue={props.change.bind(null, 'address')}
            pushFocusCallback={props.pushCallback}
            placeholder="Please enter a delivery address" />
          <Field
            name="driverInstructions"
            labelText="Driver instructions"
            component={CustomInput}
            type='textarea'
            pushFocusCallback={props.pushCallback}
            placeholder="Any instructions specific to your order" />
        </div>
        <div className="order-form__step coupon-el">
          <h3 className="multi-cart-title">Coupon</h3>
          <div className="field dropdown">
            <label className="field">
              Coupon Code
              <div className="input">
                <input
                  className="long"
                  defaultValue={couponCode}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    event.persist()
                    onCouponCodeInput(event)
                  }}
                  placeholder="Please enter a coupon code if you have one" />
                <input
                  className="short"
                  defaultValue={couponCode}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    event.persist()
                    onCouponCodeInput(event)
                  }}
                  placeholder="Please enter a coupon code" />
                {couponApplied && <div className="outer check"><img src={checkImg} alt="check" /></div>}
              </div>
            </label>
            <div className="dropdown__overlay">
              {
                (
                  couponCode
                    ? props.couponLoading
                        ? 'Checking...'
                        : props.coupon
                          ? <Coupon {...props.coupon} message={couponMessage} submit={props.handleSubmit(onSubmit)} submitting={props.submitting} reset={props.reset} openModal={openCouponModal} toggleModal={toggleOpen}/>
                          : 'Coupon not found'
                    : null
                )
              }
            </div>
          </div>
        </div>
        <div className="order-form__step driverTips">
          <h3 className="multi-cart-title">Driver tips</h3>
          <DriverTips />
        </div>
        <div className="order-form__step payment">
          <h3 className="multi-cart-title">Payment</h3>
          { props.braintreeToken && (deliveryMessage.eta || deliveryMessage.message)
            ? (
                deliveryMessage.eta ? <div className="multi-cart-delivery success">ETA: {deliveryMessage.eta}</div> : <div className="multi-cart-delivery failed">{deliveryMessage.message}</div>
              )
            : null}
          {
            props.braintreeToken
              ? <Bonus allBonuses={props.bonuses} />
              : null
          }
          <CartSidebar storeId={props.cartStore ? props.cartStore.id : undefined}/>
          <div id={'dropin-container' + (props.cartStore ? props.cartStore.id : '')}></div>
          <button
            onClick={props.isCouponAvailable === false && props.braintreeToken ? toggleOpen : props.handleSubmit(onSubmit)}
            disabled={props.submitting}
            id="submit-button"
            type="submit"
            className="order-form__button">
            {props.submitting ? <Loader className='loader_full-central' /> : 'Pay'}
          </button>
          {!props.submitting && props.error && <span className='error'>{props.error}</span>}
        </div>
      </form>
    </section>
  )
}

const WrappedOrderForm = reduxForm<OrderFormData, OrderFormWithCallbacksProps>({
  form: 'orderForm',
  validate: ({
    firstName,
    lastName,
    phone,
    email,
    address
  }: OrderFormData) => {
    const errors: FormErrors<OrderFormData> = {}
    if (!firstName) errors.firstName = 'First Name is required'
    if (!lastName) errors.lastName = 'Last Name is required'
    if (!/^(\d|\+|\(|\)|-| )+$/.test(phone)) errors.phone = 'Phone Number is required'
    if (!/@/.test(email)) errors.email = 'Email is required'
    if (!address) errors.address = 'Address is required'

    return errors
  },
  onSubmitFail: function (errors, __, ___, props) {
    if (!errors) return
    const [firstErrorKey] = Object.keys(errors)
    const firstErrorFieldCallback: () => void = props.callbacks[firstErrorKey]
    firstErrorFieldCallback && firstErrorFieldCallback()
  }
})(OrderForm)

const WithCallbacks = withCallbacks(WrappedOrderForm)

const ConnectedForm = connect(
  ({ cart, order: { couponLoading, coupon, isCouponAvailable } }: ReduxState) => {
    let couponCode = coupon && coupon.code ? coupon.code : ''
    if (cart && cart.coupons && cart.coupons[0]) {
      coupon = cart.coupons[0]
      couponCode = cart.coupons[0].code
    }

    return {
      ...cart,
      couponLoading,
      coupon,
      couponCode,
      isCouponAvailable
    }
  },
  { updateCart, checkCouponStart }
)(withRouter(WithCallbacks))

const WithProps = withProps({
  checkCoupon: ({ checkCoupon }: {
    checkCoupon: (code: string) => void
  }) => debounce(({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    checkCoupon(value)
  }, 500)
})(ConnectedForm)

const ConnectedWithProps = connect(
  (state: ReduxState) => {
    const { profile = null } = state.account.profile || {}
    return {
      ...profile && {
        initialValues: {
          firstName: profile.first_name,
          lastName: profile.last_name,
          email: profile.email,
          phone: profile.phone,
          address: profile.default_address_id,
          companyName: profile.company_name
        }
      },
      braintreeToken: state.account.profile.braintree_token,
      addresses: state.order.list,
      selectAddress: getFormValues('address')(state),
      bonuses: state.dashboard.available,
      profile: state.account.profile.profile,
      isCurrentUserCustomer: state.account.profile.userType === UserType.CustomerUserType
    }
  },
  { getCoupon, checkCoupon, createOrder, getBraintreeToken, updateProfileData, getAddresses, clearOrderData, getBonuses, saveOrderProfileData, clearCouponData, getCart, getDeliveryInfo }
)(WithProps)

export default ConnectedWithProps
