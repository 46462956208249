import { OrderService } from '../../services/OrderService'
import { ThunkDispatch } from 'redux-thunk'
import {
  GET_ADDRESSES,
  ADD_ADDRESS,
  GET_COUPON,
  GET_COUPON_START,
  AddressData,
  INewOrderData,
  ORDER_CREATED,
  EDIT_ADDRESS,
  CLEAR_ORDER_DATA,
  COUPON_AVAILABILITY,
  CLEAR_COUPON_AVAILABILITY_DATA,
  CLEAR_COUPON_DATA,
  ICoupon
} from './types'

import { updateCart } from '../cart/actions'

export function getAddresses () {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    const data = await OrderService.getAddresses()
    dispatch({
      type: GET_ADDRESSES,
      payload: data
    })
  }
}

export function addAddress (data: AddressData) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    const newAddressData : { id: AddressData } = await OrderService.addAddress(data)
    dispatch({
      type: ADD_ADDRESS,
      payload: newAddressData
    })
    return { id: newAddressData.id }
  }
}

export function editAddress (data: AddressData) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    const { id }: { id: number } = await OrderService.editAddress(data)
    dispatch({
      type: EDIT_ADDRESS,
      payload: {
        ...data,
        id
      }
    })
    return { id }
  }
}

export function checkCouponStart () {
  return {
    type: GET_COUPON_START
  }
}

export function checkCoupon (couponCode: string) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    let coupon = null
    try {
      coupon = await OrderService.getCoupon(couponCode)
    } catch (error) { }
    dispatch({
      type: GET_COUPON,
      payload: coupon
    })
  }
}

export function getCoupon (id: string) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    try {
      const coupon = await OrderService.getCouponById(id)
      dispatch({
        type: GET_COUPON,
        payload: coupon
      })
    } catch (error) { }
  }
}

export function clearOrderData () {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    dispatch({
      type: CLEAR_ORDER_DATA
    })
  }
}

export function checkCouponAvailability () {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    const data = await OrderService.checkCouponAvailability()
    dispatch({
      type: COUPON_AVAILABILITY,
      payload: data
    })
  }
}

export function clearCouponAvailabilityData () {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    dispatch({
      type: CLEAR_COUPON_AVAILABILITY_DATA
    })
  }
}

export function clearCouponData (coupon: ICoupon) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    const data = await OrderService.deleteCoupon({
      ...coupon,
      storeId: 0
    })
    dispatch({
      type: CLEAR_COUPON_DATA
    })
    dispatch(updateCart(data))
  }
}

export function reorder (orderId: string) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    const data = await OrderService.reorder(orderId)
    dispatch(updateCart(data))
  }
}

export function createOrder (data: INewOrderData) {
  return async function (dispatch: ThunkDispatch<{}, {}, any>) {
    const { id } = await OrderService.makeOrder({
      nonce: data.nonce,
      driverInstructions: data.driverInstructions,
      deliverySchedule: data.deliverySchedule,
      recurringType: 0,
      addressId: data.addressId,
      storeId: data.storeId
    })

    dispatch({
      type: ORDER_CREATED,
      payload: id
    })

    return id
  }
}
