import { ApiService } from './ApiService'
import { CustomersService } from './CustomersService'

export class BonusServiceClass {
  async get () {
    return ApiService.get(`/customers/${CustomersService.customerId}/bonuses`)
  }

  async useBonuses (bonuses: number) {
    return ApiService.post('/customers/useBonuses', { amount: bonuses })
  }
}

export const BonusService = new BonusServiceClass()
