import React, { useEffect, useState, SyntheticEvent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { ReduxState } from '../../store/reducers'

import priceToString from '../../utils/PriceToString'
import formatDate from '../../utils/formatDate'
import Subsection from '../header/subsection/Subsection'
import Loader from '../loader/Loader'
import { ICoupon, ICouponProduct } from '../../store/order/types'
import { checkCouponAvailability, clearCouponAvailabilityData } from '../../store/order/actions'

export interface ICouponMessage {
  message: string
  type?: string
}

interface CouponProps extends ICoupon {
  message?: ICouponMessage | null
  checkCouponAvailability: () => void
  isCouponAvailable: boolean | null
  clearCouponAvailabilityData: () => void
  submitting: boolean
  submit: () => void
  reset: () => void
  openModal: boolean
  toggleModal: (e: SyntheticEvent) => void
}

const ProductCard: React.FunctionComponent<ICouponProduct & { count: number }> = props => (
  <div className="cart__item">
    <Link to={`/catalog/${props.id}`} target="_blank" className="cart__item-image" title={props.name}>
      {
        props.images.map(
          (image, i) => <img key={i} src={image.url} />
        )
      }
    </Link>
    <div className="cart__item-properties">
      <h3 className="cart__item-title">{props.name}</h3>
      <p className="cart__item-description">{props.description}</p>
    </div>
    <div className="cart__item-amount">{props.count} pcs.</div>
    <div className="cart__item-total cart__item-free">{priceToString(props.real_sale_price * props.count)}</div>
  </div>
)

const Coupon: React.FunctionComponent<CouponProps> = props => {
  const [messageContainer, setMessageContainer] = useState(props.message)
  const [openCouponModal, setOpenCouponModal] = useState(false)

  useEffect(() => {
    props.checkCouponAvailability()
    return () => {
      props.clearCouponAvailabilityData()
    }
  }, [props.id])

  useEffect(() => {
    setOpenCouponModal(props.openModal)
  }, [props.openModal])

  useEffect(() => {
    if (props.isCouponAvailable !== false) {
      setMessageContainer(props.message)
    }
  }, [props.message])

  useEffect(() => {
    if (props.isCouponAvailable === false) {
      setMessageContainer({
        message: 'Coupon is unavailable',
        type: 'error'
      })
    }
  }, [props.isCouponAvailable])

  return (
    <>
      {
      openCouponModal && (<Subsection {...props} close={props.toggleModal}>
        <form className='coupon__modal'>
          <h3 className="subsection__header">Order information</h3>
          <div>
            <p style={{ textAlign: 'center' }}>The coupon is not available anymore. Related benefits will be removed.</p>
          </div>
          <button type="submit" className="button_blue first-button" onClick={props.submit}>
            {props.submitting ? <Loader className='loader_full-central' /> : 'Proceed anyway'}
          </button>
          <button className="button_grey second-button" onClick={props.toggleModal}>
            Cancel
          </button>
        </form>
      </Subsection>)
      }
    <div className="coupon">
      <div className={props.isCouponAvailable === false ? 'faded' : ''}>
        <h2>{props.title}</h2>
        {props.details && <p>{props.details}</p>}
        {
          props.gift_products && props.gift_products.map(
            ({ count, product }, i) => <ProductCard key={i} {...{ ...product, count }} />)
        }
        {props.cash_discount > 0 && <p className="coupon__accent coupon__accent_big">-${props.cash_discount / 100}</p>}
        {props.percent_discount > 0 && <p className="coupon__accent coupon__accent_big">-{props.percent_discount}%</p>}
        {props.date_start && <p>Starts <b>{formatDate(props.date_start)}</b></p>}
        {props.date_end && <p>Ends <b>{formatDate(props.date_end)}</b></p>}
      </div>
      {
        messageContainer
          ? <>
              <hr />
              <p className={`coupon__accent ${messageContainer.type ? messageContainer.type : ''}`}>{messageContainer.message}</p>
            </>
          : null
      }
    </div>
  </>
  )
}

export default connect(
  (state: ReduxState) => {
    return {
      isCouponAvailable: state.order.isCouponAvailable
    }
  }, { checkCouponAvailability, clearCouponAvailabilityData })(Coupon)
