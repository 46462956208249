function priceToInt (price?: string): number {
  if (price === null || price === undefined) {
    return 0
  }

  if (price.includes('.')) {
    const parts = price.split('.')
    const dollars = parseInt(parts[0])
    const cents = parseInt(parts[1])
    if (isNaN(dollars) || isNaN(cents)) {
      throw new Error(`Price is not a number. Dollars: ${dollars}, cents: ${cents}`)
    }
    return 100 * dollars + cents
  } else {
    const dollars = parseInt(price)
    if (isNaN(dollars)) {
      throw new Error(`Price is not a number. Dollars: ${dollars}`)
    }
    return 100 * dollars
  }
}

export default priceToInt
