import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import quertString from 'query-string'
import { Link, NavLink, withRouter, Route } from 'react-router-dom'
import Swiper, { SwiperInstance } from 'react-id-swiper'
import { RouteComponentProps } from 'react-router'
import { showCart, hideCart } from '../../store/cart/actions'

import 'swiper/swiper.scss'
import './header.scss'

import { ExtensibleAttributes } from '../../types/extensible-attributes'
import Cart from '../cart/Cart'

import account from '../../assets/images/icons/account.svg'
import cart from '../../assets/images/icons/cart-white.svg'
import burgerMenu from '../../assets/images/icons/burger-menu.svg'
import cancel from '../../assets/images/cancel.svg'
import Account from './subsection/account/Account'
import { ReduxState } from '../../store/reducers'
import { UserType } from '../../store/account/profile/types'
import { IDepartment } from '../../store/departments/types'
import { decodeHtmlSymbols } from '../../utils/DecodeHtmlSymbols'
import MobileSearchInput from './subsection/search/MobileSearch'
import Menu from './subsection/menu/Menu'
import classNames from 'classnames'
import { setIsAsapDelivery } from '../../store/main/action-creators'

interface HeaderProps extends ExtensibleAttributes {
  userIsLoggedIn: boolean
  departments: IDepartment[]
  cartCount: number | null
  loginType?: string
  isAsapDelivery: boolean
  cartIsShown: boolean
  setIsAsapDelivery: (isLoggedIn: boolean) => void
  hideCart: () => void
  showCart: () => void
}

export enum AccountFormType {
  SignIn,
  SignUp,
  ResetPassword,
  VerifySmsCode
}

const Header: React.FC<HeaderProps & RouteComponentProps> = ({
  className = '',
  userIsLoggedIn,
  departments,
  cartIsShown,
  cartCount,
  location,
  history,
  loginType,
  showCart,
  hideCart,
}) => {
  const swiper = useRef<SwiperInstance>(null)
  const [accountShown, setAccountVisibility] = useState(false)
  const [menuShown, setMenuVisibility] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [showSingIn, setShowSignIn] = useState(AccountFormType.SignIn)
  const [logo, setLogo] = useState('')

  useEffect(() => {
    if (swiper.current && swiper.current.swiper) {
      swiper.current.swiper.update()
    }
  }, [swiper.current])

  useEffect(() => {
    if (location.pathname.match(/search/)) {
      const query = quertString.parse(location.search)
      if (typeof query.q === 'string') {
        setSearchString(query.q)
      }
    } else {
      setSearchString('')
    }

    if (location.pathname.match(/sign-up/)) {
      setShowSignIn(AccountFormType.SignUp)
      setAccountVisibility(true)
    }

    import('../../assets/images/cinch-logo.svg').then(module => {
      setLogo(module.default)
    })
  }, [location.pathname])

  const showAccount = () => setAccountVisibility(true)
  const hideAccount = () => {
    setShowSignIn(AccountFormType.SignIn)
    setAccountVisibility(false)
    if (location.pathname.match(/sign-up/)) {
      history.push('/')
    }

    if (loginType === 'Google') {
      window.location.reload()
    }
  }
  const showMenu = () => setMenuVisibility(!menuShown)
  const hideMenu = () => setMenuVisibility(false)

  const onSignUp = () => {
    setShowSignIn(AccountFormType.SignUp)
    showAccount()
  }

  const onResetPassword = () => {
    setShowSignIn(AccountFormType.ResetPassword)
  }

  const onSearch = (value: string) => {
    setSearchString(value)
    history.push(`/search?q=${value}`)
  }

  const swiperOptions = {
    spaceBetween: 15,
    slidesPerView: 'auto',
    grabCursor: true,
    navigation: {
      prevEl: '.header__navigation-prev',
      nextEl: '.header__navigation-next'
    },
    slidesOffsetBefore: 50,
    slidesOffsetAfter: 50,
    watchOverflow: true
  }

  const showDepartmentsSelector = location.pathname.match(/catalog/) || location.pathname.match(/news/)
  const query = quertString.parse(location.search)
  const activeDepartment = Number(query.department)
  const linkCatalog = useRef(null)

  return (
    <header className={`${className} header`}>
      <section className="header__info">
        <Link to="/" className="header__logo" title="Cinch Delivery">
          <img src={logo} alt="Cinch Delivery" />
        </Link>
        { /* <div className="header__link--dashboard"> */ }
        { /*  <button className={classNames('dashboard-btn', 'dashboard-btn-asap', { */ }
        { /*    'dashboard-btn-asap--active': isAsapDelivery */ }
        { /*  })} onClick={deliveryChange}> */ }
        { /*    Local store <span>ASAP</span> delivery */ }
        { /*  </button> */ }
        { /*  <button className={classNames('dashboard-btn', { */ }
        { /*    'dashboard-btn--active': !isAsapDelivery */ }
        { /*  })} onClick={deliveryChange}> */ }
        { /*    <span>White Glove</span> Big Box Delivery */ }
        { /*  </button> */ }
        { /* </div> */ }
        <a ref={linkCatalog} className={'header__link header__link--catalog'} onClick={showMenu}>
          <img className="header__link-image" src={!menuShown ? burgerMenu : cancel} alt="Menu" />
          <span className="header__link-text">Menu</span>
        </a>
        {userIsLoggedIn
          ? (<NavLink className="header__link header__link--account" to='/account/profile' >
            <img className="header__link-image" src={account} alt="Account" />
            <span className="header__link-text">Account</span>
            </NavLink>)
          : (<a className="header__link header__link--account" onClick={showAccount}>
            <img className="header__link-image" src={account} alt="Account" />
            <span className="header__link-text">{
              'Account'
            }</span>
            </a>)
        }
        <section className="header__search">
          <MobileSearchInput showSearch={onSearch} searchString={searchString} changeSearchString={setSearchString}/>
        </section>
        <span className={classNames('header__link header__link--cart', { 'header__link--disabled': !cartCount })} onClick={() => cartCount ? showCart() : false}>
          <img className="header__link-image" src={cart} alt="Cart" />
          <span className="header__link-text">Cart</span>
          {Boolean(cartCount) && <span className="header__indicator">{cartCount}</span>}
        </span>
        <Menu items = {departments} closeMenu={hideMenu} ignoreItems = {[linkCatalog]} opened = {menuShown}/>
        {accountShown && <Account close={hideAccount} showSignIn={showSingIn} onResetPassword={onResetPassword} />}
        {cartIsShown && <Cart close={hideCart} />}
      </section>
      <section className="header__mobile-search">
        <MobileSearchInput showSearch={onSearch} searchString={searchString} changeSearchString={setSearchString}/>
      </section>
      {showDepartmentsSelector
        ? (<section className="header__navigation">
          {
            departments.length > 0 && departments[0].title
              ? <Swiper ref={swiper} {...swiperOptions}>
                {departments.map(({ title, id }) => {
                  return (<NavLink
                    className={
                      `header__category-link ${activeDepartment === id ? 'selected' : ''}`
                    }
                    key={id}
                    to={{
                      pathname: '/catalog',
                      search: `department=${id}`
                    }}>{decodeHtmlSymbols(title)}</NavLink>)
                }
                )}
              </Swiper>
              : null
          }
        </section>)
        : null}
    </header>
  )
}

export default connect(
  (state: ReduxState) => {
    const itemsCount = state.cart.items.reduce((counter, { count }) => {
      return counter + count
    }, 0)
    let giftsWithItemsCount = itemsCount
    if (state.cart.benefits) {
      if (state.cart.benefits.discounts && state.cart.benefits.discounts.length > 0) {
        giftsWithItemsCount = state.cart.benefits.discounts.reduce((counter, { gift }) => {
          if (gift) {
            counter += gift.count
          }
          return counter
        }, giftsWithItemsCount)
      }
    }

    let departments = state.departments.departmentItems
    if (departments && departments.length) {
      departments = departments.filter(item => item.id !== 1).map(department => {
        return {
          ...department,
          ...department.categories && {
            categories: department.categories.filter(category => !category.default)
          }
        }
      })
    }

    const customer = state.account.profile.verifySmsCodeInformation

    return {
      cartIsShown: state.cart.cartIsShown,
      cartCount: giftsWithItemsCount || null,
      userIsLoggedIn: state.account.profile.userType === UserType.CustomerUserType,
      departments: departments,
      loginType: customer ? customer.type : undefined,
      isAsapDelivery: state.main.isAsapDelivery
    }
  },
  { setIsAsapDelivery, showCart, hideCart }
)(withRouter(Header))
