import { CLEAR_SEARCH_DATA, ISearchResultCategory, SEARCH_FETCHED, SEARCH_LOADING_STATE, SearchActions, SEARCH_MORE_ITEMS, SEARCH_PAGE, PRE_SEARCH_LOADING_STATE, PRE_SEARCH_FETCHED, IPreSearchState, PRE_SEARCH_VISIBLE, PRE_SEARCH_CLEAR } from './types'

const emptyState = {
  list: [],
  more_available: false,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  itemsPerPage: 20
}
const initialState: ISearchResultCategory & IPreSearchState = {
  products: {
    ...emptyState
  },
  categories: {
    ...emptyState
  },
  departments: {
    ...emptyState
  },
  stores: {
    ...emptyState
  },
  preSearchResult: [],
  preSearchLoading: false,
  preSearchVisible: false
}

export default function reduce (state = initialState, action: SearchActions) {
  switch (action.type) {
    case CLEAR_SEARCH_DATA:
      return { ...initialState }
    case PRE_SEARCH_LOADING_STATE:
      return {
        ...state,
        preSearchLoading: action.payload,
        preSearchVisible: true
      }
    case PRE_SEARCH_FETCHED:
      return {
        ...state,
        preSearchResult: action.payload
      }
    case PRE_SEARCH_VISIBLE:
      return {
        ...state,
        preSearchVisible: action.payload
      }
    case PRE_SEARCH_CLEAR:
      return {
        ...state,
        preSearchResult: [],
        preSearchLoading: false,
        preSearchVisible: false
      }
    case SEARCH_LOADING_STATE:
      return {
        ...state,
        loading: action.payload
      }
    case SEARCH_FETCHED:
      return { ...state, ...action.payload }
    case SEARCH_MORE_ITEMS: {
      const newItems = {
        list: [...state[(action.category as keyof Omit<typeof state, 'preSearchLoading' | 'preSearchVisible' | 'preSearchResult'>)].list, ...action.payload.list],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
        more_available: action.payload.more_available,
        itemsPerPage: 20
      }
      return {
        ...state,
        [action.category]: newItems
      }
    }
    case SEARCH_PAGE: {
      return {
        ...state,
        [action.category]: action.payload
      }
    }
    default:
      return state
  }
}
