import React, { useEffect, Suspense } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import cn from 'classnames'
import { ReactNotifications } from 'react-notifications-component'

import './app.scss'
import '../catalog/catalog.scss'
import '../../assets/styles/components/loader.scss'
import '../../assets/styles/components/content.scss'
import '../../assets/styles/components/coupon.scss'
import '../../assets/styles/components/form.scss'

import Header from '../header/Header'
import Main from '../main/Main'
import Footer from '../footer/Footer'
import InformationComponent, { InformationRoutes } from '../information/Information'
import PageContentContainer from './PageContentContainer'
import PreviouslyOrdered from '../previously-ordered/PreviouslyOrdered'
import Loader from '../loader/Loader'

import { fetchDepartments } from '../../store/departments/action-creators'
import { ReduxState } from '../../store/reducers'
import { UserType } from '../../store/account/profile/types'
import { fetchFavorites } from '../../store/favorites/actions'
import { getCart } from '../../store/cart/actions'
import { fetchAnnouncements } from '../../store/information/action-creators'
import { IAnnouncementItem } from '../../store/information/types'
import StandaloneAccount from '../account/StandaloneAccount'

const TipsAndTricks = React.lazy(() => import('../tips-and-tricks/TipsAndTricks'))
const SocialLinks = React.lazy(() => import('../social-links/SocialLinks'))
const CatalogContainer = React.lazy(() => import('../catalog/Catalog'))
const ProductContainer = React.lazy(() => import('../product/ProductContainer'))
const NewsItem = React.lazy(() => import('../news/NewsItem'))
const NewsListItem = React.lazy(() => import('../news/NewsListItem'))
const Stores = React.lazy(() => import('../store/Stores'))
const StoreItem = React.lazy(() => import('../store/StoreItem'))
const StoreItemProducts = React.lazy(() => import('../store/StoreItemProducts'))
const Account = React.lazy(() => import('../account/Account'))
const HeaderCarousel = React.lazy(() => import('../header-carousel/HeaderCarousel'))
const OrderFormContainer = React.lazy(() => import('../order/OrderFormContainer'))
const FinishResetPasswordForm = React.lazy(() => import('../reset-password/FinishResetPasswordForm'))
const ConnectedRecipeContainer = React.lazy(() => import('../recipes/RecipeContainer'))
const SearchResult = React.lazy(() => import('../header/subsection/search/SearchResults'))
const AppleSignIn = React.lazy(() => import('../oauth-sign-in/AppleSignIn'))
const UnauthorizedNotificationSettings = React.lazy(() => import('../notifications/UnauthorizedNotificationSettings'))

interface AppProps {
  fetchDepartments: (page: number, items: number) => void
  fetchFavorites: () => void;
  isCurrentUserCustomer: boolean
  getCart: () => void
  fetchAnnouncements: () => void
  announcementsItems: IAnnouncementItem[]
  userLocation?: string | null
}

const App: React.FC<AppProps> = ({
  fetchDepartments,
  fetchFavorites,
  isCurrentUserCustomer,
  getCart,
  fetchAnnouncements,
  announcementsItems
}) => {
  const location = useLocation()

  useEffect(() => {
    fetchDepartments(1, 10)
    fetchAnnouncements()
  }, [])

  useEffect(() => {
    if (isCurrentUserCustomer) {
      fetchFavorites()
      getCart()
    }
  }, [isCurrentUserCustomer])
  return (
    <Suspense fallback={<div className="centered"><Loader color='blue' /></div>}>
      {
        /* userLocation
          ? */<div className={cn('page', { 'not-main': location.pathname !== '/' })}>
              <Header className="page__header" />
              <StandaloneAccount />
              <Switch>
                <Route path="/" exact component={() => <HeaderCarousel layoutData = {announcementsItems}/>} />
              </Switch>
              <section className="page__main">
                <PageContentContainer>
                  <Switch>
                    <Route path="/" exact component={Main} />
                    <Route path="/sign-up" exact component={Main} />
                    <Route path="/catalog" exact component={CatalogContainer} />
                    <Route path="/previously-ordered" exact component={PreviouslyOrdered} />
                    <Route path="/catalog/:id" exact component={ProductContainer} />
                    <Route path="/catalog/:storeId/:id" exact component={ProductContainer} />
                    <Route path="/stores" exact component={Stores}/>
                    <Route path="/store/:id" exact component={StoreItem}/>
                    <Route path="/store/:id/:department" exact component={StoreItemProducts}/>
                    <Route path="/recipes" component={ConnectedRecipeContainer} />
                    <Route path="/news" exact component={NewsListItem} >
                      <Redirect to="/" />
                    </Route>
                    <Route path="/search" exact component={SearchResult}/>
                    <Route path="/news/:id" exact component={NewsItem} />
                    <Route path="/tips-and-tricks" component={TipsAndTricks} />
                    <Route path="/account" component={Account} />
                    <Route path="/order" component={OrderFormContainer} />
                    <Route path={InformationRoutes.aboutUs} component={InformationComponent} />
                    <Route path={InformationRoutes.deliveryInfo} component={InformationComponent} />
                    <Route path={InformationRoutes.privacyPolicy} component={InformationComponent} />
                    <Route path={InformationRoutes.termsAndCons} component={InformationComponent} />
                    <Route path='/reset-password/:token' component={FinishResetPasswordForm} />
                    <Route path='/apple-callback' component={AppleSignIn} />
                    <Route path='/notification-settings/:token' component={UnauthorizedNotificationSettings} />
                    <Route>
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </PageContentContainer>
              </section>
              <ReactNotifications/>
        <Footer className="page__footer" />
          <section className="page__left-margin">
            <a className="page__scroll-up" href="#">Scroll Up</a>
          </section>
          <section className="page__right-margin">
            <SocialLinks className="menu--vertical menu--sticky" />
          </section>
        </div>
          // : <>
          //     <UserLocationGetter />
          //     <ReactNotifications/>
          //   </>
      }
    </Suspense>
  )
}

export default connect(
  (state: ReduxState) => ({
    isCurrentUserCustomer: state.account.profile.userType === UserType.CustomerUserType,
    announcementsItems: state.information.announcements.list,
    userLocation: state.account.profile.userLocation
  }),
  { fetchDepartments, fetchFavorites, getCart, fetchAnnouncements }
)(App)
