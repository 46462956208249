import { IDepartments, DEPARTMENTS_FETCHED, DepartmentsActions } from './types'
import { makeLoadersArray } from '../../utils/LoadersArray'

const initialState = {
  departmentItems: makeLoadersArray(10, 'departments'),
  categories: [],
  totalPages: 1,
  totalItems: 0,
  itemsPerPage: 20,
  currentPage: 1
}

export default function reduce (state: IDepartments = initialState, action: DepartmentsActions) {
  switch (action.type) {
    case DEPARTMENTS_FETCHED:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
