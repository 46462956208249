import React from 'react'
import classnames from 'classnames'

interface LoaderProps {
  color?: 'orange' | 'red' | 'blue',
  className?: string
}

const Loader = ({ color, className }: LoaderProps) => (
  <div className={classnames(['loader', color && `loader_${color}`, className])}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default Loader
