import { ThunkDispatch } from 'redux-thunk'
import { CatalogService } from '../../../services/CatalogService'
import { CatalogItem } from '../../catalog/types'
import {
  ACCOUNT_FAVORITES_ALSO_LIKE_FETCHED,
  ACCOUNT_FAVORITES_ITEMS_FETCHED,
  ACCOUNT_FAVORITES_RECENTLY_ORDERED_FETCHED
} from './types'
import { FavoritesService } from '../../../services/FavoritesService'
import { OrdersService } from '../../../services/OrdersService'
import { IOrderFullData } from '../../order/types'

export function fetchFavoritesPage () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    FavoritesService.get().then(catalog => {
      dispatch({
        type: ACCOUNT_FAVORITES_ITEMS_FETCHED,
        payload: catalog as CatalogItem[]
      })
    })
    OrdersService.getOrders().then(orders => {
      const products: CatalogItem[] = []
      if (orders.list && orders.list.length > 0) {
        orders.list.forEach((order: IOrderFullData) => {
          if (order.products && order.products.length > 0) {
            order.products.forEach((product) => {
              products.push(product.product)
            })
          }
        })
      }
      const uniqueIds: string[] = []
      dispatch({
        type: ACCOUNT_FAVORITES_RECENTLY_ORDERED_FETCHED,
        payload: products.filter(item => {
          if (uniqueIds.indexOf(item.id) === -1) {
            uniqueIds.push(item.id)
            return true
          } else {
            return false
          }
        })
      })
    })
    CatalogService.getItems({ page: 1, items: 20 }).then(catalog => {
      dispatch({
        type: ACCOUNT_FAVORITES_ALSO_LIKE_FETCHED,
        payload: catalog.list as CatalogItem[]
      })
    })
  }
}

export function fetchFavorites () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    FavoritesService.get().then(catalog => {
      dispatch({
        type: ACCOUNT_FAVORITES_ITEMS_FETCHED,
        payload: catalog as CatalogItem[]
      })
    })
  }
}
