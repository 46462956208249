import Cookies from 'js-cookie'
import { ApiService, ApiServiceClass } from './ApiService'
import { AddressData } from '../store/order/types'

export class GuestsServiceClass {
  apiService: ApiServiceClass;

  constructor (apiService: ApiServiceClass) {
    this.apiService = apiService
  }

  get guestId (): string | undefined {
    return Cookies.get('guest:id')
  }

  set guestId (id: string | undefined) {
    if (id) {
      Cookies.set('guest:id', id, {
        expires: 365,
        secure: true,
        sameSite: 'none'
      })
    } else {
      Cookies.remove('guest:id')
    }
  }

  get token (): string | undefined {
    return Cookies.get('guest:token')
  }

  set token (value: string | undefined) {
    if (value) {
      Cookies.set('guest:token', value, {
        expires: 365,
        secure: true,
        sameSite: 'none'
      })
    } else {
      Cookies.remove('guest:token')
    }
  }

  addAddress (address: AddressData) {
    return this.apiService.post(`/guests/${this.guestId}/addresses`, {
      title: address.title,
      label: address.title,
      line1: address.line1,
      line2: address.line2,
      zip: address.zip,
      city: address.city,
      state: address.state,
      country: 'N/A',
      notes: address.notes
    })
  }

  setPreferences ({ preferred_mode }: {
    preferred_mode: string
  }) {
    return this.apiService.post(`/guests/${this.guestId}/setPreferences`, {
      preferences: {
        delivery: {
          preferred_mode
        }
      }
    })
  }
}

export const GuestService = new GuestsServiceClass(ApiService)
