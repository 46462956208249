import React from 'react'
import { IAnnouncementItem } from '../../store/information/types'
import classnames from 'classnames'
import noPhoto from '../../assets/images/no-photo.svg'
import Loader from '../loader/Loader'

const AnnouncementItem: React.FC<IAnnouncementItem> = ({ id, images, header, description, redirect_url, loading, layout }) => {
  return (
    loading
      ? <div key={id} className="catalog__announcement loading">
          <div className="catalog__announcement-image">
            <Loader color="blue" className='product-item'/>
          </div>
        </div>
      : layout === 0
        ? <div
          className="catalog__announcement" key={`${id}`}
        >
          <span className="catalog__announcement-header" style={header ? { color: `${header.color}` } : undefined}>{header ? header.text : ''}</span>
          <span className="catalog__announcement-discription" style={description ? { color: `${description.color}` } : undefined}>{description ? description.text : ''}</span>
          <a href={redirect_url ? `${redirect_url}` : '#'} className="catalog__announcement-button"><span>Details</span></a>
          <div className="catalog__announcement-image" style={{
            backgroundImage: `url(${images && images[0] ? images[0].url : noPhoto})`
          }}></div>
        </div>
        : <a className={classnames('catalog__announcement', { filled: layout === 2 })} href={redirect_url ? `${redirect_url}` : '#'} key={`${id}`} style={{
          backgroundImage: `url(${images && images[0] ? images[0].url : noPhoto})`
        }}>
        </a>

  )
}

export default AnnouncementItem
