import { CatalogItem } from '../catalog/types'

export const GET_ADDRESSES = 'GET_ADDRESSES'
export const ADD_ADDRESS = 'ADD_ADDRESS'
export const EDIT_ADDRESS = 'EDIT_ADDRESS'
export const GET_COUPON_START = 'GET_COUPON_START'
export const ORDER_CREATED = 'ORDER_CREATED'
export const COUPON_AVAILABILITY = 'COUPON_AVAILABILITY'
export const CLEAR_COUPON_AVAILABILITY_DATA = 'CLEAR_COUPON_AVAILABILITY_DATA'
export const CLEAR_ORDER_DATA = 'CLEAR_ORDER_DATA'
export const CLEAR_COUPON_DATA = 'CLEAR_COUPON_DATA'
export const GET_COUPON = 'GET_COUPON'

interface GetAddressesAction {
  type: typeof GET_ADDRESSES,
  payload: any
}

export interface ICouponProduct {
  id: number,
  name: string,
  model: string,
  description: string,
  sale_price: number,
  real_sale_price: number
  images: {
    url: string
  }[]
}

export interface ICoupon {
  id: number,
  code: string,
  title: string,
  details: string,
  date_start: string,
  date_end: string,
  cash_discount: number,
  percent_discount: number,
  gift_products: {
    count: number,
    product: ICouponProduct
  }[],
  minimal_order_total?: number
}

interface GetCouponAction {
  type: typeof GET_COUPON,
  payload: ICoupon | null
}

interface GetCouponStartAction {
  type: typeof GET_COUPON_START
}

export interface AddressData {
  id?: number
  title: string
  line1: string
  line2: string
  city: string
  state: string
  zip: string
  notes: string
}

interface NewAddressData extends AddressData {
  id: number
}

export interface OrderData {
  nonce: string
  driverInstructions: string
  deliverySchedule: string
  recurringType: number
  addressId: number
  storeId?: number
}

interface AddAddressAction {
  type: typeof ADD_ADDRESS,
  payload: NewAddressData
}

interface EditAddressAction {
  type: typeof EDIT_ADDRESS,
  payload: NewAddressData
}

export interface IOrder {
  list: any[]
  paging_header: any
  couponLoading: boolean
  coupon: ICoupon | null
  isCouponAvailable: boolean | null
}

export interface IOrderProductData {
  count: number
  product: CatalogItem
}

export enum OrderStatus {
  Pending = 0,
  Processing = 1,
  Delivery = 2,
  Completed = 3,
  CanceledByCustomer = 4,
  CanceledByCinch = 5,
  Refunded = 6
}

export interface IOrderStatus {
  status: OrderStatus,
  date_created: string
}

export interface IOrderFullData {
  id: number
  date_created: string
  date_updated: string
  products: IOrderProductData[]
  coupons: ICoupon[]
  driver_instructions: null | string
  customer_id: number
  gifts: CatalogItem[]
  payments: any[]
  order_statuses: IOrderStatus[]
  rate: number
  total: number
  subtotal: number
  tax: number
  bonuses_used: number
  saved_benefits: number
}

export const activeOrderStatuses = [
  OrderStatus.Pending,
  OrderStatus.Processing,
  OrderStatus.Delivery
]

export interface INewOrderData {
  nonce: string
  driverInstructions: string
  deliverySchedule: string
  recurringType: number
  addressId: number
  storeId?: number
}

interface OrderCreatedAction {
  type: typeof ORDER_CREATED
  payload: number
}

interface CouponAvailability {
  type: typeof COUPON_AVAILABILITY,
  payload: {
    available: boolean
  }
}

interface ClearCouponData {
  type: typeof CLEAR_COUPON_DATA
}

interface ClearCouponAvailabilityData {
  type: typeof CLEAR_COUPON_AVAILABILITY_DATA
}

interface ClearOrderData {
  type: typeof CLEAR_ORDER_DATA
}

export type OrderActions = GetAddressesAction | AddAddressAction | EditAddressAction | GetCouponAction | GetCouponStartAction | OrderCreatedAction | ClearOrderData | CouponAvailability | ClearCouponAvailabilityData | ClearCouponData
