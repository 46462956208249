import {
  FEATURED_ITEMS_FETCHED,
  MainPage,
  MainPageActions,
  NEW_ARRIVALS_ITEMS_FETCHED,
  SUGGESTS_ITEMS_FETCHED,
  ORDER_AGAIN_FETCHED, SET_IS_ASAP_DELIVERY, CLEAR_MAIN_STATE
} from './types'
import { makeLoadersArray } from '../../utils/LoadersArray'
import { getIsAsapDelivery } from '../../utils/GetIsAsapDelivery'

const initialState = {
  featured: makeLoadersArray(6, 'featured'),
  featuredLoad: true,
  newArrivals: makeLoadersArray(6, 'newArrivals'),
  newArrivalsLoad: true,
  suggests: makeLoadersArray(6, 'suggests'),
  suggestsLoad: true,
  orderAgain: makeLoadersArray(6, 'orderAgain'),
  orderAgainLoad: true,
  isAsapDelivery: getIsAsapDelivery()
}

export default function reduce (state: MainPage = initialState, action: MainPageActions) {
  switch (action.type) {
    case FEATURED_ITEMS_FETCHED:
      return {
        ...state,
        featured: action.payload,
        featuredLoad: false
      }
    case SUGGESTS_ITEMS_FETCHED:
      return {
        ...state,
        suggests: action.payload,
        suggestsLoad: false
      }
    case NEW_ARRIVALS_ITEMS_FETCHED:
      return {
        ...state,
        newArrivals: action.payload,
        newArrivalsLoad: false
      }
    case ORDER_AGAIN_FETCHED:
      return {
        ...state,
        orderAgain: action.payload,
        orderAgainLoad: false
      }
    case SET_IS_ASAP_DELIVERY:
      return {
        ...state,
        isAsapDelivery: action.payload
      }
    case CLEAR_MAIN_STATE:
      return { ...initialState }
    default:
      return state
  }
}
