import { IPaginator } from '../../types/paginator'
import { CatalogItem, ICatalogItemPrices } from '../catalog/types'
import { IAddress } from '../customers/types'
import { IDepartment } from '../departments/types'

export interface IStore {
  id: string
  name: string
  address: IAddress
}

export const STORE_FETCHED = 'STORE_FETCHED'
export interface StoreFetched {
  type: typeof STORE_FETCHED
  payload: IStore
}

export const STORE_DEPARTMENTS_FETCHED = 'STORE_DEPARTMENTS_FETCHED'
export interface StoreDepartmentsFetched {
  type: typeof STORE_DEPARTMENTS_FETCHED
  payload: IDepartment[]
}

export const STORE_DEPARTMENT_FETCHED = 'STORE_DEPARTMENT_FETCHED'
export interface StoreDepartmentFetched {
  type: typeof STORE_DEPARTMENT_FETCHED
  payload: IDepartment
}

export const STORE_DEPARTMENT_PRODUCTS_FETCHED = 'STORE_DEPARTMENT_PRODUCTS_FETCHED'
export interface StoreDepartmentProductFetched {
  type: typeof STORE_DEPARTMENT_PRODUCTS_FETCHED
  payload: {
    list: CatalogItem[],
    paging_header: IPaginator
  }
}
export const STORE_DEPARTMENT_PRODUCTS_FETCHED_MORE = 'STORE_DEPARTMENT_PRODUCTS_FETCHED_MORE'
export interface StoreDepartmentProductFetchedMore {
  type: typeof STORE_DEPARTMENT_PRODUCTS_FETCHED_MORE
  payload: {
    list: ICatalogItemPrices[],
    paging_header: IPaginator
  }
}

export const STORE_FEATURED_FETCHED = 'STORE_FEATURED_FETCHED'
export interface StoreFeaturedFetched {
  type: typeof STORE_FEATURED_FETCHED
  payload: CatalogItem[]
}

export const STORE_NEW_ARRIVALS_FETCHED = 'STORE_NEW_ARRIVALS_FETCHED'
export interface StoreNewArrivalsFetched {
  type: typeof STORE_NEW_ARRIVALS_FETCHED
  payload: CatalogItem[]
}

export const STORE_CLEAR_STATE = 'STORE_CLEAR_STATE'
export interface StoreClearState {
  type: typeof STORE_CLEAR_STATE
}

export const STORE_DEPARTMENT_CLEAR_STATE = 'STORE_DEPARTMENT_CLEAR_STATE'
export interface StoreDepartmentClearState {
  type: typeof STORE_DEPARTMENT_CLEAR_STATE
}

export interface IStoreState {
  store: IStore | null,
  products: CatalogItem[],
  arrivals: CatalogItem[],
  departments: IDepartment[]
  department: IDepartment | null
  departmentProducts: CatalogItem[]
  pagingHeader: IPaginator | null
}

export type StoreActions = StoreFetched | StoreDepartmentsFetched | StoreDepartmentFetched | StoreDepartmentProductFetched | StoreFeaturedFetched | StoreNewArrivalsFetched | StoreClearState | StoreDepartmentClearState | StoreDepartmentProductFetchedMore
