import { CatalogItem } from '../../catalog/types'

export const ACCOUNT_FAVORITES_ITEMS_FETCHED = 'ACCOUNT_FAVORITES_ITEMS_FETCHED'
export const ACCOUNT_FAVORITES_RECENTLY_ORDERED_FETCHED = 'ACCOUNT_FAVORITES_RECENTLY_ORDERED_FETCHED'
export const ACCOUNT_FAVORITES_ALSO_LIKE_FETCHED = 'ACCOUNT_FAVORITES_ALSO_LIKE_FETCHED'

interface AccountFavoritesItemsFetched {
  type: typeof ACCOUNT_FAVORITES_ITEMS_FETCHED,
  payload: CatalogItem[]
}

interface AccountFavoritesRecentlyOrderedFetched {
  type: typeof ACCOUNT_FAVORITES_RECENTLY_ORDERED_FETCHED,
  payload: CatalogItem[]
}

interface AccountFavoritesAlsoLikeFetched {
  type: typeof ACCOUNT_FAVORITES_ALSO_LIKE_FETCHED,
  payload: CatalogItem[]
}

export type AccountFavoritesActions = AccountFavoritesItemsFetched
  | AccountFavoritesRecentlyOrderedFetched
  | AccountFavoritesAlsoLikeFetched

export interface AccountFavoritesPage {
  favorites: CatalogItem[],
  recentlyOrdered: CatalogItem[],
  alsoLike: CatalogItem[]
}
