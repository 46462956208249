import { FavoritesService } from './FavoritesService'
import { OrdersService } from './OrdersService'
import { activeOrderStatuses } from '../store/order/types'
import { CatalogItem } from '../store/catalog/types'
import { BonusService } from './BonusService'

export class DashboardServiceClass {
  async getData () {
    const [
      favorites,
      orders,
      bonuses
    ] = await Promise.all([
      FavoritesService.get(),
      OrdersService.getOrders().then(({ list }) => list),
      BonusService.get()
    ])
    const favoritesItems = await Promise.all(
      favorites.map(async (product: CatalogItem) => {
        const {
          id, name, images: [{ url: image = null } = {}] = []
        } = product
        return { id, image, title: name }
      })
    )
    const activeOrders = orders.filter(({ status }: { status: number }) => activeOrderStatuses.includes(status))
    const [latestOrder] = activeOrders

    // const favorites = []
    // const favoritesItems: any[] = []
    // const activeOrders = []
    // const orders = []
    // const latestOrder = null

    return {
      favorites: {
        count: favorites.length,
        items: favoritesItems
      },
      orders: {
        count: {
          active: activeOrders.length,
          total: orders.length
        }
      },
      latestOrder,
      ...bonuses
    }
  }
}

export const DashboardService = new DashboardServiceClass()
