import { ReduxState } from '../reducers'
import { CatalogService } from '../../services/CatalogService'
import { CustomersService } from '../../services/CustomersService'
import { ThunkDispatch } from 'redux-thunk'
import { Catalog } from '../catalog/types'
import { UserType } from '../../store/account/profile/types'
import {
  FEATURED_ITEMS_FETCHED,
  SUGGESTS_ITEMS_FETCHED,
  ORDER_AGAIN_FETCHED,
  SET_IS_ASAP_DELIVERY,
  CLEAR_MAIN_STATE
} from './types'
import { GuestService } from '../../services/GuestsService'

export function fetchPageItems () {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()
    CatalogService.getFeatured({ page: 1, items: 100 })
      .then(catalog => {
        dispatch({
          type: FEATURED_ITEMS_FETCHED,
          payload: catalog.list as Catalog
        })
      }).catch(() => {
        dispatch({
          type: FEATURED_ITEMS_FETCHED,
          payload: []
        })
      })
    if (state.account.profile.userType === UserType.CustomerUserType) {
      CatalogService.getSuggestions()
        .then(catalog => {
          dispatch({
            type: SUGGESTS_ITEMS_FETCHED,
            payload: catalog.list as Catalog
          })
        })
        .catch(() => {
          dispatch({
            type: SUGGESTS_ITEMS_FETCHED,
            payload: []
          })
        })
    }
    if (state.account.profile.userType === UserType.CustomerUserType) {
      CustomersService.getOrderAgain()
        .then(catalog => {
          dispatch({
            type: ORDER_AGAIN_FETCHED,
            payload: catalog.list as Catalog
          })
        })
        .catch(() => {
          dispatch({
            type: ORDER_AGAIN_FETCHED,
            payload: []
          })
        })
    }
  }
}

export function setIsAsapDelivery (isLogged: boolean) {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ReduxState) => {
    const state = getState()
    dispatch({
      type: SET_IS_ASAP_DELIVERY,
      payload: !state.main.isAsapDelivery
    })

    if (isLogged) {
      await CustomersService.setPreferences({
        notifications: state.account.profile.profile.preferences.notifications,
        customerId: state.account.profile.profile.id,
        preferred_mode: state.main.isAsapDelivery ? 'internal' : 'asap'
      })
    } else {
      await GuestService.setPreferences({
        preferred_mode: state.main.isAsapDelivery ? 'internal' : 'asap'
      })
    }

    window.location.assign('/')
  }
}

export function clearState () {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: CLEAR_MAIN_STATE
    })
  }
}
