export default function formatDate (dateTime: Date | string): string {
  try {
    const dtFormat = new Intl.DateTimeFormat()
    return dtFormat.format(
      dateTime instanceof Date
        ? dateTime
        : new Date(dateTime)
    )
  } catch (e) {
    console.log('Error parsing time: ' + e)
    return 'Invalid date'
  }
}
