import { AxiosError } from 'axios'
import Cookies from 'js-cookie'

const listOfBusinessLogicErrors = [
  'current_validation_pending',
  'login_invalid_credentials_error'
]

function checkErrors (e: any) {
  let hasErrors = false
  for (const error in e.response.data.errors) {
    if (listOfBusinessLogicErrors.includes(error && error.code)) {
      hasErrors = true
      break
    }
  }

  if ((e.response.config.url as string).includes('customers/login') || (e.response.config.url as string).includes('customers/tokenLogin')) {
    hasErrors = true
  }

  return hasErrors
}

export class HttpErrorServiceClass {
  handleResponse (e: AxiosError) {
    if (e.response && e.response.status === 401 && e.response.data && !checkErrors(e)) {
      console.assert(e)
      Cookies.remove('customer:token')
      Cookies.remove('customer:id')
      Cookies.remove('guest:token')
      Cookies.remove('guest:id')
      window.location.reload()
    } else {
      console.assert(e)
    }
  }
}

export const HttpErrorService = new HttpErrorServiceClass()
