import React, { useState, useRef, useEffect } from 'react'
import Swiper, { SwiperInstance } from 'react-id-swiper'
import cn from 'classnames'
import 'swiper/swiper.scss'

import AnnouncementItem from '../catalog-item/AnnouncementItem'

import { IAnnouncementItem } from '../../store/information/types'
import { makeLoadersArray } from '../../utils/LoadersArray'

interface IAnnouncementsCarouselProps {
  items: IAnnouncementItem[]
  id: string
  load: boolean
}

const AnnouncementsCarousel: React.FC<IAnnouncementsCarouselProps> = ({ items = [], load = false }) => {
  const swiper = useRef<SwiperInstance>(null)
  const [prevDisable, setPrevDisable] = useState(true)
  const [nextDisable, setNextDisable] = useState(true)

  const loaderArray = makeLoadersArray(4, 'temp')

  const goNext = () => {
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slidePrev()
    }
  }

  const swiperOptions = {
    spaceBetween: 36,
    slidesPerView: 4,
    breakpoints: {
      300: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      900: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1150: {
        slidesPerView: 4,
        spaceBetween: 36
      }
    },
    on: {
      slideChange: () => {
        setPrevDisable(swiper.current.swiper.isBeginning)
        setNextDisable(swiper.current.swiper.isEnd)
      },
      init: () => {
        setPrevDisable(swiper.current.swiper.isBeginning)
        setNextDisable(swiper.current.swiper.isEnd)
      }
    }
  }

  useEffect(() => {
    if (swiper.current && swiper.current.swiper) {
      swiper.current.swiper.update()
    }
  }, [swiper.current])

  if (!items.length) {
    return null
  }

  return (
    <section className={'carousel__announcements'}>
      <button className={cn('carousel__prev', { hide: prevDisable })} onClick={goPrev}></button>
      <div className="carousel__announcements__body">
        {load
          ? <div className="carousel__announcements__body-load">
              {loaderArray.map(item => <div key={item.id}><AnnouncementItem {...item} /></div>)}
            </div>
          : <Swiper ref={swiper} { ...swiperOptions }>
              { items.filter(item => item.layout !== 1 || item.loading).map(item => <div key={item.id}><AnnouncementItem { ...item }/></div>) }
            </Swiper>
        }
      </div>
      <button className={cn('carousel__next', { hide: nextDisable })} onClick={goNext}></button>
    </section>
  )
}

export default AnnouncementsCarousel
