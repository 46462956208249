import { ApiService, ApiServiceClass } from './ApiService'
import { ICatalogFetchProps } from '../store/catalog/types'

export class CatalogServiceClass {
  apiService: ApiServiceClass;

  constructor (apiService: ApiServiceClass) {
    this.apiService = apiService
  }

  async getItems ({ page = 1, items = 20, category, department, previouslyOrdered, store }: ICatalogFetchProps) {
    const data = {
      filtering: {
        ...category && { category_ids: [category] },
        ...department && { department_ids: [department] },
        ...previouslyOrdered && { previously_ordered: previouslyOrdered },
        ...store && { store_ids: [store] }
      },
      sorting: [{
        name: 'is_promoted',
        ascending: false
      }]
    }
    const res = await this.apiService.post(`/catalog/products/public/list/${page}/${items}`, data)
    return res
  }

  async getNewArrivals ({ page = 1, items = 20 }) {
    const res = await this.apiService.post(`/catalog/products/newArrivals/list/${page}/${items}`)
    return res
  }

  async getNewArrivalsByStore ({ page = 1, items = 20, storeId = '' } = {}) {
    const res = await this.apiService.post(`/catalog/products/newArrivals/list/${page}/${items}`, storeId
      ? {
          filtering: {
            store_ids: [storeId]
          }
        }
      : null)
    return res
  }

  async getFeatured ({ page = 1, items = 20 } = {}) {
    const res = await this.apiService.post(`/catalog/products/featured/list/${page}/${items}`)
    return res
  }

  async getFeaturedByStore ({ page = 1, items = 20, storeId = '' } = {}) {
    const res = await this.apiService.post(`/catalog/products/featured/list/${page}/${items}`, storeId
      ? {
          filtering: {
            store_ids: [storeId]
          }
        }
      : null)
    return res
  }

  async getSuggestions ({ page = 1, items = 20 } = {}) {
    const res = await this.apiService.post(`/catalog/products/suggestions/list/${page}/${items}`)
    return res
  }

  async getProduct (id: string) {
    const product = this.apiService.get(`/catalog/products/public/${id}`)
    return product
  }

  async getStore (id: string) {
    const store = this.apiService.get(`/catalog/stores/${id}`)
    return store
  }

  async getStores (id?: number) {
    const stores = this.apiService.post('/catalog/stores/list/1/100', {
      ...id && { department_ids: [id] }
    })
    return stores
  }

  async getDeliveryInfo (address: object[], store_ids: number[]) {
    const stores = this.apiService.post('/catalog/stores/requestQuote', {
      waypoints: address,
      store_ids
    })
    return stores
  }
}

export const CatalogService = new CatalogServiceClass(ApiService)
