import React, { useEffect } from 'react'

import CatalogCarousel from '../catalog-carousel/CatalogCarousel'
import AnnouncementsCarousel from '../catalog-carousel/AnnouncementsCarousel'
import Departments from '../departments/Departments'
import { ExtensibleReduxAttributes } from '../../types/extensible-attributes'

import './main.scss'
import * as catalogTypes from '../../store/catalog/types'
import { clearState, fetchPageItems } from '../../store/main/action-creators'
import { UserType } from '../../store/account/profile/types'
import { ReduxState } from '../../store/reducers'
import { connect } from 'react-redux'
import { fetchDepartments } from '../../store/departments/action-creators'
import { IDepartment } from '../../store/departments/types'
import { IAnnouncementItem } from '../../store/information/types'
import { INewsItem } from '../../store/news/types'

interface MainProps extends ExtensibleReduxAttributes {
  featured: catalogTypes.CatalogItem[]
  featuredLoad: boolean
  newArrivals: catalogTypes.CatalogItem[]
  newArrivalsLoad: boolean
  suggests: catalogTypes.CatalogItem[]
  suggestsLoad: boolean
  departments: IDepartment[]
  news: INewsItem[]
  announcements: IAnnouncementItem[]
  announcementsListLoad: boolean
  orderAgain: catalogTypes.CatalogItem[]
  orderAgainLoad?: boolean
  isCustomer: boolean
}

const Main: React.FC<MainProps> = (props) => {
  useEffect(() => {
    props.dispatch(fetchPageItems())
    props.dispatch(fetchDepartments(1, 50))

    return () => {
      props.dispatch(clearState())
    }
  }, [])

  return (
    <div className="content__center">
      {props.isCustomer ? <CatalogCarousel title="Order Again" items={props.orderAgain} id="orderAgainCarousel" load={props.orderAgainLoad}/> : null}
      <AnnouncementsCarousel items={props.announcements} load={props.announcementsListLoad} id="announcementCarousel"/>
      <CatalogCarousel title="Special offers" items={props.featured} id="specialOffersCarousel" load={props.featuredLoad}/>
      {props.isCustomer ? <CatalogCarousel title="Suggested Products" items={props.suggests} id="suggestedProductsCarousel" load={props.suggestsLoad}/> : null}
      <Departments items={props.departments} />
    </div>
  )
}

const mapStateToProps = function (state: ReduxState) {
  const items = [...state.main.featured, ...state.main.newArrivals, ...state.main.suggests, ...state.main.orderAgain]
  const favorites = state.favorites.favorites
  const countInCard = state.cart.items
  const categories = state.departments.categories

  for (let i = 0; i < items.length; i++) {
    items[i].isFavorites = false
    if (items[i].category_ids) {
      items[i].category = categories.filter(item => item.id === items[i].category_ids[0])
    }

    items[i].cardCount = 0
    items[i].isCustomer = state.account.profile.userType === UserType.CustomerUserType
    for (let c = 0; c < countInCard.length; c++) {
      if (items[i].id === countInCard[c].product.id) {
        items[i].cardCount = countInCard[c].count
      }
    }

    for (let j = 0; j < favorites.length; j++) {
      if (items[i].id === favorites[j].id) {
        items[i].isFavorites = true
      }
    }
  }
  return {
    featured: state.main.featured,
    featuredLoad: state.main.featuredLoad,
    suggests: state.main.suggests,
    suggestsLoad: state.main.suggestsLoad,
    newArrivals: state.main.newArrivals,
    newArrivalsLoad: state.main.newArrivalsLoad,
    orderAgain: state.main.orderAgain,
    orderAgainLoad: state.main.orderAgainLoad,
    departments: state.departments.departmentItems,
    news: state.news.list,
    favorites: state.favorites.favorites,
    cart: state.cart.items,
    announcements: state.information.announcements.list,
    announcementsListLoad: state.information.listLoad,
    isCustomer: state.account.profile.userType === UserType.CustomerUserType
  }
}
export default connect(mapStateToProps)(Main)
