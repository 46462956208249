import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { InformationRoutes } from '../information/Information'

const getCustomClassName = (pathName: string) => {
  if (pathName.includes('/catalog')) {
    return 'page__catalog'
  } else if (pathName.includes('/news')) {
    return 'page__news'
  } else if (pathName.includes('/tips-and-tricks')) {
    return 'page__tips'
  } else if (pathName.includes('/account')) {
    return 'page__account'
  } else if (pathName.includes('/reset-password')) {
    return 'page__reset-password'
  } else if (pathName.includes(InformationRoutes.aboutUs) || pathName.includes(InformationRoutes.deliveryInfo) ||
    pathName.includes(InformationRoutes.privacyPolicy) || pathName.includes(InformationRoutes.termsAndCons)) {
    return 'page__info'
  } else {
    return 'page__main'
  }
}

const PageContentContainer: React.FunctionComponent<RouteComponentProps> = ({ children, location }) => {
  return (
    <main className={`page__content content ${getCustomClassName(location.pathname)}`}>
        {children}
    </main>
  )
}

export default withRouter(PageContentContainer)
