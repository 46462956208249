import React, { useState } from 'react'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'

import noPhoto from '../../../../assets/images/no-photo.svg'
import chevron from '../../../../assets/images/chevron-right.svg'
import { IDepartmentProps } from './Menu'
import { decodeHtmlSymbols } from '../../../../utils/DecodeHtmlSymbols'
import Loader from '../../../loader/Loader'

interface CardProps {
  items?: IDepartmentProps[];
  close: () => void
}

const Accordion: React.FunctionComponent<CardProps> = ({
  items,
  close
}) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([])
  return (
    <>
      {
        items && items.length
          ? items.map(item => {
            const expanded = expandedRows.includes(item.id)
            return (item.loading
              ? <div key={item.id} className="accordion-item">
              <div className="accordion-item__header">
                <Loader className="accordion-item__header-image" color="blue"/>
                <div className="catalog__item-placeholders accordion">
                  <div></div>
                </div>
              </div>
            </div>
              : <div key={item.id} className="accordion-item">
              <div className="accordion-item__header" onClick={() => {
                if (expanded) {
                  const rows = expandedRows.filter(id => id !== item.id)
                  setExpandedRows(rows)
                } else {
                  const rows = [...expandedRows, item.id]
                  setExpandedRows(rows)
                }
              }}>
                <img className="accordion-item__header-image" alt={item.title} src={item.image ? item.image.url : noPhoto}/>
                <div className="accordion-item__header-text">{decodeHtmlSymbols(item.title)}</div>
                <div className="accordion-item__header-arrow"><img className={classnames({
                  expanded
                })} src={chevron} alt="Open categories"/>
                </div>
              </div>
              {item.categories && item.categories.length
                ? <div className={classnames('accordion-item__body', { expanded })}>
                    {
                      item.categories.map(child => (
                        <NavLink to={{
                          pathname: '/catalog',
                          search: `department=${item.id}&category=${child.id}`
                        }} onClick={close} className="accordion-item__body-item" key={child.id}>{decodeHtmlSymbols(child.title)}</NavLink>
                      ))
                    }
                  </div>
                : null
              }
            </div>)
          })
          : null
      }
    </>
  )
}

export default Accordion
