import React, { useEffect, SyntheticEvent } from 'react'

import './subsection.scss'

export interface SubsectionProps {
  close: (e: SyntheticEvent) => void
  hideClose?: boolean
  className?: string
}

const Subsection: React.FunctionComponent<SubsectionProps> = ({ close, children, hideClose, className }) => {
  useEffect(() => {
    const el = document.querySelector('body')
    if (el) {
      el.classList.add('no-scroll')
    }
    return () => {
      const el = document.querySelector('body')
      if (el) {
        el.classList.remove('no-scroll')
      }
    }
  }, [])

  return (
    <div className="menu__subsection-container">
      <div className="menu__subsection-container-outer" onClick={close}></div>
      <div className={`menu__subsection${className ? ` ${className}` : ''}`}>
        { !hideClose && <span className="subsection__close" onClick={close}></span> }
        {children}
      </div>
    </div>
  )
}

export default Subsection
