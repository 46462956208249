import React from 'react'
import { NavLink } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import './departments.scss'

import { IDepartment } from '../../store/departments/types'
import noPhoto from '../../assets/images/no-photo.svg'

import Loader from '../loader/Loader'
import { decodeHtmlSymbols } from '../../utils/DecodeHtmlSymbols'

interface IDepartmentProps extends IDepartment {
  loading?: boolean
}

interface IDepartments {
  items?: IDepartmentProps[]
  storeId: undefined | string
}

const MobileDepartments: React.FC<IDepartments> = ({ items = [], storeId }) => {
  return (
      <div className="departments-wrap mobile">
        {
          items.filter(x => x.id !== 1).map((department, i) => {
            const title = decodeHtmlSymbols(department.title)
            return (
              department.loading
                ? <div key={i} className='department loading'>
                  <div className='department__cover'>
                    <div className='department__cover-loader-wrapper'>
                      <Loader color='blue' className='department-loader'/>
                    </div>
                  </div>
                  <div className='department__placeholder'>
                    <div></div>
                  </div>
                </div>
                : <NavLink to={storeId
                  ? {
                      pathname: `/store/${storeId}/${department.id}`
                    }
                  : {
                      pathname: '/catalog',
                      search: `department=${department.id}`
                    }} key={department.id} className='department'>
                    <div className="department__cover">
                      <LazyLoadImage alt={title} src={ department.image ? department.image.url : noPhoto } />
                    </div>
                    <div className="department__name">{title}</div>
                    <div className="department__link"></div>
                  </NavLink>
            )
          })
        }
      </div>
  )
}

export default MobileDepartments
