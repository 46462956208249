import React, { SyntheticEvent, useState } from 'react'
import { connect } from 'react-redux'
import { Field, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form'
import { SignUpFormData } from '../../../../store/account/profile/types'
import { initResetPassword } from '../../../../store/account/profile/action-creators'

interface ResetPasswordFormProps {
  initResetPassword: (data: SignUpFormData) => any,
  toggle: () => any,
  close: (e: SyntheticEvent) => void
}

const Form: React.FunctionComponent<
  ResetPasswordFormProps &
  InjectedFormProps<SignUpFormData, ResetPasswordFormProps>
  > = ({
    initResetPassword,
    handleSubmit,
    toggle,
    close
  }) => {
    const [showErrorText, setShowErrorText] = useState(false)
    const [showNotificationText, setShowNotificationText] = useState(false)

    const submit = async (data: SignUpFormData) => {
      try {
        try {
          await initResetPassword(data)
          setShowNotificationText(true)
          setShowErrorText(false)
        } catch (e) {
          setShowErrorText(true)
          setShowNotificationText(false)
        }
      } catch (e: any) {
        const error = e.errors
          ? e.errors.map(({ message }: { message: string }) => message).join('\n')
          : 'Something went wrong, please try again'
        throw new SubmissionError({ _error: error })
      }
    }
    return (
      <form onSubmit={handleSubmit(submit)} className="form-type-2 account-form">
        <h3 className="subsection__header">Reset password</h3>
        <Field name="email" component="input" type="text" placeholder="Enter your email" />
        <button className="button_blue">
          Get confirmation code
        </button>
        <button className="button_grey" onClick={close}>
          Cancel
        </button>
        { showErrorText && <div className="account-text error">Sorry, we haven&apos;t found an account for this email.</div> }
        { showNotificationText && <div className="account-text notification">We&apos;ve sent you a confirmation email.<br/> Please check your mail box and follow instructions from it.</div> }
        <p>Dont have an account? <span onClick={toggle}>Sign Up!</span></p>
      </form>
    )
  }

const WrappedForm = reduxForm<SignUpFormData, ResetPasswordFormProps>({
  form: 'ResetPasswordForm'
})(Form)

const ConnectedWrappedForm = connect(
  null,
  { initResetPassword }
)(WrappedForm)

export default ConnectedWrappedForm
