import { IImage } from '../../types/images'
import { IOrderStatus } from '../order/types'

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA'

interface GetDashboardDataAction {
  type: typeof GET_DASHBOARD_DATA,
  payload: IDashboard
}

export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA'

interface ClearDashboardDataAction {
  type: typeof CLEAR_DASHBOARD_DATA
}

export const GET_BONUSES_DATA = 'GET_BONUSES_DATA'

interface GetBonusesDataAction {
  type: typeof GET_BONUSES_DATA,
  payload: IBonuses
}

export interface IDashboard {
  loading: boolean,
  favorites: {
    count: boolean,
    items: {
      name: string
    }[]
  } | null,
  orders: {
    count: {
      active: number,
      total: number
    }
  } | null,
  latestOrder: {
    id: number,
    status: number,
    delivery_schedule: {
      base_date: string
    },
    order_statuses: IOrderStatus[]
  } | null,
  referrals: IReferral[]
  total?: number
  available?:number
}

export interface IReferral {
  image: IImage;
  first_name: string;
  last_name: string;
  bonuses: number;
  invited: string;
}

export interface IBonuses {
  total?: number;
  available?: number;
  referrals: IReferral[]
}

export type DashboardActions = (
  GetDashboardDataAction |
  ClearDashboardDataAction |
  GetBonusesDataAction
)
