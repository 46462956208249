import {
  ABOUT_US_FETCHED,
  APP_LINKS_FETCHED,
  DELIVERY_INFORMATION_FETCHED,
  IInformation,
  InformationActions,
  PRIVACY_POLICY_FETCHED,
  SOCIAL_NETWORK_LINKS_FETCHED,
  TERMS_AND_CONDITIONS_FETCHED,
  ANNOUNCEMENT_LIST_FETCHED
} from './types'
import { Action } from 'redux'
import { makeLoadersArray } from '../../utils/LoadersArray'

const initialState: IInformation = {
  privacyPolicy: '',
  termsAndConditions: '',
  deliveryInformation: '',
  aboutUs: '',
  socialLinks: [],
  appLinks: [],
  listLoad: true,
  announcements: {
    currentPage: 1,
    list: makeLoadersArray(4, 'announcements'),
    totalPages: 1,
    itemsPerPage: 1,
    totalItems: 1
  }
}

export default function reduce (state = initialState, incomingAction: Action) {
  const action = incomingAction as InformationActions
  switch (action.type) {
    case PRIVACY_POLICY_FETCHED: {
      return {
        ...state,
        privacyPolicy: action.payload
      }
    }
    case DELIVERY_INFORMATION_FETCHED: {
      return {
        ...state,
        deliveryInformation: action.payload
      }
    }
    case ABOUT_US_FETCHED: {
      return {
        ...state,
        aboutUs: action.payload
      }
    }
    case TERMS_AND_CONDITIONS_FETCHED: {
      return {
        ...state,
        termsAndConditions: action.payload
      }
    }
    case SOCIAL_NETWORK_LINKS_FETCHED: {
      return {
        ...state,
        socialLinks: action.payload
      }
    }
    case ANNOUNCEMENT_LIST_FETCHED: {
      return {
        ...state,
        announcements: action.payload,
        listLoad: false
      }
    }
    case APP_LINKS_FETCHED: {
      return {
        ...state,
        appLinks: action.payload
      }
    }
    default:
      return state
  }
}
