import { combineReducers } from 'redux'
import favorites from './favorites/reducer'
import { AccountFavoritesPage } from './favorites/types'
import profile, { IProfileAccountInfo } from './profile/reducer'

export interface AccountPage {
  favorites: AccountFavoritesPage
  profile: IProfileAccountInfo
}

const accountReducer = combineReducers({
  favorites,
  profile
})

export default accountReducer
