import { ApiService, ApiServiceClass } from './ApiService'
import { withToken, CustomersService } from '../services/CustomersService'

export class FavoritesServiceClass {
  constructor (
    private apiService: ApiServiceClass,
    private customerService: typeof CustomersService
  ) { }

  @withToken()
  async get () {
    return this.customerService.customerId ? this.apiService.get('/customers/favorites') : () => {}
  }

  @withToken()
  async add (productId: string) {
    return this.customerService.customerId
      ? this.apiService.post(`/customers/favorites/${productId}`)
      : this.apiService.post(`/guests/favorites/${productId}`)
  }

  @withToken()
  async remove (productId: string) {
    return this.customerService.customerId
      ? this.apiService.delete(`/customers/favorites/${productId}`)
      : this.apiService.delete(`/guests/favorites/${productId}`)
  }
}

export const FavoritesService = new FavoritesServiceClass(ApiService, CustomersService)
