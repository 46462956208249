import React, { SyntheticEvent } from 'react'

interface IPaginationProps {
  totalPages: number,
  currentPage: number,
  loadPage: (e: SyntheticEvent, page: number) => void
}

const Pagination: React.FC<IPaginationProps> = ({ totalPages, currentPage, loadPage }) => {
  const pagesArray = new Array(totalPages).fill(totalPages, 0, totalPages)

  return (
    <nav className="menu menu--pagination">
      <ul className="menu__container">
      {
        pagesArray.map((item, index) => {
          const page = index + 1
          if (page !== 1 && page !== totalPages) {
            if (page >= currentPage + 3 || page <= currentPage - 3) {
              return null
            }

            if (page === currentPage + 2 || page === currentPage - 2) {
              return (
                <li key={page} className="menu__item"><a className='menu__dots' href="#" onClick={(e) => { e.preventDefault() }}>...</a></li>
              )
            }
          }

          return (
            <li key={page} className="menu__item"><a className={
              `menu__link ${currentPage === page ? 'menu__link--active' : ''}`
            } href="#" onClick={(e) => loadPage(e, page)}>{page}</a></li>
          )
        })
      }
      </ul>
    </nav>
  )
}

export default Pagination
