import React from 'react'
import infoIcon from '../../assets/images/icons/info.svg'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

import './checkout.scss'

interface ICheckout {
  singleColumn?: boolean,
  extraChildren?: any,
  childrenBeforeOptions?: any,
  childrenAfterOptions?: any,
  total?: string,
  options?: {
    label: string,
    value: string,
    description?: string
  }[],
}

const Checkout: React.FC<ICheckout> = ({
  singleColumn = false,
  extraChildren,
  childrenBeforeOptions,
  childrenAfterOptions,
  total,
  options = [],
}) => {
  return (
    <div className={classnames({
      checkout: true,
      'checkout--with-extra': extraChildren,
      'checkout--single': singleColumn
    })}>
      {
        !singleColumn && extraChildren &&
        <div className="checkout__extra">
          {extraChildren}
        </div>
      }
      <div className="checkout__options">
        {childrenBeforeOptions}
        {options.map(({ label, value, description }, i) =>
          <div key={i} className="checkout-option">
            <div className="checkout-option__label">{label}
              {
                description &&
                <img
                  className="checkout-option__icon"
                  src={infoIcon}
                  data-for={`checkout-option-description-${i}`}
                  data-tip={description}
                />
              }
              {
                description &&
                <ReactTooltip
                  id={`checkout-option-description-${i}`}
                  className="checkout-option__tooltip"
                  effect="solid"
                  clickable
                  multiline
                />
              }
            </div>

            <div className="checkout-option__value">{value}</div>
          </div>
        )}
        {
          total &&
          <div className="checkout-option checkout-option--total">
            <div className="checkout-option__label">Total</div>
            <div className="checkout-option__value">{total}</div>
          </div>
        }
        {childrenAfterOptions}
      </div>
    </div>
  )
}

export default Checkout
