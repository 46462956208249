import React, { useEffect, useState } from 'react'
import Account from '../header/subsection/account/Account'
import { connect } from 'react-redux'
import { ReduxState } from '../../store/reducers'
import { hideStandaloneLoginForm } from '../../store/account/profile/action-creators'

interface IAccountProps {
  children?: React.ReactNode
  showStandaloneLoginForm?: boolean
  hideStandaloneLoginForm: () => void
}
export enum AccountFormType {
  SignIn,
  SignUp,
  ResetPassword
}

const AccountForm: React.FC<IAccountProps> = props => {
  const [showSingIn, setShowSignIn] = useState(AccountFormType.SignIn)
  const [accountShown, setAccountVisibility] = useState(false)

  useEffect(() => {
    setAccountVisibility(!!props.showStandaloneLoginForm)
  }, [props.showStandaloneLoginForm])

  const hideAccount = () => {
    setShowSignIn(AccountFormType.SignIn)
    setAccountVisibility(false)
    props.hideStandaloneLoginForm()
  }
  const onResetPassword = () => {
    setShowSignIn(AccountFormType.ResetPassword)
  }
  return <>
    {props.children ? <div onClick={() => setAccountVisibility(!accountShown)}>{props.children}</div> : null}
    {accountShown ? <Account close={hideAccount} showSignIn={showSingIn} onResetPassword={onResetPassword} /> : null}
    </>
}

export default connect((state: ReduxState) => {
  return {
    showStandaloneLoginForm: state.account.profile.showStandaloneLoginForm
  }
}, {
  hideStandaloneLoginForm
})(AccountForm)
