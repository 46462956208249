import React, { useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCart } from '../../store/cart/actions'
import { ReduxState } from '../../store/reducers'
import { ICart } from '../../store/cart/types'

import './cart.scss'
import Loader from '../loader/Loader'
import Subsection, { SubsectionProps } from '../header/subsection/Subsection'
import { MultiCartItem } from './MultiCartItem'
import CartCustomerInfo from './CartCustomerInfo'

interface CartProps extends SubsectionProps {
  getCart: () => void
  loading?: boolean
  carts: ICart[]
  close: () => void
}

const Cart: React.FC<CartProps & RouteComponentProps> = (props) => {
  useEffect(() => {
    props.getCart()
  }, [])

  return (
    <Subsection {...props} className='subsection__cart'>
    {
      props.loading
        ? <Loader color='orange' />
        : <div className="cart">
          {
            props.carts.length > 0 && <CartCustomerInfo carts={props.carts} />
          }
          {
            props.carts.length > 1 && <div className="cart__multiple-stores">
              Please note that your cart has orders from multiple stores. Deliveries from separate stores will incur separate delivery fees.
            </div>
          }
          {
            props.carts.map((cart, i) => (<MultiCartItem key={i} index={i} close={props.close} {...cart} />))
          }
          {
            props.carts.length === 0 && <p className="text-container">The cart is empty.</p>
          }
        </div>
    }
    </Subsection>
  )
}

export default connect(
  ({ cart }: ReduxState) => ({ carts: cart.carts || [] }),
  { getCart }
)(withRouter(Cart))
