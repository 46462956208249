import { ApiService } from './ApiService'
import { CustomersService } from './CustomersService'
import { GuestService } from './GuestsService'

interface CartActionProps {
  id: string
  count: number
  storeId?: number
}

export class CartServiceClass {
  async getGuestCart () {
    const asyncGetGuestCart = async (resolve: any) => {
      if (GuestService.guestId) {
        const res = await ApiService.get(`/v2/guests/${GuestService.guestId}/cart`)
        resolve(res)
      } else {
        setTimeout(asyncGetGuestCart.bind(this, resolve), 500)
      }
    }

    return new Promise((resolve) => {
      asyncGetGuestCart(resolve)
    })
  }

  async useTips (tips: number) {
    return ApiService.post('/customers/driverTips', { amount: tips })
  }

  async get () {
    return ApiService.get(`/v2/customers/${CustomersService.customerId}/cart`)
  }

  async addGuestCartItem ({ id, count, storeId = 1 }: CartActionProps) {
    const asyncAddGuestCart = async (resolve: any) => {
      if (GuestService.guestId) {
        const res = ApiService.post(`/v2/guests/${GuestService.guestId}/cart/products`, {
          product_id: id,
          count,
          ...storeId && {
            store_id: storeId
          }
        })
        resolve(res)
      } else {
        setTimeout(asyncAddGuestCart.bind(this, resolve), 500)
      }
    }

    return new Promise((resolve) => {
      asyncAddGuestCart(resolve)
    })
  }

  async add ({ id, count, storeId = 1 }: CartActionProps) {
    return ApiService.post(`${storeId ? '/v2' : ''}/customers/${CustomersService.customerId}/cart/products`, {
      product_id: id,
      count,
      ...storeId && {
        store_id: storeId
      }
    })
  }

  async removeGuestCartItem ({ id, count, storeId }: CartActionProps) {
    return ApiService.delete(`/v2/guests/${GuestService.guestId}/cart/products`, {
      product_id: id,
      count,
      ...storeId && {
        store_id: storeId
      }
    })
  }

  async remove ({ id, count, storeId }: CartActionProps) {
    return ApiService.delete(`/v2/customers/${CustomersService.customerId}/cart/products`, {
      product_id: id,
      count,
      ...storeId && {
        store_id: storeId
      }
    })
  }
}

export const CartService = new CartServiceClass()
