import { CatalogItem } from '../catalog/types'

export const FETCH_FAVORITES = 'FETCH_FAVORITES'

interface FetchFavoritesAction {
  type: typeof FETCH_FAVORITES,
  payload: IFavorite[]
}

export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES'

interface AddToFavoritesAction {
  type: typeof ADD_TO_FAVORITES,
  payload: IFavorite
}

export const REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES'

interface RemoveFromFavoritesAction {
  type: typeof REMOVE_FROM_FAVORITES,
  payload: string;
}

export interface IFavorite extends CatalogItem {}

export interface IFavoritesState {
  favorites: IFavorite[]
}

export type FavoritesActions = (
  FetchFavoritesAction |
  AddToFavoritesAction |
  RemoveFromFavoritesAction
)
