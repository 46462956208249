import React from 'react'
import classnames from 'classnames'
import { IAddress } from '../../../store/customers/types'
import editIcon from '../../../assets/images/icons/pencil.svg'

export const ItemsList: React.FunctionComponent<{
  onClick: () => void,
  onEdit: (data: IAddress) => void,
  onSelect: (data: IAddress) => void,
  selectedId: number
  list: IAddress[]
}> = ({ onClick, onSelect, list, selectedId, onEdit }) => (
  <div className="dropdown__overlay">
    {
      list.map((data: IAddress) => (
        <div
          className="dropdown__item-wrapper"
          key={data.id}
        >
          <div
            onKeyPress={() => onSelect(data)}
            onClick={() => onSelect(data)}
            tabIndex={0}
            className={classnames('dropdown__item', {
              dropdown__item_selected: data.id === selectedId
            })}>
            {data.title}
          </div>
          <button className="dropdown__item-button" onClick={(e) => {
            e.preventDefault()
            onEdit(data)
          }}>
            <img src={editIcon}/>
          </button>
        </div>
      ))
    }
    <button
      onClick={onClick}
      type="button"
      className="blue">
      Add Another Address
    </button>
  </div>
)
