import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { ReduxState } from '../../../store/reducers'
import { getAddresses } from '../../../store/order/actions'
import { ItemsList } from './ItemsList'
import { OrderService } from '../../../services/OrderService'
import NewAddressForm from './NewAddressForm'
import Progress, { ProgressStatus } from '../../progress/Progress'
import { IAddress } from '../../../store/customers/types'

interface DeliveryAddressProps {
  labelText: string,
  placeholder: string,
  progressStatus: ProgressStatus,
  getAddresses: () => void,
  changeValue: (value: number) => void,
  pushFocusCallback: (data: { [x: string]: () => void }) => void,
  input: WrappedFieldInputProps,
  meta: WrappedFieldMetaProps,
  list: IAddress[],
  form: string
}

enum DeliveryAddressState {
  None,
  Add,
  List,
  Edit
}

export function getAddressString (address: IAddress): string {
  return (address.line2 ? address.line2 + ' ' : '') + [
    address.line1,
    address.city,
    address.state,
    address.zip
  ].filter(Boolean).join(', ')
}

const DeliveryAddress: React.FunctionComponent<DeliveryAddressProps> = ({
  pushFocusCallback,
  progressStatus,
  getAddresses,
  changeValue,
  placeholder,
  labelText,
  list = [],
  input,
  meta: {
    touched,
    error
  }
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [selectedItem, selectItem] = useState('')
  const [type, setType] = useState(DeliveryAddressState.None)
  const [editingData, setEditingData] = useState<IAddress | null>(null)
  useEffect(() => {
    pushFocusCallback && pushFocusCallback({
      [input.name]: () => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }
    })
    getAddresses()
  }, [])
  useEffect(() => {
    if (input.value) {
      selectItem('Loading...')
      OrderService.getAddresses()
        .then(customerAddresses => {
          const address = customerAddresses.find(({ id }: { id: number }) => {
            return id === input.value
          })
          selectItem(`${address.title} - ${getAddressString(address)}` || '')
        })
    }
  }, [input.value])
  const addNewAddress = () => {
    setEditingData(null)
    setType(DeliveryAddressState.Add)
  }
  const editAddress = (data: IAddress) => {
    setType(DeliveryAddressState.Edit)
    setEditingData(data)
  }
  const toggleOpen = () => {
    switch (type) {
      case DeliveryAddressState.None:
        list.length
          ? setType(DeliveryAddressState.List)
          : setType(DeliveryAddressState.Add)
        break
      case DeliveryAddressState.Add:
      case DeliveryAddressState.Edit:
      case DeliveryAddressState.List:
        setType(DeliveryAddressState.None)
        break
    }
  }
  const selectAddress = (address: IAddress) => {
    changeValue(address.id)
    selectItem(`${address.title} - ${getAddressString(address)}`)
    toggleOpen()
  }
  const onAddNewAddress = (data: IAddress) => {
    setType(DeliveryAddressState.None)
    changeValue(data.id)
    selectItem(`${data.title} - ${getAddressString(data)}`)
    setEditingData(null)
  }
  const onEditAddress = (data: IAddress) => {
    setType(DeliveryAddressState.None)
    if (data.id === input.value) {
      selectItem(`${data.title} - ${getAddressString(data)}`)
    }
    setEditingData(null)
  }

  return (
    <div className={classnames('field dropdown pointer', {
      dropdown_open: type !== DeliveryAddressState.None,
      'field-error': touched && error
    })}>
      {labelText}
      <div className="input input-icon" onClick={toggleOpen} >
        <Progress status={progressStatus} />
        <input ref={inputRef} placeholder={placeholder} readOnly value={selectedItem} />
        <span className="arrow inner" tabIndex={0} onKeyPress={toggleOpen} onClick={toggleOpen}></span>
      </div>
      {
        type
          ? (
              type === DeliveryAddressState.Add || type === DeliveryAddressState.Edit
                ? <NewAddressForm onAdd={onAddNewAddress} onEdit={onEditAddress} addressData={editingData} />
                : <ItemsList
                  onClick={addNewAddress}
                  onEdit={editAddress}
                  onSelect={selectAddress}
                  selectedId={input.value}
                  list={list} />
            )
          : null
      }
    </div>
  )
}

export default connect(
  ({ order: { list } }: ReduxState) => ({ list }),
  { getAddresses }
)(DeliveryAddress)
